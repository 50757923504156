import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import "./Registration.css";
import { isAuthenticated } from "../../Utils/Authenticated";
import { bankdetails, regcarddetails, validateCard, ValidateRegisterationtier_3 ,regdocdetails} from "../../Utils/Validators";
import UseForm from "../../Utils/UserForm";
import { request } from "../../Utils/Request";
import InputField from "../../components/Inputs/InputField";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import SelectField from "../../components/Inputs/SelectField";
import JwtDecode from "jwt-decode";
import RadioBox from "../../components/Inputs/RadioBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {GreenRadio} from "../../components/Inputs/RadioBox/index"
import CustomInput from "../../components/Inputs/borderedInput";
import { Popup, Toast } from "../../Utils/Notifications";
import { Link } from "react-router-dom";
import { FormHelperText, ListItem } from "@material-ui/core";
import { Call } from "@material-ui/icons";
import CheckBox from "../../components/Inputs/CheckBox";
import CustomSelect from "../../components/Inputs/borderedSelect";
import { HerdNum } from "../../Utils/constant";
import moment from "moment";

const Reg_tier_3 = (props, history) => {
  console.log(localStorage.userId);
  const [btnloading,setBtnloading]=useState(false)



  useEffect(() => {
    
    if (isAuthenticated()/*  && localStorage.userRole === "tier2"  */) {
      props.history.push('/Reg_tier_3')
    }else{
      // props.history.push('/')
    }
  }, [])

 
  const [loader, setLoader] = useState(false);
 
  const onClickImages = async (e) => {
    const {name}=e.target;
    console.log("++++++++++++++++++++",name)
    setValues({ ...values, [name]: e.target.files[0] });
  };
 
    var {
      handleSubmit,
      handleChange,
      values,
      errors,
      state,
      setValues
    } = UseForm(formSubmit,regdocdetails);


    async function formSubmit() {
      callupdate()
    }

    const callupdate=async(e)=>{
      setBtnloading(true)
      var val = new FormData();

    val.append("userid", JwtDecode(isAuthenticated()).id);
    val.append("image_identity", values.banner_image);
    val.append("image_address", values.address_image);
    val.append("image_herd", values.herd_image);

     const re=await request("post","/user_images",val,false,true)
   
   if(re.data.success==="yes")
   {
      const res = await request(
        "post",
        "/getUserProfileDetails",
        {
          user_id:localStorage.userId
        },
        false,
        true
      );
      console.log(res.data);
      console.log("Testing res data");
      if ((res.data.success = "yes")) {

        const form_data = {
          user_id: res.data.data.id,
          email: res.data.data.email,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          registered_number: res.data.data.registered_number, //registered number added as herd_number
          phone: res.data.data.phone,
          address1: res.data.data.address1, //building name stored as address 1
          address2: res.data.data.address2, //street name stored as address2
          state: res.data.data.state, //county stored as state
          city: res.data.data.city, // town stored as city
          zip: res.data.data.zip, //zipcode added as eircode
          country:res.data.data.country,
          role: "tier3",
          company_name:res.data.data.company_name,
          username:res.data.data.username,
          profile_image:res.data.data.avatar,
          user_profile_image: res.data.data.avatar,
          //collection address stored as mailing address
          mailing_address:res.data.data.mailing_address,
          //delivery address stored as secondary mailing address
          secondary_mailing_address:res.data.data.secondary_mailing_address
        };
      
      const response = await request(
        "post",
        "/updateUserProfile",
        form_data,
        false,
        true
      );
      setBtnloading(false) 
      if (response.data.status === "yes") {

        // add to mailchimp list
        if (!window.location.hostname.includes("localhost")){
        if (localStorage.mailchimpID && localStorage.mailchimpID != ""){
          const res = await request("post", "/mailchimp", {
            type: "mailchimp",
            userid: localStorage.userId,
            email: localStorage.userEmail,
            role: "tier3",
            mailchimp_id: localStorage.mailchimpID,
          });
          
        } else {
          const res = await request("post", "/mailchimp", {
            type: "mailchimp",
            userid: localStorage.userId,
            email: localStorage.userEmail,
            role: "tier3",
          });
        }
        }
        localStorage.clear();
        localStorage.setItem("newspopup", 1)
        await props.history.push("/login");
        return Popup.fire({ type: 'success', title: "Congratulations, you've registered as a Seller. Happy Selling." })
      }
    
    }
  }
  setBtnloading(false)
    
  }
  async function formSubmit_old() {
    var split = values.date.split('/')
    const formdata = new FormData()
    console.log("+++++",values.payment_type)
  
    if(values.payment_type=="debitCard")
    {
      const data = {
        cardNumber: values.card_number.split(' ').join(''),
        card_name: values.card_name,
        expiryMonth: split[0],
        expiryYear:split[1].length=="4"?split[1]:20+split[1],
        cvv: values.cvv,
        amount: '0.50',
        isSave: true,
        capture: false,
        mode: process.env.REACT_APP_PAYMENT_MODE
      }

      try{
      const res = await request('post','/payAmountStripe', data, false, true)
      if (!res.data.success) {
        Toast.fire({ type: 'error', title: res.data.message.error.message })
     }
     else{
       Toast.fire({ type: 'success', title: 'Your Card Added Successfully' })
       callupdate()
     } 
    } catch (error) {
      Toast.fire({ type: 'error', title: 'Try again later!' })
      console.log(error)
    }

        
	
  }
  else{
     var data={
       user_id:JwtDecode(isAuthenticated()).id,
       bank:values.bank,
       iso:values.iso_country_code,
       branch:values.branch_identifier,
       account:values.account_number
     }
    const res=await request("post","/addnewbank",data,false,true)
    callupdate()

  }
  }
  const changepage=(e)=>{
    setValues({...values,branch_identifier:"",bank:"",account_number:"",payment_type:e.target.value})
    // console.log("eeeeeeeee",e.target.name)
    // values.payment_type=e.target.value;
  }

  // console.log(values)

  const tempBank = ["bank1","bank2","bank3"]
  const tempIsoCode = ["372"]

  return (
    <div className="ffReg">
      <div className="ffRegCnt">
        <Link to="/">
          <img src="/svg/logo.svg" alt="Farm Fayre" height="75" />
        </Link>
        <div className="greetMsg">
          <h2 className="playFair">Seller Registration</h2>
          <p className="" style={{fontSize:17}}>
          We require some essential documents to authenticate your account. This boosts trust in our platform & safeguards everyone,ensuring  secure transactions where sellers get paid and buyers get what they were promised. <br></br>
          <br></br>Thanks for trusting Farm Fayre.
           {/* Your Bank account details are required so that Trustap can deposit your sale proceeds without delay. */}
          </p>
        </div>
        <form onSubmit={handleSubmit}>
        
        {/* <h2 className="regPayTitle">Bank details <span>(This is the account that Trustap will credit from your sales. Please ensure your details are correct.)</span></h2>
          <RadioBox title="Select payment type"
            name="payment_type"
            value={values.payment_type}
            onchange={handleChange}
          >

            <FormControlLabel
              value="debitCard"
              control={<GreenRadio />}
              label="Debit Card"
            />
            <FormControlLabel
              value="bankDetails"
              control={<GreenRadio />}
              label="Bank Details"
            />
          </RadioBox>
          {values.payment_type != "debitCard" ?
            <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">

              <div className="ffRegInp">
                <CustomInput
                  label="Account Holder Name*"
                  placeholder="Enter account holder name"
                  tooltiptitle="Enter account holder name"
                  name="branch_identifier"
                  con={false}
                  onchange={handleChange}
                  value={values.branch_identifier}
                  error={state && errors.branch_identifier ? errors.branch_identifier : ""}
                />
                <FormHelperText error>{state && errors.branch_identifier}</FormHelperText>
              </div>

              <div className="ffRegInp">
                <CustomInput
                  label="IBAN*"
                  // con={"sn"}

                  placeholder="Enter your IBAN"
                  tooltiptitle="Enter your IBAN"
                  name="iso_country_code"
                  onchange={handleChange}
                  value={values.iso_country_code}
                  InputProps={{

                    onKeyPress: HerdNum,
                  }}
                  error={state && errors.iso_country_code ? errors.iso_country_code : ""}
                />
                <FormHelperText error>{state && errors.iso_country_code}</FormHelperText>
              </div>

              <div className="ffRegInp">
                <CustomInput
                  id="Bank Name"
                  label="Bank Name*"
                  placeholder={"Enter Your Bank name"}
                  tooltiptitle="Bank Name"
                  name="bank"
                  onchange={handleChange}
                  value={values.bank}

                  // selectMenu={["bank1"]}
                  error={state && errors.bank ? true : false}

                ></CustomInput>
                <FormHelperText error>{state && errors.bank}</FormHelperText>
              </div>

              <div className="ffRegInp">
                <CustomInput
                  label="Account Number"
                  placeholder="Enter account number"
                  tooltiptitle="Enter account number"
                  name="account_number"
                  con={true}
                  onchange={handleChange}
                  value={values.account_number}
                // error={state && errors.account_number?errors.account_number:""}
                />
              </div>

              <div className="ffRegInp">
                <CustomInput
                  label="Sort Code"
                  placeholder="Enter Sort Code"
                  tooltiptitle="Enter Sort Code"
                  name="sort_code"
                  con={true}
                  onchange={handleChange}
                  value={values.sort_code}

                />
              </div>
            </div> 
          : 
            <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">

              <div className="ffRegInp">

                <CustomInput

                  label="Card Number"
                  // con={true}
                  // error={state ? !values.address1 && true : false }
                  placeholder="Enter your Card Number"
                  tooltiptitle="Enter your Card Number"
                  name="card_number"
                  con={values.card_number.length == 16 ? "dallow" : true}
                  // value= {values.address1}
                  onchange={handleChange}
                  // helperText = {state && errors.address1}
                  value={values.card_number}
                  //  onchange={handleChange}
                  error={errors.card_number && state ? true : false}
                  helperText={state ? errors.card_number : ""}
                />

              </div>

              <div className="ffRegInp">
                {console.log("+===============", values.date)}
                <CustomInput

                  label="Expiry Date"
                  // error={state ? !values.address1 && true : false }
                  placeholder="Enter your Expiry Date"
                  con={values.date.length == 5 ? "dallow" : true}
                  tooltiptitle="Enter your Expiry Date"
                  name="date"
                  value={values.date}
                  onchange={handleChange}
                  //  disabled={values.date.length<5?false:true}
                  error={errors.date && state ? true : false}
                  helperText={state ? errors.date : ""}
                />

              </div>

              <div className="ffRegInp">
                <CustomInput

                  label="Card Name"
                  // error={state ? !values.address1 && true : false }
                  placeholder="Enter your card name"
                  tooltiptitle="Enter your card name"
                  name="card_name"
                  con={false}
                  value={values.card_name}
                  onchange={handleChange}
                  error={errors.card_name && state ? true : false}
                  helperText={state ? errors.card_name : ""}
                />
              </div>
              <div className="ffRegInp">
                <CustomInput

                  label="CVV"
                  // error={state ? !values.address1 && true : false }
                  placeholder="Enter your cvv"
                  tooltiptitle="Enter your cvv"
                  name="cvv"
                  con={values.cvv.length === 3 ? "dallow" : true}
                  value={values.cvv}

                  onchange={handleChange}
                  error={errors.cvv && state ? true : false}
                  helperText={state ? errors.cvv : ""}
                />
              </div>
            </div>
          } */}

          <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">

            <div className="d-flex ffRegTitle mt-4 nowrap align-items-center" style={{ flexWrap : "nowrap"}}>
              <h3>Upload Verification Documents</h3>
              <div>
                <Tooltip title="We need these details to process your payments, organise transfer details, and ensure quick and efficient payment when you sell your livestock. Also, these details are required, by our payment system provider, to ensure everything is in place so that funds can be deposited to your account without any delay." placement="top-bottom">
                  <ListItem className="helpingTooltip d-flex align-items-center">
                    <span class="material-icons">help</span>
                    {/* Why is this required */}
                  </ListItem>
                </Tooltip>
              </div>
            </div>
            <div className="row reg-row">
              <div className="col-md-4">
                <div className="ffRegInpFile d-flex justify-content-center align-items-center">
                  <label htmlFor="identity_image" className="w-100">
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      name="banner_image"
                      hidden
                      id="identity_image"
                      onChange={onClickImages}
                    />
                    <div>
                      <object type="image/svg+xml" data="./svg/upload.svg" />
                      <h4>UPLOAD IMAGE</h4>
                      <p>Upload your proof of identity*</p>
                    </div>
                  </label>
                </div>
                <p>Include Driver License; or Passport; or Public Services Card [Must be within last 6 months]</p>
                <FormHelperText error>{state && errors.banner_image}</FormHelperText>
                <div className="row">
                  {values.banner_image && (
                    <div className="col-m-6 form-group">
                      <img
                        src={URL.createObjectURL(values.banner_image)}
                        className="pro-img rounded img-fluid"
                      />
                    </div>
                  )}

                </div>
              </div>

              {/* <div className="col-md-4">
                <div className="ffRegInpFile d-flex justify-content-center align-items-center">
                  <label htmlFor="identity_image1" className="w-100">
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      name="address_image"
                      hidden
                      id="identity_image1"
                      onChange={onClickImages}
                    />
                    <div>
                      <object type="image/svg+xml" data="./svg/upload.svg" />
                      <h4>UPLOAD IMAGE</h4>
                      <p>Upload your proof of address*</p>
                    </div>
                  </label>

                </div>
                <p>Include Bank Statement; or Utility Bill; or Official Government Letter [Must be within last 6 months]</p>
                <FormHelperText error>{state && errors.address_image}</FormHelperText>
                <div className="row">
                  {values.address_image && (
                    <div className="col-m-6 form-group">
                      <img
                        src={URL.createObjectURL(values.address_image)}
                        className="pro-img rounded img-fluid"
                      />
                    </div>
                  )}
                </div>
              </div> */}

              {/* <div className="col-md-4">
                <div className="ffRegInpFile d-flex justify-content-center align-items-center">
                  <label htmlFor="identity_image2" className="w-100">
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"

                      hidden
                      name="herd_image"
                      id="identity_image2"
                      onChange={onClickImages}
                    />
                    <div>
                      <object type="image/svg+xml" data="./svg/upload.svg" />
                      <h4>UPLOAD IMAGE</h4>
                      <p>Upload herd number document*</p>
                    </div>
                  </label>
                </div>
                <p>Include Department of Agriculture correspondence with "Herd Number" [Must be within last 6 months]</p>
                <FormHelperText error>{state ? errors.herd_image : ""}</FormHelperText>
                <div className="row">
                  {values.herd_image && (
                    <div className="col-m-6 form-group">
                      <img
                        src={URL.createObjectURL(values.herd_image)}
                        className="pro-img rounded img-fluid"
                      />
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>

          <div className="agreeTerms">

            <CheckBox
              label={<p><span>I confirm that I have read, understand,  and agree to the </span><a style={{ color: "green" }} target={"_blank"} href={"/terms"}>Terms & Conditions</a><span> and </span><a style={{ color: "green" }} target={"_blank"} href={"/privacypolicy"}>Privacy Policy</a><span> of Farm Fayre.</span></p>}
              name="terms"
              /* value={values.singleitemlot_auction} */
              value={values.terms}
              click={() => !values.terms}
              onchange={handleChange}
            />
            <FormHelperText error>{state && errors.terms}</FormHelperText>
          </div>
          <div className="actBtn">
            <PrimaryButton label="SUBMIT" dis={btnloading} type="submit" />
          </div>
        </form>

        {/* <div className="accHelp">
          <p>Already have an account?</p>
          <span>Login</span> <a href="#">here</a>
        </div> */}
      </div>
      <p className="footerText">© {moment().year()} Farm Fayre. All rights reserved.</p>
    </div>
  );
};

export default Reg_tier_3;
