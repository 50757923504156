import { StepContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { request } from "../../Utils/Request";
import ReactHtmlParser from "react-html-parser";
import "./allcss.css";

const Declaration = (props) => {
  const [content, setContent] = useState("");
  const getContent = async (e) => {
    const res = await request("post", "/show_static_content", {
      page_id: "Seller\'s Declaration",
    });
    if (res && res.data && res.data.status == 'true') {
      
      const search = props.location.search;
      const params = new URLSearchParams(search);

      var html = res.data.data[0].content.replace('{seller}' , params.get("seller"));
      html = html.replace('{lot}' , params.get("lot"));
      setContent(html);
    }
  };

  useEffect((e) => {
    getContent();
  }, []);
  return (
    <Layout>
      <div className="static-wrap-container container-lg">
        <div className="static-content">
        <p style={{textAlign: "center"}}><u><big>Seller's Declaration</big></u></p>
          {ReactHtmlParser(content)}
        </div>
      </div>
    </Layout>
  );
};

export default Declaration;