import React,{ useEffect, useState } from "react"; 
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { isAuthenticated } from "../../Utils/Authenticated";
import {ValidateRegisterationtier_1} from "../../Utils/Validators"
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import "./post-project.css"; 
import Layout from "../../components/Layout";


const  PostProjectLotSize = (props) => {
/* console.log(isAuthenticated())
 */
/* useEffect(()=>{
	if(!isAuthenticated() && localStorage.userRole != "tier3"){
		props.history.push("/")
	}	
},[]) */

const [state, setState] = useState()
const [selection, setSelection] = useState(false)

const handleSubmit= ()=>{
	if(state){
		if(state === "single_item" ){
			props.history.push("/post-project-single")
		}else if(state === "multiple_items"){
			props.history.push("/multiple-items-step1")
		}	
	}else{
		setSelection(true)
		console.log("Eror")
	}
}
const handlChange =(evt)=>{
	setSelection(false)
	setState(evt.target.value)
}

console.log(state)
	return(
		<Layout>
	  <div className="main-container">
        <div className="pp-back">
        	<img src="/images/listingSingle.jpg" alt="" />
        </div>
        <div className="pl-white-box">
        	<h2 className="pl-head">POST A LISTING</h2>
        	<div className="pl-shead">HOW BIG IS YOUR LOT ?</div>

        	<div className="lot-select-wrapper">
        		<div className="lot-radio">
        			<input type="radio" name="lot-type" value="single_item" onClick={handlChange}/>
        			<label className="lot-label">
                       <div className="lot-box">
                          <img src="/images/single-item.png" alt="" />
                       </div>
                       <div className="pl-label">SINGLE ITEM</div>
        			</label>
        		</div>
        		<div className="lot-radio">
        			<input type="radio" name="lot-type" value="multiple_items" onClick={handlChange}/>
        			<label className="lot-label">
                       <div className="lot-box">
                       	  <img src="/images/multiple-item.png" alt="" />
                       </div>
                       <div className="pl-label">MULTIPLE ITEMS</div>
        			</label>
        		</div>
        	</div>

			<div style={{width:"50%", margin:"auto", paddingBottom:"10px"}}>
				{selection &&
			<Alert severity="error" variant="outlined">Please select your lot size!</Alert>		
				}
			</div>
			
        	<div className="pl-btn">
	        	<PrimaryButton label="NEXT" 
					onsubmit={handleSubmit}
				>
	        	   <span className="material-icons">arrow_forward</span>
	            </PrimaryButton>
            </div>
        </div>
	  </div>
	  </Layout>
	)
}

export default  PostProjectLotSize