import React, { useState, useEffect } from "react";
import "./FavouriteCheckbox.css";
import { isAuthenticated } from "../../Utils/Authenticated";

const FavoriteCheckbox = (props) => { 
    
    const [favorite, setFavorite] = useState(
      props.watchlisted !== 0 ? true : false
    );
         //console.log("++++++++++++++++=",props.watchlisted)
    const handleChange = (e) => {
      if(isAuthenticated())
      {
         setFavorite(!favorite);
      }
    }

    

    return (
      <div class="favoriteCheck d-flex justify-content-center align-items-center">
        <input id={props.favIdChild} type="checkbox"  onClick={e=>{handleChange(e);props.onCall(e)}} checked={favorite} />
        <label for={props.favIdChild}>
          <span class="material-icons">
          {favorite ? "favorite" : "favorite_border"}</span>
        </label>
      </div>
    ); 
}


export default FavoriteCheckbox;
