import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./new.css";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      height: "60px",
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#21285D",
    borderRadius: "15px",
    boxShadow: "0px 4px 12px rgba(72, 191, 132, 20%)",
    backgroundColor: "var(--primColor)",
    border: "1px solid var(--primColor)",

    transition: "250ms background linear",
    "&:hover": {
      backgroundColor: "#fff",
      color: "var(--primColor)",
      boxShadow: "0px 4px 12px rgba(72, 191, 132, 20%)",
    },
  },
}))(Button);

const PrimaryButton = (props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${props.btnSize} primButton`}>
      {/* {console.log("+++++++=",props.value)} */}
      <ColorButton
        variant="contained"
        color="primary"
        disabled={props.dis}
        onClick={props.onsubmit}
        name={props.name}
        value={props.value}
        type={props.type}
      >
        {props.iconLeft}
        {props.label}
        {props.children}
      </ColorButton>
    </div>
  );
};

export default PrimaryButton;
