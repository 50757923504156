import io from 'socket.io-client';
let url = process.env.REACT_APP_SOCKET_URL;
const socket = io(url, { jsonp: false, transports: ['websocket'] });

socket.on('connect', function () {
  console.log('Socket Connected');
});

function InsertChatMessage(data){
  console.log("chat msge data at socket",data)
    socket.emit('insertMessages',data);
    console.log("test for emit fucntion");
    
}

function insertMessageListen(cb){
  console.log('insertg -------------')
    socket.on('insertMessages_result',(result_data)=>{
        console.log("log from socket jhs");
      cb(result_data);
    })
  }

const bidAddtime = (cb) => {
  console.log(cb);
  socket.on('bidAddtime', (data) => {
    console.log('jsdhsdjhb');
    
    cb(data);
  });
}

const realClosedUpdates = (cb) => {
  socket.on('realclosedupdates', (data) => {
    cb(data);
  });
}

socket.on('disconnect', function () {
  console.log('Socket Disconnected');
});
export {  InsertChatMessage ,insertMessageListen,bidAddtime, realClosedUpdates, socket };
