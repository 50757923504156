import { StepContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { request } from "../../Utils/Request";
import ReactHtmlParser from "react-html-parser";
import "./allcss.css";

const Buyerfees = (props) => {
  const [content, setContent] = useState([]);
  const getContent = async (e) => {
    const res = await request("post", "/show_static_content", {
      page_id: "Buyer Fees",
    });
    if (res && res.data && res.data.status == 'true') {
      var html = res.data.data[0].content;
      const price = await request("post","/getprice",{},false,true)
      if(price.data.success==="yes"){
        html = html.replace(/{buyer_fee1}/g , parseFloat(price.data.data[0].amount).toFixed(2));
        html = html.replace(/{buyer_fee2}/g , parseFloat(price.data.data[4].amount).toFixed(2));
        html = html.replace(/{seller_fee1}/g , parseFloat(price.data.data[2].amount).toFixed(2));
        html = html.replace(/{seller_fee2}/g , parseFloat(price.data.data[5].amount).toFixed(2));
      }
      setContent(html);
    }
  };

  useEffect((e) => {

    if(localStorage.userRole !== "tier2"){
      props.history.push("/");
    }	
    getContent();
  }, []);
  return (
    <Layout>
      <div className="static-wrap-container container-lg">
        <div className="static-content">{ReactHtmlParser(content)}</div>
      </div>
    </Layout>
  );
};
export default Buyerfees;