import React from "react";
import "./favSeller.css";
import FavoriteCheckbox from "../FavouriteCheck/FavouriteCheckbox";
import { Link, useHistory } from "react-router-dom";
import { request } from "../../Utils/Request";
import { isAuthenticated } from "../../Utils/Authenticated";
import { AvatarURL } from "../../Utils/constant";

const FSCard = (props) => {
  let history = useHistory();
  const getprofData = (id) => async (e) => {
    try {
      const res = await request(
        "post",
        "/getUserProfileDetails",
        {
          user_id: id,
        },
        false,
        true
      );
      // console.log("kkkkkkk", res);
      if (res.data.success === "yes") {
        var arr = {
          profData: "",
        };
        arr.profData = res.data.data;
        history.push({ pathname: "/seller-profile", state: arr });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const favchange = (e) => {
    if (!isAuthenticated()) {
    } else {
      props.onchange(e);
    }
  };

  return (
    <div className="media fs-card">
      <div className="media-left">
        <div className="acImg">
          <img src={props.fav.avatar?AvatarURL+props.fav.avatar:"./images/img1.png"} alt="" />
        </div>
      </div>
      <div className="media-body">
        <div className="d-flex justify-content-between align-items-center acTitle">
          <Link onClick={getprofData(props.fav.seller_id)}>
            {" "}
            <h1 className="playFair">
              {props.fav.first_name + props.fav.last_name}
            </h1>
          </Link>

          <FavoriteCheckbox
            favIdChild={props.fav.seller_id}
            watchlisted={props.watchlist}
            onCall={(e) => favchange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default FSCard;
