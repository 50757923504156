import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { callNumber, callNumber1 } from "../../Utils/constant";
import "./NewsUpdates.css";
import jsonp from "jsonp";
import { Toast, Popup } from "../../Utils/Notifications";
import Axios from "axios";
import querystring from "query-string";
import ReactHtmlParser from "react-html-parser";
import { request } from "../../Utils/Request";
// import MailchimpSubscribe from "react-mailchimp-subscribe"
var settings = {
  dots: false,
  infinite: true,
  // fade: true,
  arrows: false,
  autoplay: true,
  pauseOnHover: true,
  speed: 2500,
  autoplaySpeed: 2000,
  cssEase: "linear",
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  margin: 30,
  // variableWidth: true,
};

export default function NewsUpdates(props) {
  const [values, setValues] = useState({
    email: "",
  });

  const onsubmit = async (e) => {
    if (!/\S+@\S+\.\S+/.test(values.email)) {
      Toast.fire({ type: "error", title: "Email address is invalid!" });
    } else {
      if (!window.location.hostname.includes("localhost")) {
        const res = await request("post", "/mailchimp", {
          type: "mailchimp",
          email: values.email,
        });
        if (res.data.success) {
          setValues({ ...values, email: "" });
          return Popup.fire({ type: "success", title: "Thanks for Subscribe" });
        } else {
          return Toast.fire({ type: "error", title: res.data.msg });
        }
      }
      // const data1 = {
      //   EMAIL: values.email
      // }

      // jsonp(`//farmfayre.us1.list-manage.com/subscribe/post-json?u=f42fbdf59e92dbe607063e64f&id=3560eb77c8&${querystring.stringify(data1)}`, { param: 'c' }, (err, data) => {
      //   if (err) {
      //     console.log(err)
      //   }
      //   if (data.result === "success") {
      //     return Popup.fire({ type: "success", title: "Thanks for Subscribe" });
      //   }
      //   if (data.result === "error") {
      //     return Toast.fire({ type: "error", title: data.msg.split('<')[0] })
      //   }
      // });
      // <MailchimpSubscribe url={url} render={({subscribe})=>(
      //   subscribe(data)
      // )}  />
      // Axios.post(url,{ EMAIL:values.email})

      // setValues({...values,email:e.target.value})
    }
  };
  const handlechange = (e) => {
    setValues({ ...values, email: e.target.value });
  };

  const [content, setContent] = useState([]);
  const getContent = async (e) => {
    const res = await request("post", "/show_static_content", {
      page_id: "News and Updates",
    });
    if (res && res.data && res.data.status == "true") {
      setContent(res.data.data[0].content);
    }
  };

  useEffect((e) => {
    getContent();
  }, []);

  return (
    <div className="newsUpdates">
      <h1 className="nuTitle playFair">NEWS & UPDATES</h1>
      <div className="d-flex justify-content-between align-items-start customContainer">
        <div className="subsSection">
          <h2 className="playFair">Keep in the Loop</h2>
          <h3 className="playFair">Be the first to know</h3>
          <div class="input-group mb-2">
            <input
              type="text"
              onChange={handlechange}
              value={values.email}
              class="form-control"
              placeholder="Enter your email"
              aria-label="Enter your email"
              // aria-describedby="button-addon2"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={onsubmit}
              >
                Subscribe
              </button>
            </div>
          </div>
          <p>You can opt out of it anytime.</p>
        </div>

        <div className="news-wrapper">
          <div className="row">
            <div className="col-md-6 mb-2">
              <div className="newsCard">
                <div>{ReactHtmlParser(content)}</div>
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <div className="newsCard">
                <h1 className="playFair">Recent Price Feed </h1>
                <div>
                  <div className="thHead">
                    <div className="text-left">Lot #</div>
                    <div className="text-left">Type</div>
                    <div className="text-right">Weight (Kgs)</div>
                    {/* <div className="text-center">Age (Mths)</div> */}
                    <div className="text-right">
                      Sale Price <br />
                      (€ or €/Kg)
                    </div>
                  </div>
                  <Slider {...settings}>
                    {props.live &&
                      props.live.map((data) => (
                        <div className="tbBody">
                          <div className="text-left">
                            {/* <Link to={"/recent-items-view/" + data.id}>
                              {(data.auctionid + "").replace(/\d(?=\d{4})/g, ".")}
                            </Link> */}
                            {(data.auctionid + "").replace(/\d(?=\d{4})/g, ".")}
                          </div>
                          <div className="text-left">{data.subcategory}</div>
                          <div className="text-right">
                            {!data.avgweight ||
                            data.avgweight == 0 ||
                            data.avgweight == ""
                              ? "N/A"
                              : callNumber(Math.round(data.avgweight))}
                          </div>
                          {/* <div className="text-center">{data.content_head2}</div> */}
                          <div className="text-right">
                            {data?.content_head3 == "Not Sold by Weight"
                              ? callNumber(data.rprice)
                              : !data.avgweight ||
                                data.avgweight == 0 ||
                                data.avgweight == ""
                              ? "N/A"
                              : callNumber(
                                  parseFloat(
                                    data.rprice / data.avgweight
                                  ).toFixed(2)
                                )}
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
