import React from "react";
import { IMAGE_URL } from "../../Utils/constant";
import FavoriteCheckbox from "../FavouriteCheck/FavouriteCheckbox";
import "./RecentAuctions.css";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Button, useMediaQuery } from "@material-ui/core";
import { CountiesShortform, callNumber } from "../../Utils/constant";

const RecentAuctions = (props) => {
  let history = useHistory();
  const moveon = (id, count, mn) => (e) => {
    return history.push({ pathname: "/recent-items-view/" + id, state: mn });
  };

  const matches = useMediaQuery("(max-width:600px)");
  // console.log(props.soldIcon);

  return (
    <div className="auctionCard recentAuctions">
      <div className="acImg">
        {/* <Button
          onClick={moveon(
            props.data.id,
            props.data.lotcount,
            props.data.modelnumber
          )}
        > */}
        <img src={IMAGE_URL + props.data.file_name}></img>
        {/* </Button> */}
      </div>
      {matches ? (
        <div className="mobileView">
          <div className="acTitle">
            {/* <Button
                onClick={moveon(
                  props.data.id,
                  props.data.lotcount,
                  props.data.modelnumber
                )}
              >
              {" "} */}
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ flexDirection: "row-reverse", flexWrap: "nowrap" }}
            >
              <div className="acMisc d-flex justify-content-between align-items-center">
                <p>
                  <span class="material-icons-outlined">location_on</span>
                  {props.data.state
                    ? CountiesShortform.find(
                        (item) => item.fname == props.data.state
                      ).sname
                    : props.data.state}
                </p>
              </div>
              <h1 className="playFair">{props.data.title}</h1> {/* </Button> */}
            </div>
          </div>
          {/* <div className="soldTo d-flex justify-content-start align-items-center">
              <i class="fas fa-crown"></i>
              {props.data.winner}
            </div> */}
          <div className="acPrice">
            <p>
              Sold at{" "}
              <span>
                € {props.data.cronjob ? props.data.wprice : props.data.bprice}
              </span>
            </p>
          </div>
          <div
            className="acMisc d-flex justify-content-between align-items-center"
            style={{ flexDirection: "row", flexWrap: "nowrap" }}
          >
            {/* <p className="soldTime">
              {props.data.paid_date && moment(props.data.paid_date).isValid()
                ? moment(props.data.paid_date).format("MM/DD/YY")
                : moment(props.data.date_closed).format("MM/DD/YY")}
            </p> */}
            <p className="justify-content-end">
              Weight:{" "}
              {props.data.lotcount == "1"
                ? props.data.weight
                : callNumber(Math.round(props.data.avgweight))}{" "}
              kgs
            </p>
          </div>
          {props.soldIcon && (
            <div
              className="soldIcon"
              dangerouslySetInnerHTML={{ __html: props.soldIcon }}
            />
          )}
        </div>
      ) : (
        <>
          <div className="acTitle">
            <h1 className="playFair">{props.data.title}</h1>
            <p>Sale number: #{props.data.id}</p>
          </div>
          <div className="acPrice">
            <p>
              Sold at{" "}
              <span>
                € {props.data.cronjob ? props.data.wprice : props.data.bprice}
              </span>
            </p>
          </div>
          {/* <p className="soldTime">
            {props.data.paid_date && moment(props.data.paid_date).isValid()
              ? moment(props.data.paid_date).format("MM/DD/YY")
              : moment(props.data.date_closed).format("MM/DD/YY")}
          </p> */}
          <div className="acMisc d-flex justify-content-between align-items-center">
            <p>
              <span class="material-icons-outlined">location_on</span>
              {props.data.state
                ? CountiesShortform.find(
                    (item) => item.fname == props.data.state
                  ).sname
                : props.data.state}
            </p>
            <p>
              Weight:{" "}
              {props.data.lotcount == "1"
                ? props.data.weight
                : callNumber(Math.round(props.data.avgweight))}{" "}
              kgs
            </p>
          </div>
          {props.soldIcon && (
            <div
              className="soldIcon"
              dangerouslySetInnerHTML={{ __html: props.soldIcon }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RecentAuctions;
