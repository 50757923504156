import React, { useState } from "react";
import "./CustomDropdown.css";

const CustomDropdown = (props) => {
  const [dropdownState, setDropdown] = useState(false);

  return (
    <div
      className="customDropdown"
      onMouseLeave={() => setTimeout(() => setDropdown(false), 350)}
    >
      <a
        href="#preservePosition"
        id="preservePosition"
        className="dropdownLabel d-flex justify-content-between align-items-center"
        onClick={() => setDropdown(true)}
      >
        {props.label}
        <span class="material-icons">expand_more</span>
      </a>
      {dropdownState && (
        <ul className="dropdownOptions">
          {props.children}
          {/* Use this format for child elements
        <li>
          <a href="#">Value 1</a>
        </li> */}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
