import React,{useState} from 'react';  
import { Link } from "react-router-dom"; 

const NewAddress = props => { 

  return (
    <div className="address-card savedAddr"> 
      <div className="ac-radio text-center">
          <img src="./svg/mailbox.svg" alt="Add Address" className="mt-3 mb-3" />
          <h6>ADD NEW ADDRESS</h6> 
      </div>
    </div>
  );
};

export default NewAddress;
