import React, { useEffect, useState } from "react"; 
import Layout from "../../components/Layout";
import { request } from "../../Utils/Request";
import "./invoice.css"; 
import moment from "moment"
import { useHistory } from "react-router-dom";
import { DocumentEditorComponent, Print } from '@syncfusion/ej2-react-documenteditor';
import { parse } from "date-fns";
import { callNumber,callNumber1} from "../../Utils/constant";
import {SaleNumber} from "../../Utils/TimerProfile";
import { isAuthenticated } from "../../Utils/Authenticated";
import JwtDecode from "jwt-decode";
const MobileInvoice = (props) => { 
  let history=useHistory()
  const [type, setType] = useState('')
  const [user, setUser] = useState({})
  const [invoice, setInvoice] = useState({})
  const [details,setAdminrecord]=useState({})
  const [selladd,setAdd]=useState({})
  const [setting,setSetting]=useState({
    platformfees:0,
    providerfees:0,
    additionalfees:0,
    tax:0,
    platformfees2:0,
    additionalfees2:0,
  })
  const[val,setVal]=useState({
    auctionid:""
  })
  const[dis,setDis]=useState(false)
  const viewInvoice = async (id, user_id) => {
    
      try {
          var data = {}
          if (props.match.params.type === 'auction') {
              data = {id, hasShipping: 0, user_id: user_id}
          } else {
              data = { id, hasShipping: 0, isAuction: 0, user_id: user_id}
          }
          const res = await request('post', '/view-invoice', data, false, true)
          
          if (res.data.success === 'yes') {
              // if (props.match.params.type === 'auction') {
              //     res.data.results.arr_temp.SlibuyPayments.map((value) => {
              //         value.bprice = value.amount
              //         value.premium = ((parseFloat(value.amount)*15)/100).toFixed(2)

              //         value.tax = ((parseFloat(value.amount)*setting.tax)/100).toFixed(2)
              //         // ((parseFloat(value.amount)*3.42)/100)).toFixed(2)
              //         value.total = (parseFloat(value.amount) + ((parseFloat(value.amount)*15)/100)) 
                  
              //     })
              //     setVal({...val,auctionid:res.data.results.arr_temp.SlibuyPayments[0].auctionid})
              //     setInvoice(res.data.results.arr_temp.SlibuyPayments[0])
              //     setUser(res.data.results.arr_temp.userShippdetail)
              //     setAdminrecord(res.data.adminRecords[0])
              // } else {
                  res.data.data.map((value) => {
                      if (value.subcategory == "Cow & Calf"){
                        value.bprice = (parseFloat(value.amount)*2).toFixed(2)
                      } else {
                        value.bprice = value.amount
                      }
                      value.premium = ((parseFloat(value.amount)*10)/100).toFixed(2)
                      value.tax = ((parseFloat(value.amount)*setting.tax)/100).toFixed(2)
                      // ((parseFloat(value.amount)*3.42)/100)).toFixed(2)
                      value.total = (parseFloat(value.amount) + ((parseFloat(value.amount)*10)/100))
                  
                  })
                  console.log('view-invoice',res.data)
                  setVal({...val,auctionid:res.data.data[0].auctionid})
                  setInvoice(res.data.data[0])
                  setAdminrecord(res.data.adminRecords[0])
                  setUser(res.data.userProfile)
                  setAdd(res.data.selleraddress)
              // }
              
          }
      } catch (error) {
          console.log(error)
      }
  }

  const getDetails=async(e)=>{
		const res=await request("post","/getprice",{},false,true)
		if(res.data.success==="yes"){
           
          setSetting({...setting,
            platformfees:parseFloat(res.data.data[0].amount),
            providerfees:parseFloat(res.data.data[1].amount),
            additionalfees:parseFloat(res.data.data[2].amount),
            tax:parseFloat(res.data.data[3].amount),
            platformfees2:parseFloat(res.data.data[4].amount),
            additionalfees2:parseFloat(res.data.data[5].amount),
          })
           setDis(true)
		}
	} 

  useEffect(() => {
    setType(props.match.params.type)
    if (props.match.params.buyer_id){
      viewInvoice(props.match.params.id, props.match.params.buyer_id)
    }
    getDetails()
  },[])

   const toggleDrawer =(event) => {
    //  console.log("+++++",props.history)
    if(props.history.location.state){
     if(props.history.location.state.val==="trans"){
      props.history.push({pathname:"/transactions"})
     }
     else if(props.history.location.state.val==="sold"){
      props.history.push({pathname:"/my-auctions",state:{val:1}})
     }
    }
    else{
     if(type==="auction")
     {
       props.history.push({pathname:"/my-purchases",state:{val:1}})
     }
     else{
       props.history.push({pathname:"/my-purchases",state:{val:3}})
     }
    }
    
  };
     const print=(e)=>{
     
     const old=document.body.innerHTML
     document.body.innerHTML=document.getElementById('print').innerHTML
      window.print()
      document.body.innerHTML=old
   
     }
  return (
    <>
   <div id="print" className="container vcInvoice">
     
     <div className="invoiceCard">
       <div className="card-header d-flex justify-content-between align-items-center p-4 iwd-100">
             <div className="invoice-head">
              {invoice.shipping_status == "completed"?"Receipt":"Pre Settlement Figures"}
             </div>
             <div className="float-right">
               <h5 className="mb-0">
                Receipt #{invoice.common_invoice}
               </h5>
               Date: {details ? moment(invoice.paid_date).format('DD/MM/YYYY') : "-"}
             </div>
           </div>
           <div className="card-body">
             <div className="row mb-4">
               <div className="col-sm-6">
                <h5 className="mb-3">From:</h5>              
              <h3 className="text-dark mb-1">{selladd.first_name+' '+selladd.last_name}</h3>
              <div>{selladd.address1},{" "+selladd.address2}</div>
                 <div>
                   {selladd.city}, {selladd.state} - {selladd.zip}
                 </div>
              <div>Phone: {selladd.phone}</div>
                 
               </div>
              
               <div className="col-sm-6 text-right">
                 <h5 className="mb-3">To:</h5>
                 <h3 className="text-dark mb-1">
                 {user.first_name+' '+user.last_name}
                 </h3>
 <div>{user.address1},{" "+user.address2}</div>
                 <div>
                   {user.city},{user.state} - {user.zip}
                 </div>
           <div>Phone: {user.phone}</div>
               </div>
             </div>
             <h4 className="invoiceTitle">Transaction Details</h4>
             {/* {type!=="auction"? */}
             <div className="transDet d-flex justify-content-between flex-wrap">
               <div className="invDiv">
                 <p>Transaction Id</p>
 <span>#{details ? details.transid : "-"}</span>
               </div>
               <div className="invDiv">
                 <p>Created on</p>
                 <span>{details ? moment(details.pay_date).format('DD/MM/YYYY') : "-"}</span>
               </div>
               <div className="invDiv">
                 <p>Transaction card type</p>
      <span>{details ? details.pay_type : "-"}</span>
               </div>
               <div className="invDiv">
                 <p>Invoice status</p>
                 <span>{details ? 'Successful' : 'Unpaid'}</span>
               </div>
             </div>
             {/* :""} */}
 
             <h4 className="invoiceTitle mt-5">Product Details</h4>
 
             <div className="table-responsive">
               <table className="table table-striped">
                 <thead>
                   <tr>
                     <th className="center">Sale No</th>
                     <th>Item</th>
                     <th className="right">Price</th>
                   </tr>
                 </thead>
                 <tbody>
                  <tr>
  <td className="center">{SaleNumber(invoice.location,invoice.date_added,invoice.date_closed)+val.auctionid.toString().slice(val.auctionid.toString().length-4)}</td>
 <td className="left strong">{invoice.title}</td>
 <td className="right">€ {parseFloat(invoice.bprice).toFixed(2)}</td>
                     </tr>
                 </tbody>
               </table>
             </div>
             <div className="row mt-5">
               <div className="col-12">
                 <div className="table-responsive">
                   <table className="table table-clear vcInvTot">
                     <tbody>
                       <tr>
                         <td className="left" align="right">
                           <span className="text-dark">Total</span>
                         </td>
                         <td className="right">
 <span className="text-dark">€ {callNumber1(parseFloat(invoice.amount * invoice.qty).toFixed(2))}</span>
                         </td>
                       </tr>
                       <tr>
                           <td className="left" align="right">
                             <span className="text-dark">
                               Adjustment - 10%
                             </span>
                           </td>
                           <td className="right">
                             <span className="text-dark">
                               € {callNumber1(parseFloat(invoice.buypremium_amount).toFixed(2))}
                             </span>
                           </td>
                         </tr> 
                       {/* <tr>
                         <td className="left" align="right">
                           <span className="text-dark">
                            VAT - 6%
                           </span>
                         </td>
                         <td className="right">
                           <span className="text-dark">
                             € {callNumber(Math.round(invoice.tax))}
                           </span>
                         </td>
                       </tr> */}
                       <tr>
                         <td className="left" align="right">
                           <span className="text-dark">
                               Platform fees (incl. VAT):
                           </span>
                         </td>
                         <td className="right">
                           <span className="text-dark">
                             € {dis?callNumber1(parseFloat(invoice.commission).toFixed(2)):""}
                           </span>
                         </td>
                       </tr>
                       <tr>
                         <td className="left" align="right">
                           <span className="text-dark">
                           Post Sale Haulage fees (incl. VAT):
                           </span>
                         </td>
                         <td className="right">
                           <span className="text-dark">
                           € {callNumber1((parseFloat(invoice.shipping_amount)/2).toFixed(2))}
                           </span>
                         </td>
                       </tr>
                       <tr>
                         <td className="left" align="right">
                           <span className="text-dark">
                           Payment Providers fees:
                           </span>
                         </td>
                         <td className="right">
                           <span className="text-dark">
                             € {dis?callNumber1((parseFloat(invoice.transaction_fee) - parseFloat(invoice.featured_fee || 5)).toFixed(2)):""}
                           </span>
                         </td>
                       </tr>
                       <tr>
                         <td className="left" align="right">
                           <span className="text-dark">
                           User Wallet Balance:
                           </span>
                         </td>
                         <td className="right">
                           <span className="text-dark">
                             € {invoice.wallet_amount}
                           </span>
                         </td>
                       </tr>
                       <tr style={{ background: "#f3f3f3" }}>
                         <td className="left" align="right">
                           <strong className="text-dark">Grand total</strong>
                         </td>
                         <td className="right">
                           {dis?
                           <strong className="text-dark">
                              € {callNumber1((parseFloat(invoice.total_invoice)+parseFloat(invoice.transaction_fee)-parseFloat(invoice.featured_fee)).toFixed(2))}
                           </strong>:""}
                         </td>
                       </tr>
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>
           </div>
     </div>
   </div>
  
   
    </>
  );
};

export default MobileInvoice;
