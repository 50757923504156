import React from "react";
import InputField from "../../components/Inputs/InputField";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import Layout from "../../components/Layout";
import "./ContactUs.css";
import UseForm from "../../Utils/UserForm";
import { contactus } from "../../Utils/Validators";
import { request } from "../../Utils/Request";
import { Toast } from "../../Utils/Notifications";

const ContactUs = () => {
  const formSubmit = async (e) => {
    var data = {
      from_email: values.email,
      messages: values.Message,
      name: values.first_name.toString() + " " + values.last_name.toString(),
      phone_number: "-",
      subject: "From buyer",
      to_email: "contactus@farmfayre.com",
      is_send_email: true
    };
    const res = await request("post", "/send_contactus", data, false, false);
    if (res.data.success) {
      Toast.fire({ type: "success", title: "Message sent successfully" });
      setValues({
        first_name: "",
        last_name: "",
        email: "",
        Message:""
      })
      setState(false)
    } else {
      Toast.fire({ type: "error", title: res.data.data.error });
    }
  };
  var {
    handleSubmit,
    handleChange,
    values,
    errors,
    state,
    setValues,
    setState
  } = UseForm(formSubmit, contactus);
  return (
    <Layout>
      <div className="contactUs customContainer">
        {/* <div className="logoContactUs">
          <img src="/svg/logo.svg" alt="Farm Fayre" height="50" />
        </div> */}

        <h3 className="contactTitle">
          Feel free to contact us with any queries, or feedback that can help us
          improve our service.
        </h3>
        <p className="contactAddr">
          A: 11 Patricks Street, Kilkenny,<br></br>
          {/* P: +353 (0)86 0761 210 <br></br> */}
          P: +353 (0)56 7801 699 <br></br>
          E: contactus@farmfayre.com
        </p>
        <div className="row contactForm">
          <div className="col-md-6 col-12">
            <object type="image/svg+xml" data="./svg/contact.svg" />
          </div>
          <div className="col-md-6 col-12">
            <h3>Contact Us</h3>
            <form>
              <InputField
                label="First name"
                placeholder="Enter your first name"
                type="text"
                name="first_name"
                value={values.first_name}
                onchange={handleChange}
                helperText={state && errors.first_name}
                // con={false}
              />
              <InputField
                label="Last name"
                placeholder="Enter your last name"
                type="text"
                // con={false}
                name="last_name"
                value={values.last_name}
                onchange={handleChange}
                helperText={state && errors.last_name}
              />
              <InputField
                label="Email address"
                placeholder="Enter your email address"
                type="email"
                name="email"
                onchange={handleChange}
                value={values.email}
                helperText={state && errors.email}
              />
              <InputField
                label="Message"
                placeholder="Enter a message"
                multiline={true}
                type="text"
                name="Message"
                onchange={handleChange}
                value={values.Message}
                helperText={state && errors.msg}
              />

              <PrimaryButton onsubmit={handleSubmit} label="Send message" />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
