import React from "react"; 
import { Link, useHistory,withRouter } from 'react-router-dom';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Button, Divider, ListItem } from "@material-ui/core";
import DashboardMenu from './DashboardMenu'
import Layout from "../Layout"
import './dashboard.css'
import { isAuthenticated } from "../../Utils/Authenticated";
import JwtDecode from "jwt-decode";

const DBLayout  = ({dashboardtype, ...props}) => {  

  const [menu, setMenu] = React.useState({
    left: false,
  });

   const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenu({ ...menu, [anchor]: open });
  }; 

  const history = useHistory();

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="ffLogoHead d-flex justify-content-between align-items-center">
        <Link to="/">
          <img src="/svg/logo.svg" alt="Farm Fayre" height="50" />
        </Link>
        <Button
          className="headDrawerClose"
          onClick={toggleDrawer("left", false)}
        >
          <span className="material-icons">clear</span>
        </Button>
      </div>
      <DashboardMenu haulier={dashboardtype} />
    </div>
  ); 

  return ( 
  	<Layout>
	    <div className={"dashboard-wrapper"}> 
      {/* JwtDecode(isAuthenticated()).role==="haulier"?true: */}
	       <DashboardMenu haulier={JwtDecode(isAuthenticated()).role==="haulier"?true:false} />
	       <div className="dashboard-content">
	          <div className="db-cheader">
	          	  {props.title} 
	          	  <a onClick={history.goBack}> <span className="material-icons">arrow_backward</span> Back</a>
	          	  <a className="menu-mobile" onClick={toggleDrawer("left", true)}>Menu</a>
	          </div>
	          {props.children}
	       </div>
	       <SwipeableDrawer
            className="headerDrawer"
            anchor={"left"}
            open={menu["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
	    </div>
    </Layout>
  );
}

export default withRouter(DBLayout);
