import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: "3em",
    minWidth: "100%",
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--primColor)",
    },
    "& .MuiInput-underline": {
      paddingBottom: "1em",
      textAlign: "left",
    },
    "& label.Mui-focused": {
      color: "#21285D",
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectField = (props) => {
  const classes = useStyles();
/*   const [selectValue, setselectValue] = React.useState("");

  const handleChange = (event) => {
    this.props.onchange()
     setselectValue(event.target.value);
   }; */
    console.log("+++++++++++++++=",props.value)
  return (
    <div>
      <FormControl className={classes.formControl}>
  <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name= {props.name}
          value={props.value}
          onChange={props.onchange}
          disabled={props.disabled}
          
        >
          {/* {props.selectMenuHeader?<option>{props.selectMenuHeader}</option>:""} */}
          {props.selectMenu &&

          props.selectMenu.map((m)=>
            <MenuItem value={m}>{m}</MenuItem>
          )
        }
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectField;
