import { Button } from '@material-ui/core';
import React,{useState} from 'react';  
import { Link } from "react-router-dom";

const SavedAddress = props => { 

  
  return (
    <>
    <div className="address-card savedAddr">
  
      <input name="address-card" type="radio" checked={props.value} /> 
      <div className="ac-radio">
          <h6>
            {props.building} 
          </h6>
          <div>{props.street}</div>
          <div>{props.town}, {props.state} - {props.eir}</div>
          <div>
           {props.country} {props.deliverytype && <span className="delivery-type">{props.deliverytype}</span>}</div>
          <span className="material-icons">check_circle</span> 
      </div>
    </div>
    {props.dis!=="display"?
    <div className="edit-card">
    <Button value={props.ke} onClick={e=>props.edi(props.ke)}>
         <span className="material-icons">edit</span>Edit
         </Button>
         <Button name={props.ke} onClick={e=>props.del(props.ke)}>
         <span className="material-icons">delete</span>Delete
         </Button>
    </div>:""}
    </>
  );
};

export default SavedAddress;
