import React, { useEffect, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import ImageGallery from "react-image-gallery";
import { useHistory } from "react-router-dom";
import { request } from "../../Utils/Request";
import JwtDecode from "jwt-decode";
import { isAuthenticated } from "../../Utils/Authenticated";
import { TimetoDate, TimetoDay } from "../../Utils/TimerProfile";
import { IMAGE_URL } from "../../Utils/constant";
import { AvatarURL } from "../../Utils/constant";
import { Button } from "@material-ui/core";
import moment from "moment";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import { BreedshortNames, BreedshortNamesICBF } from "../../Utils/constant";
// var key=1;
// const arr=[];
const MultiItem = (props) => {
  // const images=[];
  // const [product,setProduct]=useState({
  //   products:[]
  // })
  const { proddetails } = props;
  const [Next, setNext] = useState({
    details: [],
  });
  const [display, setDis] = useState(false);
  const [key, setKey] = useState(0);

  const getProductdetails = async () => {
    var user_id = !isAuthenticated() ? 0 : JwtDecode(isAuthenticated()).id;
    const res = await request(
      "post",
      "/getprodetails_multiple",
      { userid: user_id, product_id: props.id },
      false,
      true
    );

    const updatedResults = res.data.results.map((result) => {
      const contentHead = result.content_head1;
      const breeds = contentHead.split(",");

      const shortNames = breeds.map((breed) => {
        const foundBreed = BreedshortNames.find(
          (b) => b.fname.trim() === breed.trim()
        );
        const foundBreedICBF = BreedshortNamesICBF.find(
          (b) => b.fname.trim() === breed.trim()
        );
        return foundBreed
          ? foundBreed.sname
          : foundBreedICBF
          ? foundBreedICBF.sname
          : breed.trim();
      });

      return {
        ...result,
        content_head1: shortNames.join(","),
      };
    });

    setNext({
      ...Next,
      details: updatedResults,
      attachments: res.data.attachments,
    });

    setDis(true);
  };

  const Nex = (e) => {
    setKey(parseInt(key) + 1);
  };
  // const callimage = (e) => {
  //   const images = [];
  //   Next.attachments[`product_image${key + 2}`].map((val) =>
  //     images.push({ original: AvatarURL + val, thumbnail: AvatarURL + val })
  //   );
  //   return images;
  // };
  const callimage = (e) => {
    const images = [];
    const attachmentKey = `product_image${key + 2}`;
    // Check if attachments exist and are not empty
    if (Next.attachments[attachmentKey] && Next.attachments[attachmentKey].length > 0) {
      Next.attachments[attachmentKey].map((val) =>
        images.push({ original: AvatarURL + val, thumbnail: AvatarURL + val })
      );
    } else if (props.prodImages.length > 0) {
      // If attachments are empty
       props.prodImages.map((val) =>{
        if(val.original){
          images.push(val) 
           }
         }
        );
    }
  
    return images;
  };
  const Pre = (e) => {
    setKey(parseInt(key) - 1);
  };

  useEffect(() => {
    getProductdetails();
  }, []);

  // const images = [
  //   {
  //     original: '/images/pv-img.png',
  //     thumbnail: '/images/pv-img.png',
  //   },
  //   {
  //     original: '/images/pv-thumb1.png',
  //     thumbnail: '/images/pv-thumb1.png',
  //   },
  //   {
  //     original: '/images/pv-thumb2.png',
  //     thumbnail: '/images/pv-thumb2.png',
  //   },
  //   {
  //     original: '/images/pv-thumb3.png',
  //     thumbnail: '/images/pv-thumb3.png',
  //   }
  // ];

  return (
    <div className="sideProduct">
      {display ? (
        <>
          {/* { console.log("++++++++", key,Next.details.length)} */}

          <div className="mi-item-count">
            Item {parseInt(key) + 1} of {Next.details.length - 1}
          </div>
          <div className="mi-title">
            {Next.details[key + 1].auctionlot_title}
          </div>
          <ImageGallery
            items={callimage()}
            thumbnailPosition="bottom"
            showNav={true}
            showBullets={false}
            showFullscreenButton={false}
            showPlayButton={false}
          />
          <div className="row">
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Weight(Kgs)</div>
              <div className="pcon-txt">
                {Next.details[key + 1].weight == 0
                  ? "N/A"
                  : Next.details[key + 1].weight + " kg"}
              </div>
            </div>

            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Sex</div>
              <div className="pcon-txt">
                {Next.details[key + 1].content_head5}
              </div>
            </div>
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">D.O.B</div>
              <div className="pcon-txt">
                {" "}
                {moment(Next.details[key + 1].content_head2).format(
                  "DD/MM/YYYY"
                )}
              </div>
              {/* <div className="pcon-txt"> {TimetoDate(Next.details[key+1].content_head2)}</div> */}
            </div>

            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Breed</div>
              <div className="pcon-txt">
                {Next.details[key + 1].content_head1}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">TB Test Date</div>
              <div className="pcon-txt">
                {moment(Next.details[key + 1].inspectiontime).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">QA</div>
              <div className="pcon-txt">
                {proddetails.extra_fields
                  ? JSON.parse(proddetails.extra_fields).qa_farm
                  : "No"}
              </div>
            </div>{" "}
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Designations/Clubs</div>{" "}
              <div className="pcon-txt">{props.qa ? props.qa : "No"}</div>
            </div>
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Export</div>

              <div className="pcon-txt"> {Next.details[key + 1].finish}</div>
            </div>
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Days in Herd</div>

              <div className="pcon-txt">
                {TimetoDay(Next.details[key + 1].content_head4)}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Calf Reg</div>
              <div className="pcon-txt">
                {Next.details[key + 1].manufacturer}
              </div>
            </div>
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Movement</div>
              <div className="pcon-txt">{Next.details[key + 1].height}</div>
            </div>
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Section</div>
              <div className="pcon-txt">
                {Next.details[key + 1].category_id}
              </div>
            </div>
            <div className="col-6 col-md-3 form-group">
              <div className="pcon-head">Type</div>
              <div className="pcon-txt">
                {Next.details[key + 1].subcategory}
              </div>
            </div>
            {Next.details[key + 1].auctionnotes ? (
              <div className="col-12 col-md-12 form-group">
                <div className="pcon-head">Notes</div>
                <div className="pcon-txt">
                  {Next.details[key + 1].auctionnotes != "undefined"
                    ? Next.details[key + 1].auctionnotes
                    : "-"}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* {Next.details[key + 1].family_tree ? ( */}
            <div className="col-12 col-md-12 form-group">
              <div className="pcon-head">Family Tree</div>
              <div className="pcon-txt">
                {Next.details[key + 1].family_tree}
              </div>
            </div>
            {/* ) : (
              ""
            )} */}
          </div>

          <div className="mi-btns-wrap text-center">
            <Button
              onClick={Pre}
              disabled={key == 0 ? true : false}
              style={{ backgroundColor: "#76bc43" }}
              className="prev-item"
            >
              <span className="material-icons">arrow_back</span> PREVIOUS ITEM
            </Button>
            <Button
              onClick={Nex}
              disabled={key == Next.details.length - 2 ? true : false}
              style={{ backgroundColor: "#76bc43" }}
              className="next-item"
            >
              NEXT ITEM <span className="material-icons">arrow_forward</span>
            </Button>
          </div>
          <div className="mi-item-count text-center mt-2">
            Item {parseInt(key) + 1} of {Next.details.length - 1}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MultiItem;
