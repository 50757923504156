import React, { useEffect, useState } from "react";
import { request } from "../../Utils/Request";
import { AvatarURL } from "../../Utils/constant";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import JwtDecode from "jwt-decode";
import { isAuthenticated } from "../../Utils/Authenticated";
import FFModal from "../../components/Modal";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

const DashboardMenu = ({ haulier, ...props }) => {
  const [profData, setProfdata] = useState({
    name: "",
    image: "",
  });
  const [modalopen, setModelopen] = React.useState(false);
  const toggleModal = () => {
    setModelopen(!modalopen);
  };

  const getprofData = async () => {
    try {
      const res = await request(
        "post",
        "/getUserProfileDetails",
        {
          user_id: localStorage.userId,
        },
        false,
        true
      );
      if (res.data.success === "yes") {
        setProfdata({
          name: res.data.data.first_name,
          image: res.data.data.avatar,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleClickRefer = (e) => {

  //   if (localStorage.userRole == "tier1") {
  //     e.preventDefault()
  //     toggleModal()
  //   }
  // };

  useEffect(() => {
    getprofData();
  }, []);

  console.log(haulier);

  const Capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className="dashboard-menu">
        {console.log(localStorage.userRole, "localStorage.userRole")}
        <div className="user-img">
          <img
            className="user-img"
            src={
              profData.image !== ""
                ? AvatarURL + profData.image
                : "/images/user-img.svg"
            }
            alt="profile.jpg"
          />
          {/* 	<img src="/images/user-img.svg" alt="" />  */}
        </div>
        <div className="user-name">Hello {Capitalize(profData.name)}</div>
        <ul className="db-menu">
          <li>
            <NavLink to="/profile" activeClassName="active">
              <span className="material-icons">person</span> My Profile
            </NavLink>
          </li>
          <li className="referNEarn">
            <NavLink to="/refer-and-earn" activeClassName="active">
              <span>€</span> Refer & Earn
            </NavLink>
          </li>
          {!haulier && JwtDecode(isAuthenticated()).role !== "tier1" ? (
            <>
              <li className="notifDash">
                <NavLink to="/my-purchases" activeClassName="active">
                  <span className="material-icons">shopping_basket</span> My
                  Purchases
                  {/* <span class="notifCount">4</span> */}
                </NavLink>
              </li>
              {JwtDecode(isAuthenticated()).role !== "tier2" && (
                <li>
                  <NavLink to="my-auctions" activeClassName="active">
                    <span className="material-icons">gavel</span> My Sales
                  </NavLink>
                </li>
              )}
            </>
          ) : (
            ""
          )}
          <li>
            <NavLink to="/favourites" activeClassName="active">
              <span className="material-icons">favorite</span> Favourites
            </NavLink>
          </li>
          <li>
            <NavLink to="/notifications" activeClassName="active">
              <span className="material-icons">notifications</span>{" "}
              Notifications
            </NavLink>
          </li>
          <li>
            <NavLink to="/saved-details" activeClassName="active">
              <span className="material-icons">bookmark</span> Saved Details
            </NavLink>
          </li>
          {localStorage.userRole === "haulier" ? (
            <li>
              <NavLink to="/haulier-dashboard" activeClassName="active">
                <span className="material-icons">local_shipping</span> Haulier
              </NavLink>
            </li>
          ) : (
            <>
              <li>
                <NavLink to="/transactions" activeClassName="active">
                  <span className="material-icons">receipt</span> Transactions
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </div>

      <FFModal open={modalopen} handleClose={toggleModal} modaltitle="Message">
        <div style={{ fontWeight: "500" }}>
          <p className="card-text">
            If you wish to benefit from our Refer and Earn program please
            register as a Buyer.
          </p>

          <div className="modalCTA d-flex justify-content-end align-items-center">
            <Button onClick={toggleModal}>Cancel</Button>

            <PrimaryButton
              label={"Register"}
              onsubmit={() => {
                window.location.href = "/Reg_tier_2";
              }}
            />
          </div>
        </div>
      </FFModal>
    </>
  );
};

export default DashboardMenu;
