import React, { useState, useEffect } from "react";
import { request } from "../../Utils/Request"
import { isAuthenticated } from "../../Utils/Authenticated";
import {DateFormat, TimeLeft,TimetoDays} from "../../Utils/TimerProfile"
import Pagination from "../../components/Pagination/Pagination"
import { Link } from "react-router-dom"; 
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
var a=1;
const Closed = () => { 
   /* useEffect(()=>{
	if(!isAuthenticated()){
		props.history.push("/")
	}	
},[]) */

const [productsData, setProductsdata] = useState({
  productDetail:[],
  totallist:0,
  pageperList:10,
  page:1
})
const handlePageChange = (evt, page)=>{
  setProductsdata({
    ...productsData, page:page
  })
  a=parseInt(a)+10;
}

const asending=(e)=>{
  //  console.log("+++++++++",
  productsData.productDetail.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
   setProductsdata({...productsData})
}

const desending=(e)=>{
  
  productsData.productDetail.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
  productsData.productDetail.reverse()
  setProductsdata({...productsData})
  
}

const getSellerProducts = async () =>{
  const res= await request(
    "post",
    "/getSellerProducts",
    { status: 'closed', limit: productsData.pageperList, page: productsData.page }, false, true
  )
console.log(res.data)
if(res.data.status==="yes"){
  res.data.data.result.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
  setProductsdata({
    ...productsData, productDetail :res.data.data.result, totallist:res.data.data.length
  })
}
}

useEffect(()=>{
  getSellerProducts()
},[productsData.page])

console.log(productsData)


  return (
    <div className="ff-resp-table table-responsive">
    <table className="table w-100">
      <thead className="mobile-hide">
        <tr>
          <th>#</th>
          <th>Lot name</th>
          <th>Posted on<ArrowDropUpIcon onClick={asending}/><ArrowDropDownIcon onClick={desending}/></th>
          <th>Status</th> 
        </tr>
      </thead>
      <tbody>
      {productsData.productDetail && productsData.productDetail.map((item,key)=>
        <tr>
          <td className="resp-td" data-label="#">
      <div className="rtc-wrap">{parseInt(a)+parseInt(key)}</div>
          </td>
          <td className="resp-td" data-label="Lot name">
          <Link to={"/multi-items-view/"+item.id} style={{color:"var(--primColor)"}}><div className="rtc-wrap">{item.title}</div></Link>
          </td>
          <td className="resp-td" data-label="Posted on">
            <div className="rtc-wrap">
              {DateFormat(item.date_added)}
            </div>
          </td>
          <td className="resp-td" data-label="Status">
            <div className="rtc-wrap">{item.market_status}</div>
          </td>
        </tr> 
      )}
      </tbody>
    </table>
    <Pagination 
        count = {Math.ceil(productsData.totallist / productsData.pageperList)}
        onchange = {handlePageChange}
        />
    </div>
  );
};

export default Closed;
