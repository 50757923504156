import moment from "moment-timezone";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const UseForm = (callback, validate) => {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    heard_from: "",
    refer_address: "",
    refer_email: "",
    refer_phone: "",
    company_name: "",
    username: "",
    checkedA: true,
    checkedB: true,
    no_of_animal_type: false,
    mailing_address: "",
    herd_number: [],
    herd_num1: [],
    secondary_mailing_address: "",
    product_image: [],
    present_country: "Ireland",
    delivery_country: "Ireland",
    collection_country: "Ireland",
    documet: "",
    bank: "",
    branch_identifier: "",
    account_number: "",
    // date:"/",
    phone_number: "+353",
    phone: "+353",
    type: "debit card",
    enablebid: false,
    payment_type: "bankDetails",
    singleitemlot_pickup_location: "present_address",
    multiItems_pickup_location: "present_address",
    singleitemlot_truck_turn: "no",
    singleitemlot_pref_times: "no",
    singleitemlot_qualityAssuranceFarm: "no",
    singleitemlot_auction: true,
    singleitemlot_buynow: true,
    singleitemlot_respricetype_checked: "private",
    singleitemlot_linklots: "no",
    multiItems_assurance: [],
    multiItems_icbf: "no",
    multiItems_truck_turn: "no",
    multiItems_pref_times: "no",
    eir_code: "",
    zipcode: "",
    present_eir_code: "",
    collection_eir_code: "",
    delivery_eir_code: "",
    multiItems_auction_checked: true,
    multiItems_buynow_checked: true,
    multiItems_resprice_checked: "private",
    multiItems_linklots: "no",
    cvv: "",
    card_name: "",
    date: "",
    card_number: "",
    sellertime: [],
    sellertime_end: [],
    buyertime: [],
    buyertime_end: [],
    present_buildingName: "",
    present_street_name: "",
    present_town: "",
    present_county: "",
    init_amount: "",
    multiItems_estimation_avg: "",
    multiItems_first_weight: "",
    multiItems_first_sex: [],
    multiItems_first_cardissued: new Date().setFullYear(
      new Date().getFullYear()
    ),
    multiItems_first_cardissued1: new Date().setFullYear(
      new Date().getFullYear()
    ),
    multiItems_first_export: "",
    multiItems_first_calfReg: "",
    multiItems_first_breed: [],
    multiItems_dob_range: [],
    multiItems_qafarm: "No",

    FLterms: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState(false);
  /* const [selectedDate, setSelectedDate] = useState(new Date()); */

  const handleSubmit = (event) => {
    event.preventDefault();
    setState(true);
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleChange = (evt) => {
    var { name, value } = evt.target;
    //console.log("wwwwwwwwwwwwwwwwwwwwwwww",name,value)
    if (
      name === "checkedA" ||
      name === "terms" ||
      name === "checkedB" ||
      name === "singleitemlot_auction" ||
      name === "singleitemlot_buynow" ||
      name === "multiItems_buynow_checked" ||
      name === "multiItems_auction_checked" ||
      name === "password_toggle"
    ) {
      value = evt.target.checked;
    }
    if (
      name === "date" &&
      value.length == 2 &&
      !values.date.toString().includes("/")
    ) {
      //   evt.target =evt.target+"/";
      value = value + "/";
      console.log("sett", value.length);
    }

    if (name === "refer_phone") {
      value = value.replace(/[^0-9]/g, "");
    }
    if (name === "no_of_animals" && value > 99) {
      return null;
    }
    if(name === "card_number") {
      if(value.length <= 16){
        return setValues({
          ...values,
          [name]: value,
        })
      }else{
      return null
      }
    }
    if(name === "date" && value.length > 5) {
      return null     
    }
    if(name === "cvv" && value.length > 3) {
      return null     
    }
    if ((name === "multiItems_type" && value == "Cow") || value == "Heifer") {
      return setValues({
        ...values,
        [name]: value,
        multiItems_first_sex: ["Female"],
      });
    } else if (
      (name === "multiItems_type" && value == "Bull") ||
      value == "Bullock"
    ) {
      return setValues({
        ...values,
        [name]: value,
        multiItems_first_sex: ["Male"],
      });
    }
    if (name === "multiItems_first_export") {
      return setValues({
        ...values,
        [name]: value == "All Suitable for Export" ? "Yes" : "No",
      });
    }
    if (name == "multiItems_first_movement" && value == 0) {
      return setValues({
        ...values,
        [name]: value,
        multiItems_first_cardissued: values.multiItems_first_dob,
        multiItems_first_cardissued1: new Date(),
      });
    }
    if (name == "multiItems_assurance") {
      const QA = value.some((item) => item.value == 1);

      return setValues({
        ...values,
        [name]: value,
        multiItems_qafarm: QA ? "Yes" : "No",
      });
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDateChange = (date, name) => {
    return setValues({
      ...values,
      [name]: date,
    });
    //  if (name === "multiItems_dob_range" && date.length > 1) {
    //     return setValues({
    //       ...values,
    //       [name]: date,
    //       ["multiItems_first_dob"]: date[0],
    //       ["multiItems_first_dob1"]: date[1],
    //     });
    //   }
    //    else if (name === "multiItems_movement_range" && date.length > 1) {
    //     return setValues({
    //       ...values,
    //       [name]: date,
    //       ["multiItems_first_cardissued"]: date[0],
    //       ["multiItems_first_cardissued1"]: date[1],
    //     });
    //   } else {
    //     return setValues({
    //       ...values,
    //       [name]: date,
    //     });
    //   }
  };

  // For change values

  useEffect(() => {
    setIsSubmitting(false);
    setErrors(validate(values));
    // eslint-disable-next-line
  }, [values]);

  // For errors
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line
  }, [errors]);

  return {
    handleSubmit,
    handleChange,
    handleDateChange,
    values,
    errors,
    state,
    setValues,
    setState,
    setErrors,
  };
};

export default UseForm;
