import React, { useState } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

const Bidhistory = (props) => {
   

  return (
    <>
      <div className="bid-history-head">Bid History</div> 
      <div className="bid-history-wrapper">
        {props.bidHistory &&
          props.bidHistory.data.map((data) => (
            <div className="bh-con-box">
              <div>{data.namesec}</div>
              <div>€ {data.proposed_amount}</div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Bidhistory;
