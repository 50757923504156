import React, { useState, useEffect } from "react";
import { request } from "../../Utils/Request"
import { isAuthenticated } from "../../Utils/Authenticated";
import {DateFormat, TimeLeft,TimetoDays} from "../../Utils/TimerProfile"
import Pagination from "../../components/Pagination/Pagination"
import { Link } from "react-router-dom"; 
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
var a=1;
const Active = () => { 
  /* useEffect(()=>{
	if(!isAuthenticated()){
		props.history.push("/")
	}	
},[]) */

const [productsData, setProductsdata] = useState({
  productDetail:[],
  totallist:0,
  pageperList:10,
  page:1
})

const asending=(e)=>{
  //  console.log("+++++++++",
  productsData.productDetail.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
   setProductsdata({...productsData})
}
const asending1=(e)=>{
  //  console.log("+++++++++",
  productsData.productDetail.sort((a,b)=>TimeLeft(a["date_closed"])-TimeLeft(b["date_closed"]))
   setProductsdata({...productsData})
}

const desending=(e)=>{
  
  productsData.productDetail.sort((a,b)=>TimeLeft(a["date_added"])-TimeLeft(b["date_added"]))
  productsData.productDetail.reverse()
  setProductsdata({...productsData})
  
}
const desending1=(e)=>{
  
  productsData.productDetail.sort((a,b)=>TimeLeft(a["date_closed"])-TimeLeft(b["date_closed"]))
  productsData.productDetail.reverse()
  setProductsdata({...productsData})
  
}

const handlePageChange = (evt, page)=>{
  setProductsdata({
    ...productsData, page:page
  })
  a=parseInt(a)+10;
}


const call11=async(e)=>{
  console.log("++++",e.target.value)
//   const res= await request(
//     "post",
//     "/getSellerProducts",

// /*     { status: 'open', limit: productsData.pageperList, page: productsData.page }, false, true */
//     { status: '', limit: productsData.pageperList, page: productsData.page }, false, true
//   )
}

const getSellerProducts = async () =>{
  const res= await request(
    "post",
    "/getSellerProducts",

/*     { status: 'open', limit: productsData.pageperList, page: productsData.page }, false, true */
    { status: 'open', limit: productsData.pageperList, page: productsData.page }, false, true
  )
console.log(res.data)
if(res.data.status==="yes"){
   var arr=[];
   res.data.data.result.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
   res.data.data.result.map((val)=>{
      if(val.market_status=="open")
      {
         arr.push(val)
       }
      })


  setProductsdata({
    ...productsData, productDetail :arr, totallist:res.data.data.length
  })
}
}

useEffect(()=>{
  getSellerProducts()
},[productsData.page])

console.log(productsData)

  return (
   <div className="ff-resp-table table-responsive">
    <table className="table w-100">
      <thead className="mobile-hide">
        <tr>
          <th>#</th>
          <th>Lot name</th>
          <th>Posted on<ArrowDropUpIcon onClick={asending}/><ArrowDropDownIcon onClick={desending}/></th>
          <th>Time left<ArrowDropUpIcon onClick={asending1}/><ArrowDropDownIcon onClick={desending1}/></th>
          <th>Current bid</th>
          <th>Total bids</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {productsData.productDetail && productsData.productDetail.map((item,key)=>
        <tr>
          <td className="resp-td" data-label="#">
           <div className="rtc-wrap">{parseInt(a)+parseInt(key)}</div>
          </td>
          <td className="resp-td" data-label="Lot name">
          <Link to={"/multi-items-view/"+item.id} style={{color:"var(--primColor)"}}> <div className="rtc-wrap">{item.title}</div></Link>
          </td>
          <td className="resp-td" data-label="Posted on">
            <div className="rtc-wrap">{DateFormat(item.date_added)==="Invalid date"?"Waiting For Admin Approval":DateFormat(item.date_added)}</div>
          </td>
          <td className="resp-td" data-label="Time left">
            <div className="rtc-wrap">{DateFormat(item.date_added)==="Invalid date"?"Pending Approval":TimeLeft(item.date_closed)==="Sale Closed"?"     -    ":TimeLeft(item.date_closed)}</div>
          </td>
          <td className="resp-td" data-label="Current bid">
           <div className="rtc-wrap">€ {item.latestbid}</div></td> {/* Start price added as current Bid. To be confirmed with client */}
          <td className="resp-td" data-label="Total bids">
            <div className="rtc-wrap"><a className="green-txt">{item.bidcount}</a></div>
          </td>
          <td className="resp-td" data-label="Action">
            <div className="rtc-wrap">
              <Link to={`/multi-items-view/${item.id}`} className="gray-btn">View</Link>
            </div>
          </td>
        </tr> 
        )}
      </tbody>
    </table>
    <Pagination 
    count = {Math.ceil(productsData.totallist / productsData.pageperList)}
    onchange = {handlePageChange}
    />
    </div>
  );
};

export default Active;
