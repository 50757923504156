import React from "react";  
import Layout from '../../components/Layout';
import './catlouge.css';

const CatlougeCard  = ({url,onCall,val,title, ...props}) => { 

  return ( 
    <div className="card-catlouge"> 
       <button onClick={e=>onCall(val)} ><img src={url} alt={title} className="catlouge-img" />
        <div className="catlouge-title">{title}</div></button>
    </div>
  );
}

export default CatlougeCard