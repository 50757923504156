import React,{ useEffect, useState }  from "react";
import { Link } from "react-router-dom";
import UseForm from "../../Utils/UserForm";
import {ValidatePasswordChange} from "../../Utils/Validators"
import { isAuthenticated } from "../../Utils/Authenticated";
import {Popup, Toast} from "../../Utils/Notifications"
import { request } from "../../Utils/Request"
import InputField from "../../components/Inputs/InputField";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import moment from "moment";

import "./Login.css";

const ResetPassword=(props) =>{
  
  const [user_token, setUserToken] = useState('')
  useEffect(() => {
    if (props.history.location.search) {
        setUserToken(props.history.location.search.slice(4))
    }
    if (isAuthenticated()) {
      props.history.push('/')
    }
    
  }, [])

  console.log(user_token)
  var {
    handleSubmit,
    handleChange,
    values,
    errors,
    state
  } = UseForm(submitPasswordChange,ValidatePasswordChange)
  const [loader, setLoader] = useState(false)
  
  async function submitPasswordChange(){

    try{
      const res = await request (
        "post",
        "/change-password",
       {user_token, password:values.password}
      )
      console.log(res.data)
      if(res.data.success === "yes"){
        await props.history.push('/login')
        return Toast.fire({ title: res.data.message, type:'success' })
      }
      else{

      Toast.fire({ title: res.data.message, type:'error' })
      }
    }
    catch(error){
      console.log(error)
    }
  }

  return (
    <div className="ffLogin forgotPswd">
      <div className="ffLoginCnt">
        <Link to="/">
         <img src="/svg/logo.svg" alt="Farm Fayre" height="75" />
        </Link>
        <div className="greetMsg">
          <h2 className="playFair">Reset Password</h2>
          <p className="playFair">Create a new password</p>
        </div>
        <form onSubmit = {handleSubmit}>
          <InputField
            label="New password"
            placeholder="Enter your new password"
            type="password"
            name="password"
            error={state ? errors.password && true : false }
						helperText = {state && errors.password}
            value={values.password}
            onchange= {handleChange}
          />

          <InputField
            label="Confirm password"
            placeholder="Re-enter your new password"
            type="password"
            name="confirm_password"
            error={state ? errors.confirm_password && true : false }
						helperText = {state && errors.confirm_password}
            value={values.confirm_password}
            onchange= {handleChange}
          />
          <div className="actBtn">
            <PrimaryButton label="SUBMIT" 
            type="submit" 
            />
          </div>
        </form>
      </div>
      <p className="footerText">© {moment().year()} Farm Fayre. All rights reserved.</p>
    </div>
  );
}
export default ResetPassword;
