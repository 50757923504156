import { Button } from '@material-ui/core';
import React,{useEffect, useState} from 'react';  
import { Link } from "react-router-dom";
import DBLayout from '../../components/DBLayout';  
import { request } from '../../Utils/Request';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { TimetoDays } from '../../Utils/TimerProfile';
import { callNumber } from '../../Utils/constant';
import {CSVLink} from "react-csv";
import moment from 'moment';
const Transactions = props => { 
  const [details,setDetails]=useState({
    det:[],
    data:[]
  })
 
 
 const headers=[{
   label:"#",key:"#"
 },
 {
  label:"Transaction id",key:"tid"
 },
 {
  label:"Transacted on",key:"ton"
 },
 {
  label:"Type",key:"Type"
 },
 {
  label:"Amount",key:"Amount"
 }
];


  const getdetails=async()=>{
     const res=await request("post","/buyerTransaction",{page:"1",sh_limit:"8"},false,true)   
      if(res.data.success=="yes"){
        res.data.results.sort((a,b)=>TimetoDays(a["pay_date"])-TimetoDays(b["pay_date"]))
        
       
    
      res.data.results.map((val,key)=>{
        // const da={}
        // console.log("mmmmmmmm",da)
        details.data.push({"#":key+1,"tid":val.transid,"ton":val.pay_date,"Type":val.pay_type,"Amount":callNumber(val.amount)})
      })

      setDetails({...details,det:res.data.results})

    }
    // console.log("+++++++++++",data)

    }

  useEffect(()=>{
     getdetails()
  },[])
  const asending=(e)=>{
     
   details.det.sort((a,b)=>TimetoDays(a["pay_date"])-TimetoDays(b["pay_date"]))
   details.data.sort((a,b)=>TimetoDays(a["ton"])-TimetoDays(b["ton"]))
     setDetails({...details})
  }
  const desending=(e)=>{
    details.det.sort((a,b)=>TimetoDays(a["pay_date"])-TimetoDays(b["pay_date"]))
    details.det.reverse()
    details.data.sort((a,b)=>TimetoDays(a["ton"])-TimetoDays(b["ton"]))
    details.data.reverse()
    setDetails({...details})
  }

  return (
    <DBLayout title="Transactions"> 
       <div className="form-wrapper pw-box">
          <div className="form-group text-right">
            {console.log("ddddddddddddd",details.data)}
          <CSVLink data={details.data} headers={headers}>
          <Button className="exportBtn"><span className="material-icons">description</span> Export to csv</Button>
          </CSVLink>
          </div>
          <div className="ff-resp-table table-responsive">
          <table className="table w-100">
              <thead>
                  <tr>
                    <th>#</th>
                    <th>Transaction id</th>
                    <th>Transacted on<ArrowDropUpIcon onClick={asending}/><ArrowDropDownIcon onClick={desending}/></th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
              </thead>
              <tbody>
              {!details.det.length ? 
                      <tr>
                        <td colspan="6" className="pd-zero text-center">
                          <div className="card card-body">No transactions found</div>
                        </td>
                      </tr>
                      : 
                details.det.map((data,key)=>
                  <tr>
                    <td>{key+1}</td>
                    <td>#{data.transid}</td>
                    <td>{moment(data.pay_date).format("YYYY/MM/DD hh:mm a")} </td>
                    <td>{data.pay_type}</td>
                    <td className="red-txt">€ {callNumber(data.amount)}</td>
                    <td>
                    {data.buynow_id === 0? <Link to={{pathname:"/invoice/auction/"+data.invoice_no ,state:{val:"trans"} }} className="gray-btn">{data.shipping_status == "completed"?"View Receipt":"Pre Settlement Figures"}</Link>: <Link to={{pathname:"/invoice/buynow/"+data.invoice_no,state:{val:"trans"}}} className="gray-btn">{data.shipping_status == "completed"?"View Receipt":"Pre Settlement Figures"}</Link>}
                    </td>
                  </tr>
                )}
                   
              </tbody>
          </table>
          </div>
       </div>
    </DBLayout>
  );
};

export default Transactions;
