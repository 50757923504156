import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { NumberOnly, TextOnly, specialcharacter, dallow, Phone } from "../../../Utils/constant";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: '3em',
      width: "100%",
    },
    "& .MuiInput-underline": {
      paddingBottom: "1em",
    },
    
    ".MuiFormControl-marginNormal":{
      marginTop: "0em",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--primColor)",
    },
    input: {
      fontFamily: 'Brother-1816-Light',
    },
    "label.MuiFormLabel-root.Mui-focused": {
      color: "var(--primColor)",
    },
  },
}));

const CustomInput  = (props) => {

  const classes = useStyles();

  return ( 
    <Tooltip title={props.tooltiptitle} arrow placement="top" disableHoverListener={true} disableFocusListener={props.disableTooltip}>
      <TextField
        id={props.id}
        label={props.label}
        placeholder={props.placeholder}
        type={props.type}
        name= {props.name}
        InputProps={{inputProps:{ min: 0}}}
        disabled={props.disabled}
        value = {props.value}
        onChange= {props.onchange}
        // onKeyPress={props.onKeyPress}
        inputProps={{inputProps:{onKeyPress:props.con==true?NumberOnly:props.con==false?TextOnly:props.con=="sn"?specialcharacter:props.con=="dallow"?dallow:props.con=="phone"?Phone:""}}}
        variant="outlined"
        fullWidth
        margin="normal"
        InputLabelProps={{
                    shrink: true,
        }}
        {...props}
      /> 
    </Tooltip>
  );
}

export default CustomInput;
