import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography'; 
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container'; 
import DBLayout from '../../components/DBLayout';
import Pickup from './Pickup'; 
import Delivery from './Delivery';

const TabPanel = ({ children, value, index, ...other }) => { 
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `haulier-dashboard-${index}`,
    'aria-controls': `haulier-dashboard-tabpanel-${index}`
  };
}

const HaulierDashboard = props => {
  const [value, setValue] = useState(0);

  

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <DBLayout title="Haulier" dashboardtype="haulier"> 
      <Container className="db-bids-wrapper mt-4 customTabs pw-box"> 
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="buyer-dashboard" 
        >
          <Tab label="Pick-up/Delivery" {...a11yProps(0)} /> 
          <Tab label="Delivered" {...a11yProps(1)} /> 
        </Tabs>
        <TabPanel value={value} index={0}>  
           <Pickup />
        </TabPanel>
        <TabPanel value={value} index={1}>  
           <Delivery />
        </TabPanel> 
      </Container>
    </DBLayout>
  );
};
 
export default HaulierDashboard