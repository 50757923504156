import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: '3em',
      width: "100%",
    },
    "& .MuiInput-underline": {
      paddingBottom: "1em",
    },
    
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--primColor)",
    },
    input: {
      fontFamily: 'Brother-1816-Light',
    },
    "label.MuiFormLabel-root.Mui-focused": {
      color: "var(--primColor)",
    },
  },
}));

const CustomSelect  = (props) => {


  const classes = useStyles();

  return ( 
    <Tooltip title={props.tooltiptitle} arrow placement="top">

      <TextField
        id={props.id}
        select
        label={props.label}
        SelectProps={{
          native: true,
        }} 
        fullWidth
        margin="normal"
        InputLabelProps={{
                    shrink: true,
        }}
        variant="outlined"
        disabled={props.disabled}
        name={props.name}
        value={props.value}
        onChange={props.pc_onchange}
        error ={props.error}
      >
        {props.selectMenuHeader ?
          <option value="">{props.selectMenuHeader}</option> : ""}
        { props.selectMenuNew &&
        props.selectMenuNew.map((item)=>
          <option value={item.value}>{item.name}</option>
          )}
        { props.selectMenu &&
        props.selectMenu.map((item)=>
          <option value={item}>{item}</option>
          )}
        {props.children} 

      </TextField> 
    </Tooltip>
  );
}

export default CustomSelect;
