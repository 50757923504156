import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import JwtDecode from "jwt-decode";
import { isAuthenticated } from "../../Utils/Authenticated";
import CookieConstant from "react-cookie-consent";
export default function Footer() {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <div className="footer">
      <div className="d-flex justify-content-between align-items-start customContainer">
        <div className="footerLt">
          <div className="footerLogo">
            <Link to="/">
              <img src="/svg/logo.svg" alt="Farm Fayre" height="50" />
            </Link>
          </div>
          <div className="footerSocialLinks d-flex justify-content-start align-items-center">
            <a
              target="_blank"
              href={"https://m.facebook.com/Farmfayreireland/?__tn__=%2Cg"}
            >
              <img src="/images/fb.png" />
            </a>
            <a target="_blank" href="https://twitter.com/FarmFayre">
              <img src="/images/xicon.png" />
            </a>
            <a target="_blank" href="https://www.instagram.com/farmfayre">
              <img src="/images/ig.png" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=nk_E1mI4b60"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
        <div className="footerRt">
          <ul className="d-flex justify-content-end align-items-center">
            {isAuthenticated() &&
            JwtDecode(localStorage.getItem("usertoken")).role === "tier2" ? (
              <li>
                <Link to="/buyerfees">Buyer Fees</Link>
              </li>
            ) : isAuthenticated() &&
              JwtDecode(localStorage.getItem("usertoken")).role === "tier3" ? (
              <li>
                <Link to="/sellerfees">Buyer/Seller Fees</Link>
              </li>
            ) : (
              ""
            )}
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/aboutus">About Us</Link>
            </li>
            <li>
              <Link to="/privacypolicy">Privacy</Link>
            </li>
            <li>
              <Link to="/terms">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/faq">FAQ's</Link>
            </li>
            <li>
              <a target="_blank" href="https://farmfayre.com/resources/">
                Resources
              </a>
            </li>
          </ul>

          <br></br>
          <p>© 2024 Farm Fayre. All rights reserved.</p>
        </div>
      </div>
      {!iOS && (
        <CookieConstant
          location={"bottom"}
          declineButtonText={"cancel"}
          buttonText={"Accept"}
          containerClasses="cookieConsent"
          // style={{ background: "#2B373B", width: "58%", left: "232px" }}
          buttonStyle={{
            background: "var(--primColor)",
            color: "rgb(4, 15, 74);",
            fontSize: "13px",
            marginRight: "50%",
          }}
          declineButtonStyle={{
            background: "#21285D",
            color: "var(--primColor)",
            fontSize: "13px",
          }}
          expires={150}
        >
          <span style={{ fontSize: "13px" }}>
            We use cookies and similar technologies to enable services and
            functions on our platform and to understand your interaction with
            our service.<br></br>
            By using our platform you agree to our use of these and similar
            technologies. See{" "}
            <a
              style={{ color: "#6ce811", textDecoration: "underline" }}
              target={"_blank"}
              href={"/privacypolicy"}
            >
              Privacy
            </a>{" "}
            policy
          </span>
        </CookieConstant>
      )}
      <div className="mc-pt250"></div>
    </div>
  );
}
