import React, { useEffect } from "react";  
import Layout from '../../components/Layout';
import CatlougeCard from  './CatlougeCard';
import './catlouge.css';
import {request} from '../../Utils/Request'
import { useHistory } from "react-router-dom";
const CatlougeSelect  = (props) => { 
    let history=useHistory()
  const getProductdetails=async()=>{
    const res=await request('post','/mobilesearch',{}, false, true);
    
  }
  useEffect(()=>{
         getProductdetails()
  },[])
 const onsubmit=(val)=>{
    var arr=[];

    if(val=="1"){
         arr.push("Carlow","Kildare","Kilkenny","Laois", "Wexford","Wicklow")
    }
    else if(val=="2")
    {
            arr.push("Kerry","Cork")
    }
    else if(val=="3")
    {
          arr.push("Galway","Mayo")
    }
    else if(val=="4")
    {
        arr.push("Cavan","Donegal","Monaghan")
    } 
    else if(val=="5")
    {
      arr.push("Dublin","Longford","Louth","Meath","Offaly","Westmeath")
    } 
    else if(val=="6")
    {
      arr.push( "Waterford","Tipperary","Clare","Limerick") 
    } 
    else if(val=="7")
    {
      arr.push("Leitrim","Roscommon","Sligo")
    }
    else{
       arr.push("Tyrone","Fermanagh","Down", "Antrim","Armagh","Derry")
    }
    // console.log("+++++",arr)
    props.history.push({
      pathname: "/catlouge",
      state: {
       
        loc:arr,
      },
    });
 }
 return ( 
    <Layout> 
        <div className="main-container catlouge-container ccs-wrapper">
	        <div className="container"> 
                <h2 className="catlouge-head1">REGION BASED CATALOGUE</h2> 

                <div className="row">
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge1.png"
                      title="South Leinster"
                      onCall={onsubmit}
                      val={"1"}
                    /> 
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge2.png"
                      title="Munster-1"
                      onCall={onsubmit}
                      val={"2"}
                    /> 
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge3.png"
                      title="Connacht-1"
                      onCall={onsubmit}
                      val={"3"}
                    /> 
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge4.png"
                      title="Ulster-1"
                      onCall={onsubmit}
                      val={"4"}
                    /> 
                  </div> 
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge1.png"
                      title="North Leinster"
                      onCall={onsubmit}
                      val={"5"}
                    /> 
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge2.png"
                      title="Munster-2"
                      onCall={onsubmit}
                      val={"6"}
                    /> 
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge3.png"
                      title="Connacht-2"
                      onCall={onsubmit}
                      val={"7"}
                    /> 
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 form-group">
                  	<CatlougeCard 
                      url="/images/catlouge4.png"
                      title="Ulster-2"
                      onCall={onsubmit}
                      val={"8"}
                    /> 
                  </div>
                </div>
	        </div>
	    </div>
    </Layout>
  );
}

export default CatlougeSelect