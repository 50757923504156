import { Button } from '@material-ui/core';
import React,{useState} from 'react';  
import { Link } from "react-router-dom";
import "./bank.css";
const BankCard = props => { 
 
  console.log("+++",props.ke)
  return (
  <>
    <div className="address-card">
      <input name="bank-card" type="radio" checked={props.value} /> 
      
      <div className="ac-radio">
      
          <h6>
            {props.bankname} 
          </h6>
          <div className="word-wrap">{props.isocode}</div>
          <div>{props.branchid}</div>
          <div>{props.accountno}</div>
          <span className="material-icons">check_circle</span> 
      </div>
    </div>
    <div className="edit-card">
    <Button value={props.ke} onClick={e=>props.edi(props.ke)}>
       <span className="material-icons">edit</span>Edit
       </Button>
       <Button name={props.ke} onClick={e=>props.del(props.ke)}>
       <span className="material-icons">delete</span>Delete
       </Button>
      </div>
    </>
  );
};

export default BankCard;
