  import  Swal from "sweetalert2";
  
  export const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 2000,
      showCloseButton: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
  })
  
  export const Popup = Swal.mixin({
      position: 'center',
      showConfirmButton: false,
      customClass: 'swalpop',
      timer: 2000,
  })

  export const SwalAlert = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    showCloseButton: true,
})