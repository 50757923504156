import React,{useState} from 'react';  
import { Link } from "react-router-dom";
 

const PayCard = props => { 

  return (
    <div className="address-card pc-radio-wrap mt-4">
      <input name="pay-card" type="radio" value={props.val} onChange={e=>props.onCall(e)} checked={props.value==props.val?true:false} />  
      
      <div className="ac-radio">
          <h6 className="ch-name">{props.cardholder}</h6>
          <div className="row">
            <div className="col-6 pd-label">{props.cardnumber}</div>
            <div className="col-6 pd-label text-right">Valid Thru {props.valid}</div> 
          </div> 
          <span className="material-icons">{props.value==props.val? "radio_button_checked" : "radio_button_unchecked"}</span>
      </div> 
    </div>
  );
};

export default PayCard;
