import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import Radio from "@material-ui/core/Radio";
import { isAuthenticated } from "../../Utils/Authenticated";
import { Popup, Toast } from "../../Utils/Notifications";
import { ValidatePostSingleLot } from "../../Utils/Validators";
import UseForm from "../../Utils/UserForm";
import DatePicker from "../../Utils/DatePicker";
import { request } from "../../Utils/Request";
import {
  IrishCounties,
  Section,
  Type,
  Breed,
  Weight,
  Sex,
  Age,
  weimethod,
} from "../../Utils/constant";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "../../components/Inputs/borderedInput";
import CustomSelect from "../../components/Inputs/borderedSelect";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { GreenRadio } from "../../components/Inputs/RadioBox";
import CheckBox from "../../components/Inputs/CheckBox";
import Upload from "../../components/Upload";
import "./post-project.css";
import imageCompression from "browser-image-compression";

import Layout from "../../components/Layout";
import FFModal from "../../components/Modal";
import { Button } from "@material-ui/core";

const PostProjectStep1 = (props) => {
  /*   const testRun = async ()=>{
    const resp = await request (
      "post",
      "/getProductDetails",
      {}, false, true
    )
  console.log(resp.data)
  }

  testRun()

  
  const testRun2 = async ()=>{
    const resp = await request (
      "post",
      "/getSellerProducts",
      { status: '', limit: 5, page: 1 }, false, true
    )
  console.log(resp.data)
  }

  testRun2() */

  /* useEffect(()=>{
	if(!isAuthenticated() && localStorage.userRole != "tier3"){
		props.history.push("/")
	}	
},[]) */

  const [modalopen1, setModelopen1] = React.useState(false);
  const toggleModal1 = () => {
    setModelopen1(!modalopen1);
  };

  var {
    handleChange,
    handleSubmit,
    handleDateChange,
    values,
    errors,
    state,
    setValues,
  } = UseForm(toggleModal1, ValidatePostSingleLot);

  const [options] = useState({
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });
  const onClickImages = (e) => {
    console.log(e.target.files);
    console.log(e.target.files[0]);
    Object.keys(e.target.files).map(async (value, key) => {
      var img = await imageCompression(e.target.files[key], options);
      setValues((prevState) => ({
        ...prevState,
        product_image: [...prevState.product_image, img],
      }));
      /*   setValues({ ...values, product_image: [...values.product_image, e.target.files]});   */
    });
  };

  const removeImage = (e) => {
    values.product_image.splice(values.product_image.indexOf(e), 1);
    setValues({ ...values });
  };

  const onClickVideo = (e) => {
    setValues({ ...values, product_videos: e.target.files[0] });
  };
  /* console.log(values) */

  useEffect(() => {
    const UserProfile = async () => {
      try {
        const res = await request(
          "post",
          "/getUserProfileDetails",
          {
            user_id: localStorage.userId,
          },
          false,
          true
        );
        console.log(res);

        if (res.data.success === "yes") {
          setValues({
            ...values,
            present_bldgname: res.data.data.address1,
            present_stname: res.data.data.address2,
            present_town: res.data.data.city,
            present_country: res.data.data.country,
            present_county: res.data.data.state,
            present_eircode: res.data.data.zip,
            singleitemlot_pickup_location: "present_address",
          });
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };
    UserProfile();
  }, []);

  async function postSingleItem() {
    console.log("+++++++++++++++");
    try {
      const formData = new FormData();
      formData.set("protitle", values.singleitemlot_title);
      formData.set("description", values.singleitemlot_description);
      formData.set("category_id", values.singleitemlot_section); //section as category id
      formData.set("subcategory", values.singleitemlot_type); //type as subcategory
      formData.set("contenthead1", values.singleitemlot_breed); //breed as contenthead1
      formData.set("contenthead5", values.singleitemlot_sex); //sex as contenthead5
      formData.set("weight", values.singleitemlot_weight);
      formData.set("contenthead3", values.singleitemlot_weighing_method); //weighing method as content_head3
      formData.set("contenthead2", values.singleitemlot_dob);
      //DOB as 	contenthead2
      formData.set("tags", values.singleitemlot_tagnumber); //tagnumbers as tags
      formData.set("inspectiontime", values.singleitemlot_cardissued); //cardissue date as inspectiontime
      formData.set("contenthead4", values.singleitemlot_testdate); //testdate as contenthead4
      formData.set(
        "height",
        values.singleitemlot_prevowner ? values.singleitemlot_prevowner : 0
      ); //prev owners as height

      formData.set("buyerpremium", values.singleitemlot_premiums); //premiums as buyerpremium
      formData.set("auctionnotes", values.singleitemlot_notes);
      formData.set("length", values.singleitemlot_truck_turn === "yes" ? 1 : 0); //truck turn as length
      formData.set("width", values.singleitemlot_pref_times === "yes" ? 1 : 0); //pref times as width
      values.product_image.map((image) => {
        formData.append("product_image", image);
      });
      formData.append("product_videos", values.product_videos);
      formData.set("auction", values.singleitemlot_auction ? 1 : 0);
      formData.set("buynow", values.singleitemlot_buynow ? 1 : 0);
      formData.set(
        "sprice",
        values.singleitemlot_startprice ? values.singleitemlot_startprice : 0
      );
      formData.set(
        "bprice",
        values.singleitemlot_buynowprice ? values.singleitemlot_buynowprice : 0
      );
      formData.set(
        "modelnumber",
        values.singleitemlot_respricetype_checked === "private" ? 1 : 0
      ); //modelnumber as rprice private
      formData.set(
        "rprice",
        values.singleitemlot_resprice
          ? values.singleitemlot_resprice
          : values.singleitemlot_startprice
          ? values.singleitemlot_startprice
          : 0
      );

      formData.set(
        "localpickup",
        values.singleitemlot_pickup_location === "different_address" ? 1 : 0
      ); //localpickup location as location

      formData.set(
        "city",
        values.singleitemlot_pickup_location === "different_address"
          ? values.singleitemlot_town
          : values.present_town
      ); //town as city
      //Location need to be mapped from frontend
      var state =
        values.singleitemlot_pickup_location === "different_address"
          ? values.singleitemlot_county
          : values.present_county;

      if (state == "Galway" || state == "Mayo") var province = "Connaught 1";
      else if (state == "Leitrim" || state == "Roscommon" || state == "Sligo")
        var province = "Connaught 2";
      else if (
        state == "Carlow" ||
        state == "Kildare" ||
        state == "Kilkenny" ||
        state == "Laois" ||
        state == "Wexford" ||
        state == "Wicklow" ||
        state == "Tipperary" ||
        state == "Waterford"
      )
        var province = "South East";
      else if (
        state == "Dublin" ||
        state == "Longford" ||
        state == "Louth" ||
        state == "Meath" ||
        state == "Offaly" ||
        state == "Westmeath"
      )
        var province = "North Leinster";
      else if (
        state == "Clare" ||
        state == "Limerick" ||
        state == "Tipperary" ||
        state == "Waterford"
      )
        var province = "Munster 2";
      else if (state == "Cork" || state == "Kerry") var province = "Munster 1";
      else if (
        state == "Antrim" ||
        state == "Armagh" ||
        state == "Derry" ||
        state == "Down" ||
        state == "Fermanagh" ||
        state == "Tyrone"
      )
        var province = "Ulster 2";
      else if (state == "Monaghan" || state == "Cavan" || state == "Donegal")
        var province = "Ulster 1";

      formData.set(
        "state",
        // values.singleitemlot_pickup_location === "different_address"
        //   ? values.singleitemlot_county
        //   : values.present_county
        state
      ); //county as state
      formData.set("location", province);
      formData.set(
        "country",
        values.singleitemlot_pickup_location === "different_address"
          ? values.singleitemlot_country
          : values.present_country
      ); //Sountry name as location
      formData.set(
        "sell_location",
        values.singleitemlot_pickup_location === "different_address"
          ? values.singleitemlot_eircode
          : values.present_eircode
      ); //eircode as sell_location
      formData.set(
        "contenthead6",
        values.singleitemlot_pickup_location === "different_address"
          ? values.singleitemlot_bldgname
          : values.present_bldgname
      ); //bldg name as contenthead6
      formData.set(
        "custom_field_1",
        values.singleitemlot_pickup_location === "different_address"
          ? values.singleitemlot_stname
          : values.present_stname
      ); //street name as custom_field_1
      // formData.set("weight", "8000KG"); //street name as custom_field_1
      // formData.set("custom_field_2", "ANother 8000KG");
      formData.set("manufacturer", values.singleitemlot_calfReg); //calfreg as manufacturer
      formData.set("finish", values.singleitemlot_export); //export as finish
      formData.set("custom_field_8", values.singleitemlot_tbStatus); //street name as custom_field_1
      formData.set("startdate", values.start_date);
      formData.set("enddate", values.end_date);

      /*   formData.set('product_images',values.product_image) */

      const res = await request(
        "post",
        "/sellerpostprojectlive",
        formData,
        false,
        true
      );
      console.log(res);
      console.log(res.data);
      if (res.data.status === "true") {
        await props.history.push("/my-auctions");
        return Toast.fire({
          type: "success",
          title: `Your list has been successfully posted`,
        });
      }
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  /* console.log(values.singleitemlot_pickup_location) */

  //Single Item Lot - SIL
  return (
    <Layout>
      {console.log("++++++", errors)}
      <div className="main-container">
        <div className="pp-back">
          <img src="/images/listingSingle.jpg" alt="" />
        </div>
        <div className="pl-white-box">
          <h2 className="pl-head">POST A LISTING</h2>
          <div className="pl-form">
            <h4 className="form-ghead">Lot Details</h4>
            <div className="row">
              <div className="col-md-6 form-group">
                <CustomInput
                  id="lot-title"
                  key="lot-title"
                  label="Lot title"
                  placeholder="Enter your lot title"
                  tooltiptitle="Keep this short and informative. This the what Buyers will see first."
                  name="singleitemlot_title"
                  value={values.singleitemlot_title}
                  onChange={handleChange}
                  error={state ? !values.singleitemlot_title && true : false}
                  helperText={state && errors.singleitemlot_title}
                  /*   disableTooltip={false} */
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <CustomInput
                  id="description"
                  key="description"
                  label="Description"
                  placeholder="Enter a brief description"
                  multiline
                  rows={4}
                  name="singleitemlot_description"
                  value={values.singleitemlot_description}
                  onChange={handleChange}
                  tooltiptitle="Here you can get into more detail. Describe your livestock. Don't hold back, a good description can add many &#8364; to your sale price"
                  error={
                    state ? !values.singleitemlot_description && true : false
                  }
                  helperText={state && errors.singleitemlot_description}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="section"
                  key="section"
                  label="Section"
                  name="singleitemlot_section"
                  tooltiptitle="Section that item belongs to"
                  value={values.singleitemlot_section}
                  pc_onchange={handleChange}
                  selectMenu={Section}
                  selectMenuHeader="Select Section"
                  error={state ? !values.singleitemlot_section && true : false}
                  /*  helperText = {state && errors.singleitemlot_section} */
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.singleitemlot_section}
                  </FormHelperText>
                )}
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="type"
                  key="type"
                  label="Type"
                  name="singleitemlot_type"
                  value={values.singleitemlot_type}
                  pc_onchange={handleChange}
                  tooltiptitle="Type of an Item"
                  selectMenu={Type}
                  selectMenuHeader="Select Type"
                  error={state ? !values.singleitemlot_type && true : false}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.singleitemlot_type}
                  </FormHelperText>
                )}
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="breed"
                  key="breed"
                  label="Breed"
                  name="singleitemlot_breed"
                  value={values.singleitemlot_breed}
                  pc_onchange={handleChange}
                  tooltiptitle="Breed of the animal"
                  selectMenu={Breed}
                  selectMenuHeader="Select Breed"
                  error={state ? !values.singleitemlot_breed && true : false}
                  helperText={state && errors.singleitemlot_breed}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.singleitemlot_breed}
                  </FormHelperText>
                )}
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="sex"
                  key="sex"
                  select
                  label="Sex"
                  name="singleitemlot_sex"
                  value={values.singleitemlot_sex}
                  pc_onchange={handleChange}
                  tooltiptitle="Gender type of the animal"
                  selectMenu={Sex}
                  selectMenuHeader="Select Sex"
                  error={state ? !values.singleitemlot_sex && true : false}
                  /*    helperText = {state && errors.singleitemlot_sex} */
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.singleitemlot_sex}
                  </FormHelperText>
                )}
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomInput
                  id="weight"
                  key="weight"
                  label="Weight"
                  con={true}
                  name="singleitemlot_weight"
                  value={values.singleitemlot_weight}
                  onChange={handleChange}
                  placeholder="Enter weight in kgs"
                  tooltiptitle="Weight of the animal"
                  error={state ? !values.singleitemlot_weight && true : false}
                  helperText={state && errors.singleitemlot_weight}
                />
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="weigh-method"
                  key="weigh-method"
                  label="Weighing method"
                  name="singleitemlot_weighing_method"
                  selectMenuHeader={"Select Weighing method"}
                  value={values.singleitemlot_weighing_method}
                  pc_onchange={handleChange}
                  tooltiptitle="Method to caluclate the weigh of the animal"
                >
                  {weimethod.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.singleitemlot_weighing_method}
                  </FormHelperText>
                )}
              </div>

              <div className="col-sm-6 col-md-3 form-group">
                {/*            <CustomInput
                id="dob"
                key="dob"
                label="DOB"
                type="date"
                placeholder="Select date of birth"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="material-icons">calendar_today</span>
                    </InputAdornment>
                  ),
                }}
                tooltiptitle="Animal's date of birth"
              /> */}
                <DatePicker
                  id="dob"
                  key="dob"
                  label="DOB"
                  name="singleitemlot_dob"
                  disableFuture={true}
                  value={values.singleitemlot_dob}
                  placeholder="Select date of birth"
                  onchange={handleDateChange}
                  tooltiptitle="Animal's date of birth"
                  error={state ? !values.singleitemlot_dob && true : false}
                  helperText={state && errors.singleitemlot_dob}
                />
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomInput
                  id="tag-number"
                  key="tag-number"
                  label="ID Tag"
                  con={true}
                  placeholder="Enter tag number"
                  name="singleitemlot_tagnumber"
                  value={values.singleitemlot_tagnumber}
                  onChange={handleChange}
                  tooltiptitle="Tag number assigned to the animal"
                  error={
                    state ? !values.singleitemlot_tagnumber && true : false
                  }
                  helperText={state && errors.singleitemlot_tagnumber}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3 form-group">
                {/*              <CustomInput
                id="issued-date"
                key="issued-date"
                label="Date card issued"
                placeholder="Select date of issue"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="material-icons">calendar_today</span>
                    </InputAdornment>
                  ),
                }}
                tooltiptitle="Date of animal issued"
              /> */}
                <DatePicker
                  id="issued-date"
                  key="issued-date"
                  label="TB test date"
                  placeholder="Select TB test date"
                  disableFuture={true}
                  name="singleitemlot_cardissued"
                  value={values.singleitemlot_cardissued}
                  onchange={handleDateChange}
                  tooltiptitle="Date card was issued"
                  error={
                    state ? !values.singleitemlot_cardissued && true : false
                  }
                  helperText={state && errors.singleitemlot_cardissued}
                />
              </div>

              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="tbStatus"
                  key="tbStatus"
                  label="TB Status"
                  name="singleitemlot_tbStatus"
                  tooltiptitle="TB Status"
                  pc_onchange={handleChange}
                  selectMenu={["Positive", "Clear"]}
                  value={values.singleitemlot_tbStatus}
                  selectMenuHeader={"Select TB Status"}
                ></CustomSelect>
                <FormHelperText error>
                  {state ? errors.singleitemlot_tbStatus : ""}
                </FormHelperText>
              </div>

              <div className="col-sm-6 col-md-3 form-group">
                {/*      <CustomInput
                id="test-date"
                key="test-date"
                label="Last test date"
                placeholder="Select last test date"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="material-icons">calendar_today</span>
                    </InputAdornment>
                  ),
                }}
                tooltiptitle="Date of animal tested"
              /> */}

                <DatePicker
                  id="test-date"
                  key="test-date"
                  label="Date into herd"
                  placeholder="Select date into herd"
                  disableFuture={true}
                  name="singleitemlot_testdate"
                  value={values.singleitemlot_testdate}
                  onchange={handleDateChange}
                  tooltiptitle="Date animal was tested"
                  error={state ? !values.singleitemlot_testdate && true : false}
                  helperText={state && errors.singleitemlot_testdate}
                />
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                {/*     <CustomSelect
                id="previous-owners"
                key="previous-owners"
                label="No. of previous owners"
                name="singleitemlot_prevowner"
                value= {values.singleitemlot_prevowner}
                pc_onchange= {handleChange}
                tooltiptitle="No of previous owners"
              >
                <option key="" value="">
                  No of previous owners
                </option>
              </CustomSelect> */}
                <CustomInput
                  id="previous-owners"
                  key="previous-owners"
                  label="No. of previous owners"
                  name="singleitemlot_prevowner"
                  con={true}
                  placeholder="No of previous owners"
                  value={values.singleitemlot_prevowner}
                  onChange={handleChange}
                  tooltiptitle="No of previous owners"
                  error={
                    state ? !values.singleitemlot_prevowner && true : false
                  }
                  helperText={state && errors.singleitemlot_prevowner}
                />
              </div>

              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="export"
                  key="export"
                  label="Export"
                  name="singleitemlot_export"
                  tooltiptitle="Export"
                  pc_onchange={handleChange}
                  value={values.singleitemlot_export}
                  selectMenuHeader={"Select export"}
                  selectMenu={["Yes", "No"]}
                ></CustomSelect>
                <FormHelperText error>
                  {state ? errors.singleitemlot_export : ""}
                </FormHelperText>
              </div>

              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  id="calfReg"
                  key="calfReg"
                  label="Calf Reg"
                  name="singleitemlot_calfReg"
                  value={values.singleitemlot_calfReg}
                  pc_onchange={handleChange}
                  selectMenuHeader={"Select CalfReg"}
                  tooltiptitle="Select if calf is registered"
                  selectMenu={["Yes", "No"]}
                ></CustomSelect>
                <FormHelperText error>
                  {state ? errors.singleitemlot_calfReg : ""}
                </FormHelperText>
              </div>

              {/* <div className="col-sm-6 col-md-3 form-group">
                <CustomInput
                  id="premiums"
                  key="premiums"
                  label="Premiums collected"
                  placeholder="Premiums collected"
                  name="singleitemlot_premiums"
                  value={values.singleitemlot_premiums}
                  onChange={handleChange}
                  tooltiptitle="Premiums collected / Due details"
                  error={state ? !values.singleitemlot_premiums && true : false}
                  helperText={state && errors.singleitemlot_premiums}
                />
              </div>*/}
            </div>

            <div className="row">
              <div className="col-md-12 form-group notesCls">
                <CustomInput
                  id="notes"
                  key="notes"
                  label="Notes"
                  placeholder="Enter notes"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  tooltiptitle="Any specific details that the buyers may need to make their choice can be put in here. Give them all the info they need, but don't overload them. Keep it simple and straight forward."
                  name="singleitemlot_notes"
                  value={values.singleitemlot_notes}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Display seller name and address?
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="singleitemlot_truck_turn"
                    value={values.singleitemlot_truck_turn}
                    onChange={handleChange}
                    /* helperText = {state && errors.singleitemlot_truck_turn} */
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                  {state && (
                    <FormHelperText error>
                      {errors.singleitemlot_truck_turn}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Choose preferential times
                  </FormLabel>
                  <RadioGroup
                    aria-label="preferential-times"
                    name="singleitemlot_qualityAssuranceFarm"
                    className="fd-row"
                    value={values.singleitemlot_qualityAssuranceFarm}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Quality assurance farm
                  </FormLabel>
                  <RadioGroup
                    aria-label="qualityAssuranceFarm"
                    name="singleitemlot_pref_times"
                    className="fd-row"
                    value={values.singleitemlot_pref_times}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <Upload
                  src="/images/insert-photo.png"
                  header="UPLOAD IMAGE"
                  subheader="You can upload multiple images"
                  id="upload-image"
                  title="You need to put up at least one good quality image of each animal. More is better, but only animals for sale in this Lot can be in the images. You may only include animals that are in this specific Lot.  No animals in Images that are not in this specific Lot. You must include all animals that are in this specific Lot."
                  accept="image/*"
                  onchange={onClickImages}
                  multiple={true}
                  /* error={state}
                helperText = {state && errors.product_image} */
                />
                {state && (
                  <FormHelperText error>{errors.product_image}</FormHelperText>
                )}
                <div className="row">
                  {values.product_image &&
                    values.product_image.map((value, key) => (
                      <div className="col-md-3 productPostThumbnails" key={key}>
                        <label
                          onClick={() => removeImage(value)}
                          name={key}
                          className="pp-edit material-icons"
                        >
                          close
                        </label>
                        <img
                          style={{
                            minHeight: "100%",
                            minWidth: "100%",
                            objectFit: "cover",
                          }}
                          src={URL.createObjectURL(value)}
                          id={key}
                          className="pro-img rounded img-fluid"
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-12 col-md-6 form-group">
                <Upload
                  src="/images/movie.png"
                  header="UPLOAD VIDEO"
                  subheader="You can upload a single video file"
                  accept="video/*"
                  id="upload-image"
                  onchange={onClickVideo}
                  multiple={false}
                  title=" Upload a video of the entire lot. This is a great opportunity to show the Buyers how good they are.  You may only include animals that are in this specific Lot.  No animals in Video that are not in this specific Lot. You must include all animals that are in this specific Lot"
                />

                <div className="row">
                  {values.product_videos && (
                    <Player
                      playsInline
                      poster="/assets/poster.png"
                      src={URL.createObjectURL(values.product_videos)}
                    />
                  )}
                </div>
              </div>
            </div>

            <h4 className="form-ghead mt-4">Price Details</h4>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Post this listing under
                  </FormLabel>
                  <div className="listing-under d-flex">
                    <CheckBox
                      label="Sale"
                      name="singleitemlot_auction"
                      /* value={values.singleitemlot_auction} */
                      value={values.singleitemlot_auction}
                      click={() => !values.singleitemlot_auction}
                      onchange={handleChange}
                    />
                    <CheckBox
                      label="Buynow"
                      name="singleitemlot_buynow"
                      value={values.singleitemlot_buynow}
                      onchange={handleChange}
                      click={() => !values.singleitemlot_buynow}
                    />
                  </div>
                </FormControl>
              </div>
              {/* <div className="row"> */}

              {/* </div> */}

              <div className="col-12 col-md-3 form-group">
                <CustomInput
                  disabled={values.singleitemlot_auction ? false : true}
                  id="start-price"
                  key="start-price"
                  label="Start price"
                  placeholder="Enter a start price"
                  name="singleitemlot_startprice"
                  value={values.singleitemlot_startprice}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                  }}
                  tooltiptitle="This is the minimum Price for first Bid"
                />
              </div>
              <div className="col-12 col-md-3 form-group">
                <CustomInput
                  disabled={values.singleitemlot_buynow ? false : true}
                  id="buy-now-price"
                  key="buy-now-price"
                  label="Buy now price"
                  name="singleitemlot_buynowprice"
                  value={values.singleitemlot_buynowprice}
                  onChange={handleChange}
                  placeholder="Enter buy now price"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                  }}
                  tooltiptitle="With this option Buyers will have the opportunity to Buy your Lot at a predetermined price, set by you,
at any time until the Lot closes. If the Sale Price surpasses the Buy Now price the Sale price has
priority"
                />
              </div>
              <div className="col-12 col-md-3 form-group">
                <DatePicker
                  id="Auction start-date"
                  key="Auction start-date"
                  label="Sale start-date"
                  format={"MM/dd/yyyy hh:mm:ss"}
                  placeholder="Sale start-date"
                  disableFuture={false}
                  name="start_date"
                  value={values.start_date}
                  onchange={handleDateChange}
                  tooltiptitle="Sale start-date"
                  error={state ? !values.start_date && true : false}
                  helperText={state && errors.start_date}
                />
              </div>

              <div className="col-12 col-md-3 form-group">
                <DatePicker
                  id="Auction end-date"
                  key="Auction end-date"
                  label="Sale end-date"
                  format={"MM/dd/yyyy hh:mm:ss"}
                  placeholder="Sale end-date"
                  disableFuture={false}
                  name="end_date"
                  value={values.end_date}
                  onchange={handleDateChange}
                  tooltiptitle="Sale end-date"
                  error={state ? !values.end_date && true : false}
                  helperText={state && errors.end_date}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <Tooltip
                    title="With the choice you can determine whether or not the Buyers can see the reserve price. If you choose “Public” the Reserve Price will be displayed in the Sale room. If you chose “Private” the Buyers will not see the reserve price and will not know the reserve price until it has been passed and the Lot is then 'On the Market'"
                    arrow
                    placement="top"
                  >
                    <FormLabel component="legend" className="pp-rc-label">
                      Select reserve price type
                    </FormLabel>
                  </Tooltip>
                  <RadioGroup
                    aria-label="reserve-price"
                    name="singleitemlot_respricetype_checked"
                    className="reserve-price"
                    className="fd-row"
                    value={values.singleitemlot_respricetype_checked}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="public"
                      control={<GreenRadio />}
                      label="Public"
                      value="checked"
                    />
                    <FormControlLabel
                      value="private"
                      control={<GreenRadio />}
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-12 col-md-3 form-group">
                <CustomInput
                  id="lot-title"
                  key="lot-title"
                  label="Reserve price"
                  name="singleitemlot_resprice"
                  value={values.singleitemlot_resprice}
                  onChange={handleChange}
                  error={state && !errors.singleitemlot_resprice ? true : false}
                  helperText={state && errors.singleitemlot_resprice}
                  placeholder="Enter reserve price"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                  }}
                  tooltiptitle=" This the minimum price which you will accept for sale. Once the Reserve price has been passed the Lot cannot be withdrawn, it is on the Market and the highest Bidder will have the legal obligation to pay for them"
                />

                <FormHelperText error>
                  {state && errors.singleitemlot_resprice}
                </FormHelperText>
              </div>
            </div>

            <h4 className="form-ghead mt-4">Pick-up Location Details</h4>

            <div className="row">
              <div className="col-12 col-md-8 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Collection location of livestock
                  </FormLabel>
                  <RadioGroup
                    aria-label="pickup-location"
                    name="singleitemlot_pickup_location"
                    className="reserve-price"
                    value={values.singleitemlot_pickup_location}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="different_address"
                      control={<GreenRadio />}
                      label="Different address"
                    />
                    <FormControlLabel
                      value="present_address"
                      control={<GreenRadio />}
                      label="Same as my present address"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {values.singleitemlot_pickup_location === "different_address" && (
              <div className="row">
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="building-name"
                    key="building-name"
                    label="Building name"
                    con={false}
                    name="singleitemlot_bldgname"
                    value={values.singleitemlot_bldgname}
                    onChange={handleChange}
                    placeholder="Enter your building name"
                    tooltiptitle="Enter your building name"
                    error={
                      state
                        ? values.singleitemlot_pickup_location ===
                            "different_address" &&
                          !values.singleitemlot_bldgname &&
                          true
                        : false
                    }
                    helperText={state && errors.singleitemlot_bldgname}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="street-name"
                    key="street-name"
                    label="Street name"
                    name="singleitemlot_stname"
                    value={values.singleitemlot_stname}
                    onChange={handleChange}
                    placeholder="Enter your street name"
                    tooltiptitle="Enter your street name"
                    error={
                      state
                        ? values.singleitemlot_pickup_location ===
                            "different_address" &&
                          !values.singleitemlot_stname &&
                          true
                        : false
                    }
                    helperText={state && errors.singleitemlot_stname}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="town-name"
                    key="town-name"
                    label="Town name"
                    con={false}
                    name="singleitemlot_town"
                    value={values.singleitemlot_town}
                    onChange={handleChange}
                    placeholder="Enter town"
                    tooltiptitle="Enter your town"
                    error={
                      state
                        ? values.singleitemlot_pickup_location ===
                            "different_address" &&
                          !values.singleitemlot_town &&
                          true
                        : false
                    }
                    helperText={state && errors.singleitemlot_town}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <CustomSelect
                    id="County"
                    key="County"
                    label="Select your county"
                    tooltiptitle="County"
                    name="singleitemlot_county"
                    value={values.singleitemlot_county}
                    pc_onchange={handleChange}
                    error={
                      state
                        ? values.singleitemlot_pickup_location ===
                            "different_address" &&
                          !values.singleitemlot_county &&
                          true
                        : false
                    }
                  >
                    <option key="" value="">
                      Select your county
                    </option>
                    {IrishCounties.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.singleitemlot_county}
                    </FormHelperText>
                  )}
                </div>
                <div className="col-sm-6 form-group">
                  <CustomSelect
                    id="Country"
                    key="Country"
                    label="Select your country"
                    tooltiptitle="Country"
                    name="singleitemlot_country"
                    value={values.singleitemlot_country}
                    pc_onchange={handleChange}
                    error={
                      state
                        ? values.singleitemlot_pickup_location ===
                            "different_address" &&
                          !values.singleitemlot_country &&
                          true
                        : false
                    }
                  >
                    <option key="" value="">
                      Select your country
                    </option>
                    <option key="Ireland" value="Ireland">
                      Ireland
                    </option>
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.singleitemlot_country}
                    </FormHelperText>
                  )}
                </div>

                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="eir-code"
                    key="eir-code"
                    con={"sn"}
                    label="EIR code"
                    name="singleitemlot_eircode"
                    value={values.singleitemlot_eircode}
                    onChange={handleChange}
                    placeholder="Enter EIR code"
                    tooltiptitle="EIR code"
                    error={
                      state
                        ? values.singleitemlot_pickup_location ===
                            "different_address" &&
                          !values.singleitemlot_eircode &&
                          true
                        : false
                    }
                    helperText={state && errors.singleitemlot_eircode}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 col-md-4 form-group">
                <FormControl fullWidth component="fieldset">
                  <Tooltip
                    title="If you choose to link your Lots Buyers can see this and buy multiple Lots from you."
                    arrow
                    placement="top"
                  >
                    <FormLabel component="legend" className="pp-rc-label">
                      Do you want to link your lots
                    </FormLabel>
                  </Tooltip>
                  <RadioGroup
                    aria-label="link-lots"
                    className="reserve-price"
                    name="singleitemlot_linklots"
                    value={values.singleitemlot_linklots}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          <div className="pl-btn pt-4">
            <PrimaryButton
              label="SUBMIT"
              onsubmit={handleSubmit}
            ></PrimaryButton>
          </div>
        </div>
        <FFModal
          open={modalopen1}
          handleClose={toggleModal1}
          modaltitle="Seller's Declaration"
        >
          <div className="seller-note" style={{ fontWeight: "500" }}>
            <p>
              The Farm Fayre platform is based upon the honour system between
              all partier, of which I agree to be bound.
            </p>
            <p>
              I, “Member Name”, hereby declare, specifically to this “Lot” -
              “Item Number” -, the following
            </p>
            <ol className="sdec-note">
              <li>
                All information/documentation as presented herein is true and
                correct to the best of my knowledge.
              </li>
              <li>
                All livestock listed herein are healthy and free of disease
                and/or injury; &,
              </li>
              <li>
                All livestock listed herein are clearly presented in the
                attached photos & videos; &,
              </li>
              <li>
                ONLY the listed livestock are presented in the attached photos &
                videos;
              </li>
            </ol>
            <p>
              As Seller, I agree that all the above statements are true and
              correct. Therefore, should any of the above be judged to be untrue
              or incorrect, at the discretion of the “Arbitrator”, I will
              organise and accept at my own expense for the immediate return of
              all livestock within this lot to my premises and agree to the
              Buyers funds being returned to them with immediate effect and
              without conflict or complaint.
            </p>
            <div className="jc-center">
              <CheckBox label="I Declare the above to be True to my knowledge" />
            </div>
            <div className="pl-btns">
              <PrimaryButton
                label={"POST MY LISTING"}
                btnSize={"smallsize"}
                onsubmit={postSingleItem}
              />
            </div>
          </div>
        </FFModal>
      </div>
    </Layout>
  );
};

export default PostProjectStep1;
