import React, { useState, useEffect } from "react";
import "./FavouriteCheckbox.css";

const SellerFavourite = (props) => { 
    
    const [favorite, setFavorite] = useState(false);

    const handleChange = () => {
      //setFavorite(!favorite);
      props.setDisabled(true)
    }

    // useEffect(()=>{
    //            if(parseInt(props.sellerfav) != 0 && props.sellerfav != undefined){
    //                setFavorite(true)
    //            }
    // },[props])
    
    return (
      <div class="favoriteCheck d-flex justify-content-center align-items-center">
        <input id={props.favIdChild} type="checkbox"  disabled={props.disabled} onClick={(e)=>{handleChange(e);props.onCall(e)}} checked={props.sellerfav == 0?false:true} />
        <label for={props.favIdChild}>
          <span class="material-icons">
          {props.sellerfav == 0 ?  "favorite_border":"favorite" }</span>
        </label>
      </div>
    ); 
}


export default SellerFavourite;
