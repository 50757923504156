import React from 'react'
import AuctionList from '../../components/AuctionCard/AuctionList'

const ListView = (props) => {
       const {productdet}=props;
    return (
        <div className="searchList d-flex justify-content-start align-items-center">
            {productdet.results && productdet.results.map((data)=>
            <AuctionList
              title={data.title}
              startprice={data.sprice}
              image={data.avataeorg}
              start_date={data.date_start}
              close_date={data.date_closed}
              auction={data.auction}
              buynow={data.buynow}
              buynow_price={data.bprice}
              description={data.description}
            />
            )}
        </div>
    )
}
export default ListView