import React from 'react';
import Box from '@material-ui/core/Box';
import '../../views/SellerProfile/sellerprofile.css'
import Rating from '@material-ui/lab/Rating';
import { AvatarURL } from '../../Utils/constant';
import { Avatar } from '@material-ui/core';

const Reviews = (props) => {

    return(
        <div className="card">
        <div className="card-body">
            <div className="row">
                <div className="col-sm-6">
                    <h4 className="card-title">
                        <Avatar
                            src={props.review.avatar?AvatarURL+props.review.avatar:"/images/default-user.svg"}
                            alt=""
                            className="user_img"
                        />
                            {props.review.first_name}
                    </h4>
                </div>
                    <div className="col-sm-6 text-right">
                        <div className="light-red">
                        <Box component="fieldset" mb={props.review.rating} borderColor="transparent">
                            <Rating
                                name="simple-controlled"
                                value={props.review.rating}
                                readOnly={true}
                            />
                        </Box> 
                        </div>
                    </div>
            </div>
                    
            <p className="card-text">{props.review.subject}</p>
        </div>
        </div>
    );
}

export default Reviews

