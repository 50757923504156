import React, { useState, useRef, useEffect } from "react";
import { request } from "../../Utils/Request";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DBLayout from "../../components/DBLayout";
import { TextField, InputAdornment } from "@material-ui/core";
import InputField from "../../components/Inputs/InputField";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { callNumber, callNumber1 } from "../../Utils/constant";
import "./referral.css";
import moment from "moment";
import { Popup } from "../../Utils/Notifications";

const Referrals = (props) => {
  const textAreaRef = useRef(null);
  console.log(
    "process.env.REACT_APP_FRONT_URL",
    process.env.REACT_APP_FRONT_URL
  );
  const referlink =
    process.env.REACT_APP_FRONT_URL +
    "/Reg_tier_1?refer_id=" +
    localStorage.userId;
  const [open, setOpen] = React.useState(false);
  const [referData, setReferdata] = useState({
    referDetail: [],
    amount: 0,
  });
  const [referEmail, setReferEmail] = useState({
    email: "",
  });
  const handlechange = async (e) => {
    setReferEmail({ ...referEmail, email: e.target.value });
  };

  const getMyrefer = async () => {
    const res = await request(
      "post",
      "/getUserWalletDetails",
      { user_id: localStorage.userId },
      false,
      true
    );
    console.log(res.data);
    if (res.data.success === "yes") {
      setReferdata({
        ...referData,
        referDetail: res.data.referral,
        amount: Number(res.data.data.amount),
      });
    }
  };

  const sendRefer = async () => {
    if (referEmail.email != null && referEmail.email != "") {
      console.log("   link:referlink,", referlink);
      console.log("    to_email:referEmail.email,", referEmail.email);
      const res = await request(
        "post",
        "/referral_email",
        {
          link: referlink,
          to_email: referEmail.email,
        },
        false,
        true
      );

      if (res.data.success === "yes") {
        Popup.fire({
          type: "success",
          title: "Refer Email Sent successfully",
        });
      }
      setReferEmail({ ...referEmail, email: "" });
    }
  };

  useEffect(() => {

    // if (localStorage.userRole == "tier1") {
    //   props.history.push("/Reg_tier_2");
    // }	

    getMyrefer();
  }, []);

  const copyClipBoard = () => {
    setOpen(true);

    var copyText = document.getElementById("referral");
    // document.body.appendChild(copyText)

    // copyText.value='from value'
    // copyText.textContent='from textContent'
    // var sel=getSelection()
    // var range=document.createRange();
    // range.selectNode(copyText)
    // sel.removeAllRanges();
    // sel.addRange(range)
    // copyText.focus()
    // copyText.querySelector()
    document.getElementById("referral").select();
    document.execCommand("copy");
    // document.body.removeChild(copyText)
    setTimeout(() => {
      setOpen(false);
    }, 1500);
  };

  return (
    <DBLayout 
    // title="Refer & Earn – Share the goodwill :-)"
    >
      <div className="media share-link">
        <div className="media-body">
          <h3 className="refer-head">Share the Revolution & Reap the Rewards!</h3>

          <p className="refer-descp">
          At Farm Fayre, we believe in the power of community. As we revolutionize the way farmers trade, we want you right beside us, sharing the benefits.
          </p>
          <p className="refer-descp how_it_works" style={{fontWeight:"bold"}}>
           How It Works
          </p>
          <ul>
            <li className="howList"><span style={{fontWeight:"bold"}}>Invite Your Fellow Farmers</span>
            <ul><li>
            Share your unique referral link with your peers - whether they're farmers, livestock traders, or anyone in the livestock trade!</li></ul>  
            </li>
            <li className="howList"><span style={{fontWeight:"bold"}}>They Join Farm Fayre</span>
            <ul><li>
            Once your friend joins, and makes their first livestock purchase, <span style={{fontWeight:"bold"}}>you earn €50 Cash!</span></li></ul>  
            </li>
            <li className="howList"><span style={{fontWeight:"bold"}}>They List & Sell</span>
            <ul><li>
            When that same friend lists and sells livestock, <span style={{fontWeight:"bold"}}>another €50 Cash is yours!</span></li></ul>  
            </li>
          </ul>
          <p className="referrals" style={{fontWeight:"bold"}}>Every Complete referral brings you up to €100 Cash!</p>
          {/* <p className="refer-descp">
            This offers the potential of € 12.50 per introduction. Not bad for
            sharing a link, right?
          </p> */}
        </div>
        <div className="media-right">
          <img className="refer_earn" src="/images/referEarn.png" alt="" />
        </div>
      </div>
      <div className="wallet-balance">
        <div className="wallet-head">
          <span className="material-icons">account_balance_wallet</span>Your Wallet
          Balance: <b>€ {referData.amount !== undefined ? referData.amount.toFixed(2) : 0} </b>{" "}
          {/* <span className="material-icons green-txt">info</span> */}
        </div>

        <p className="ref-txt">
        <span style={{fontWeight:"bold"}}>Spread the word, and enjoy your rewards!</span> The future of farming is here, and together, we're shaping it.
        </p>
        <p className="ref-txt">
        Copy your personal the link:-
        </p>
        <CopyToClipboard text={referlink}>
          <div className="refer-link" onClick={copyClipBoard}>
            <input
              id="referral"
              className="referral"
              value={referlink}
              type="text"
              ref={textAreaRef}
              disabled
            />
            <span className="material-icons">content_copy</span>
          </div>
        </CopyToClipboard>
        <ul>
          <li>Share it with your contacts via any messaging service; or,</li>
          <li>
             Advise them to place your name in the “Where did you hear about us?” section at registration; or, 
          </li>
          <li>
             Put their email address(es) in the box below and we’ll send them an introduction email.
          </li>
        </ul>

        {/* <p className="ref-txt">
          Simply input your contact(s) email address in the below section:
        </p> */}
        <div className="emailInvite">
          <InputField
            id="email-invite"
            label="Enter email here"
            placeholder="Enter email here"
            // variant="outlined"
            // size="small"
            // fullWidth
            value={referEmail.email}
            onchange={handlechange}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end" className="email-invite-btn">
            //       <span className="material-icons">send</span>
            //     </InputAdornment>
            //   ),
            // }}
          />
          <Button onClick={sendRefer}>
            <span className="material-icons">send</span>
          </Button>

        </div>
        <Snackbar open={open} autoHideDuration={3000}>
          <Alert severity="success">Text copied to the clipboard</Alert>
        </Snackbar>
        <h4 className="ref-his-head" style={{fontWeight:"bold"}}>My Referral History</h4>
       
      </div>

    
      <div className="ff-resp-table table-responsive">
        <table className="table w-100">
          <thead className="mobile-hide">
            <tr>
              <th>Friend Referred</th>
              <th>Referred By</th>
              <th>Date</th>
              <th>Action</th>
              <th>Credit</th>
            </tr>
          </thead>
          <tbody>
            {!referData.referDetail.length ? (
              <tr>
                <td colspan="5" className="pd-zero text-center">
                  <div className="card card-body">No referral yet</div>
                </td>
              </tr>
            ) : (
              referData.referDetail.map((item) => (
                <tr>
                  <td className="resp-td" data-label="Referred to">
                    <div className="rtc-wrap">{item.refer_to_id == 0 ? "Credit" : item.to_name}</div>
                  </td>
                  <td className="resp-td" data-label="Referred on">
                    <div className="rtc-wrap">{item.refer_to_id == 0 ? "-" : item.from_id}</div>
                  </td>
                  <td className="resp-td" data-label="Date">
                    <div className="rtc-wrap">{moment(item.date_added).format(
                "YYYY-MM-DD"
              )}</div>
                  </td>
                  <td className="resp-td" data-label="Action">
                    <div className="rtc-wrap">{item.source}</div>
                  </td>
                  <td className="resp-td" data-label="Credit">
                    <div className="rtc-wrap green-txt">€ {callNumber(item.points)}</div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <p className="ref-txt">
        * Farm Fayre’s refer and earn program is subject to terms and conditions. Farm Fayre may update or end the refer and earn program at any time. </p>
     
     
    </DBLayout>
  );
};

export default Referrals;
