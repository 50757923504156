import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      height: "60px",
    },
  },
}));

const ColorButton = withStyles((theme, inverse) => ({
  root: {
    color:"var(--primColor)",
    borderRadius: "3px",
    boxShadow: "0px 4px 12px rgba(72, 191, 132, 20%)",
    backgroundColor:"#fff",
    border:"1px solid var(--primColor)",
    "&:hover": {
      backgroundColor:"var(--primColor)",
      boxShadow: "0px 4px 12px rgba(72, 191, 132, 20%)",
      color: "#fff"
    },
  },
}))(Button);

const TransButton = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}> 
      <ColorButton
        variant="contained"
        color="primary"
        className={classes.margin}
        onClick= {props.onsubmit} 
      >
        {props.children}
        {props.label} 
      </ColorButton>
    </div>
  );
};

export default TransButton
