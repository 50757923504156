import React, { useEffect, useState } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GreenRadio } from "../../components/Inputs/RadioBox";
import {request } from "../../Utils/Request"
import JwtDecode from "jwt-decode";
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
	herdNumberCard: {
		margin: '0 5px 0 0'
	}
});

const SelectHerd = ({ imgurl, itemid, title, amount, codPrice, props }) => { 
	  const [num,setNum]=useState([]);
	  const [dis,setDis]=useState(0);
	  const classes = useStyle();
	const getherdnum=async(e)=>{

		   const res=await request("post","/getherdnumber",{user_id:JwtDecode(localStorage.getItem("usertoken")).id},false,true)
		
			  setNum(res.data.data);
		
		   
	}

	useEffect(()=>{
        getherdnum()
	},[])

	const handlechange=(key)=>(e)=>{
		setDis(key)
	}

	
    return (
	    <div className="card checkout-card"> 
	       <div className="card-body"> 
              <h4 className="cc-title">Select Herd Number</h4>

              <div className="shred-radio-wrapper">
				{num && num.map((val,key)=>

              	<div className="shred-custom-card">
              		<input name={`herd-num`} type="radio" onChange={handlechange(key)} checked={key==dis?true:""} />
				      <label className={`sc-label`}>#{val}</label>
              	</div>

				)}
              	{/* <div className="shred-custom-card">
              		<input name="herd-num" type="radio" />
              		<label className="sc-label">#12254513</label>
              	</div>
              	<div className="shred-custom-card">
              		<input name="herd-num" type="radio" />
              		<label className="sc-label">#12254514</label>
              	</div>
              	<div className="shred-custom-card">
              		<input name="herd-num" type="radio" />
              		<label className="sc-label">#12254515</label>
              	</div>
              	<div className="shred-custom-card">
              		<input name="herd-num" type="radio" />
              		<label className="sc-label">#12254516</label>
              	</div> */}
              </div> 
	       </div>
	    </div>
    );
}

export default SelectHerd