import React from "react"; 
import Tooltip from "@material-ui/core/Tooltip";
import "./upload.css";
import { id } from "date-fns/locale";
 
const Upload  = (props) => {
 

  return ( 
    <Tooltip title={props.title} arrow placement="top">
      <div className="upload-file-wrapper">
        <input type="file" 
        id={props.id} 
        accept={props.accept}
        name={props.name}
        onChange = {e=>props.onchange(e,props.id)}
        multiple={props.multiple}
        onClick={(e)=>e.target.value=null}
        />
        <div className="">
          <img src={props.src} alt="" />
        </div>
        <div className="ul-img-head">{props.header}</div>
        <div className="ul-img-head">{props.subheader}</div>
      </div> 
    </Tooltip>
  );
}

export default Upload;
