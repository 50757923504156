import React, {useEffect} from "react";  
import { isAuthenticated } from "../../Utils/Authenticated";
import DBLayout from '../../components/DBLayout'
import ProfileForm from './ProfileForm'
import "./profile.css"

const Profile  = (props) => { 

  useEffect(()=>{
    if(!isAuthenticated()){
      props.history.push("/")
    }	
  },[])
  return ( 
    <DBLayout title="My Profile"> 
       <ProfileForm />
    </DBLayout>
  );
}

export default Profile