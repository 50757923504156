import React from "react";
import CatlougeCard from "../../components/AuctionCard/CatlougeCard";

const GridView = (props) => {
   const {productdet}=props;
  return (
    <div className="searchGrid d-flex justify-content-start align-items-center">
    {productdet.results && productdet.results.map((data)=>
      <CatlougeCard 
          title={data.title}
          startprice={data.sprice}
          image={data.avataeorg}
          start_date={data.date_start}
          close_date={data.date_closed}
          auction={data.auction}
          buynow={data.buynow}
          buynow_price={data.bprice}
          description={data.description}/>
    )}
    </div>
  );
};

export default GridView;
