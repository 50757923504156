import React from "react";
import PrimaryButton from "../Inputs/PrimaryButton";
import Layout from "../Layout";
import "./ErrorPages.css";

const Error404 = () => {
  return (
    <Layout>
      <div className="errorPages customContainer">
        <lottie-player
          src="https://assets2.lottiefiles.com/packages/lf20_pbpj201c.json"
          background="transparent"
          speed="1"
          class="error404"
          loop
          // controls
          autoplay
        ></lottie-player>
        <h2>Error 404 - Page not found.</h2>
        <p>You seem to have reached an uncharted territory.</p>
        <PrimaryButton label="Take me Home" />
      </div>
    </Layout>
  );
};

export default Error404;
