import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
  root: {
    color: "var(--primColor)",
    "&$checked": {
      color: "var(--primColor)",
    },
    MuiFormControlLabelRoot: {
      marginBottom: 0
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const CheckBox = (props) => {
/*   const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }; */

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <GreenCheckbox
          name={props.name}
            checked={props.value}
            value={props.value}
            onChange={props.onchange ? e=>props.onchange(e):null}
         
          />
        }
        label={props.label}
      />
    </FormGroup>
  );
};

export default CheckBox;
