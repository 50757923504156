import React, { useState, useEffect } from "react";
import { request } from "../../Utils/Request"
import Timer from "../../Utils/Timer"
import { isAuthenticated } from "../../Utils/Authenticated";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {DateFormat, TimeLeft, TimetoDays} from "../../Utils/TimerProfile"
import Pagination from "../../components/Pagination/Pagination"
import { Link } from "react-router-dom"; 
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { callNumber } from "../../Utils/constant";
const ActiveBids = () => { 
    /* useEffect(()=>{
	if(!isAuthenticated()){
		props.history.push("/")
	}	
},[]) */
var a=1;
const [purchaseData, setPurchasedata] = useState({
  productDetail:[],
  totallist:0,
  pageperList:10,
  page:1
})

const handlePageChange = (evt, page)=>{
  setPurchasedata({
    ...purchaseData, page:page
  })
  a=parseInt(a)+10;
}

const getMypurchase = async()=>{
  const res = await request(
    "post",
    "/buyerDashboard",
    { status: "bidactive", page:purchaseData.page , sh_limit:purchaseData.pageperList}, false, true
  )
  console.log(res.data)
  if(res.data.success==="yes"){
    res.data.results.sort((a,b)=>TimetoDays(a["bidTime"])-TimetoDays(b["bidTime"]))
    setPurchasedata({
      ...purchaseData, productDetail :res.data.results, totallist:res.data.total_pagecnt
    })
  }
}

const asending=(e)=>{
  //  console.log("+++++++++",
  purchaseData.productDetail.sort((a,b)=>TimetoDays(a["bidTime"])-TimetoDays(b["bidTime"]))
   setPurchasedata({...purchaseData})
}
const desending=(e)=>{
  purchaseData.productDetail.sort((a,b)=>TimetoDays(a["bidTime"])-TimetoDays(b["bidTime"]))
  purchaseData.productDetail.reverse()
  setPurchasedata({...purchaseData})
}

const desending1=(e)=>{
  
  purchaseData.productDetail.sort((a,b)=>TimeLeft(a["date_closed"])-TimeLeft(b["date_closed"]))
  purchaseData.productDetail.reverse()
  setPurchasedata({...purchaseData})
  
}
const asending1=(e)=>{
  //  console.log("+++++++++",
  purchaseData.productDetail.sort((a,b)=>TimeLeft(a["date_closed"])-TimeLeft(b["date_closed"]))
  setPurchasedata({...purchaseData})
}

useEffect(()=>{
  getMypurchase()
},[purchaseData.page])

console.log(purchaseData.productDetail)
  return (
    <div className="ff-resp-table table-responsive">
    <table className="table w-100">
      <Timer />
      <thead className="mobile-hide">
        <tr>
          <th>#</th>
          <th>Lot name</th>
          <th>Bid placed on<ArrowDropUpIcon onClick={asending}/><ArrowDropDownIcon onClick={desending}/></th>
          <th>Time left<ArrowDropUpIcon onClick={asending1}/><ArrowDropDownIcon onClick={desending1}/></th>
          <th>Current Bid</th>
          <th>My bid</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
      {!purchaseData.productDetail.length ? 
      <tr>
        <td colspan="7" className="pd-zero text-center">
          <div className="card card-body">No Active Bids Listed</div>
        </td>
      </tr>
      : 
      purchaseData.productDetail.map((item,key)=>
        <tr>
          <td className="resp-td" data-label="#">
      <div className="rtc-wrap">{parseInt(a)+parseInt(key)}</div>
          </td>
          <td className="resp-td" data-label="Lot name">
          <Link to={"/multi-items-view/"+item.id} style={{color:"var(--primColor)"}}><div className="rtc-wrap">{item.title}</div></Link>
          </td>
          <td className="resp-td" data-label="Bid placed on">
            <div className="rtc-wrap">{DateFormat(item.bidTime)}</div>
          </td>
          <td className="resp-td" data-label="Time left">
            <div className="rtc-wrap">{TimeLeft(item.date_closed)}</div>
          </td>
          <td className="resp-td" data-label="My bid">
            <div className="rtc-wrap" style={parseInt(item.rprice)>parseInt(item.latestbid)?{color:"#f44336"}:{color:"var(--primColor)"}}>€ {callNumber(item.latestbid)}</div>
          </td>
          <td className="resp-td" data-label="My bid">
            <div className="rtc-wrap"  style={item.mybid!==item.latestbid?{color:"#f44336"}:{color:"var(--primColor)"}}>€ {callNumber(item.mybid)}</div>
          </td>
          <td className="resp-td" data-label="Action">
            <Link to={"/multi-items-view/"+item.id} className="gray-btn">View</Link>
          </td>
        </tr>
      )}

      </tbody>
    </table>
    <Pagination 
    count = {Math.ceil(purchaseData.totallist / purchaseData.pageperList)}
    onchange = {handlePageChange}
    />
    </div>
  );
};

export default ActiveBids;
