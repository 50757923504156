import { ADD_TOKEN, CHANGE_USER, ADD_TIMER } from "../types"

const initalstate = {
    userdata:localStorage.getItem('userData') || '{}',
    token: localStorage.getItem('usertoken') || '',
    timer: localStorage.getItem('prodtimer') || ''
}

function UserReducer(state=initalstate, action){
    switch(action.type){
        case ADD_TOKEN:
            localStorage.setItem('currentUser',null)
            return Object.assign({}, state, {
                token: action.payload
            })
        case CHANGE_USER:
            localStorage.setItem('currentUser',null)
            return Object.assign({}, state, {
                userdata: action.payload
            })
        case ADD_TIMER:
            localStorage.setItem('currentUser',null)
            return Object.assign({}, state, {
                timer: action.payload
            })
        default:
            localStorage.setItem('currentUser',null)
            return state
    }
}

export default UserReducer