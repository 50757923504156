import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./TrustapRedirect.css";

function TrustapRedirect() {
  const trustap_link = process.env.REACT_APP_TRUSTAP_URL + "/auth?response_type=code&client_id=ae44855d-6b59-499b-bd4e-d1e048be70f3&redirect_uri="+process.env.REACT_APP_FRONT_URL+"&scope=openid%20p2p_ls:offline_create%20p2p_ls:offline_update%20p2p_tx:offline_accept_deposit%20p2p_tx:offline_set_price%20p2p_tx:offline_confirm_handover%20p2p_tx:offline_create_join&state=web_request"
  return (
    <>
      <Header />
      <div className="static-wrap-container container trustapRedirect">
        <div className="tr_logo">
          <img src="/svg/logo.svg" alt="Farm Fayre" height="50" />
          <img src="/svg/trustap-logo.svg" alt="Farm Fayre" height="50" />
        </div>
        <h2>
        We're thrilled you've joined our revolution in safe, secure, and transparent livestock sales.
        </h2>
        <p>
        There's just one more step: Register with{' '} 

 
          <a href={trustap_link} target="_blank">
            Trustap
          </a>
          , our trusted payment provider.This ensures that your funds will be automatically deposited into your designated account once your livestock are delivered and settled.
        </p>
        {/* <p>
          By registering with Trustap when your livestock are delivered, and
          settled, your funds will automatically be deposited to your designated
          account.
        </p> */}
        <p>
          Got questions? Feel free to{" "}
          <a href="https://farmfayre.com/contact" target="_blank">
            Contact Us
          </a>.
        </p>
        <p className="register">
        <a
            href={trustap_link}
            target="_blank"
          >
           Click Here to Register with Trustap
          </a>{" "}
        </p>
        <h6>Thank you for being a part of Farm Fayre.</h6>
        {/* <h6>
          <a
            href={trustap_link}
            target="_blank"
          >
            Click here
          </a>{" "}
          to register with Trustap
        </h6> */}
      </div>
      <Footer />
    </>
  );
}

export default TrustapRedirect;
