import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./new.css";
const useStyles = makeStyles((theme) => ({
  button: {
    "& > *": {
      "@media (min-width: 900px)": {
        width: "340px",
      height: "170px",
      },

      "@media (max-width: 900px)": {
        width: "100%",
        height: "170px",
      }
    },
  },
  tagline: {
    "@media (min-width: 900px)": {
      height: 38,
      width: "100%",
      position: 'absolute',
      bottom: 38,
      fontSize: "13px",
    },
    "@media (max-width: 900px)": {
      height: 38,
      width: "100%",
      position: 'absolute',
      bottom: 38,
      fontSize: "13px",
    }
  },
  main: {
    "@media (min-width: 900px)": {
      height: 100,
    },
    "@media (max-width: 900px)": {
      height: 100,
    }
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#21285D",
    borderRadius: "15px",
    boxShadow: "0px 4px 12px rgba(72, 191, 132, 20%)",
    backgroundColor: "var(--primColor)",
    border: "1px solid var(--primColor)",

    transition: "250ms background linear",
    "&:hover": {
      backgroundColor: "#fff",
      color: "var(--primColor)",
      boxShadow: "0px 4px 12px rgba(72, 191, 132, 20%)",
    },
    fontSize: "24px"
  },
}))(Button);

const CustomPrimaryButton = (props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.button} ${props.btnSize} primButton customButton frontButton`}>
      {/* {console.log("+++++++=",props.value)} */}
      <ColorButton
        variant="contained"
        color="primary"
        disabled={props.dis}
        onClick={props.onsubmit}
        name={props.name}
        value={props.value}
        type={props.type}
      >
        <span className={`${classes.main}`}>
        {props.label}
          {props.iconCenter}
          </span>
        
        <span className={`frontSpan ${classes.tagline}`}>
          {props.tagline1}
          {/* <br></br>{props.tagline2}<br></br>{props.tagline3}<br></br>{props.tagline4} */}
          </span>
      </ColorButton>
    </div>
  );
};


export default CustomPrimaryButton;
