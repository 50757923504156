import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import "./RadioBox.css"

export const GreenRadio = withStyles({
  root: {
    color: "var(--primColor)",
    "&$checked": {
      color: "var(--primColor)",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function RadioBox(props) {
/*   const [value, setValue] = React.useState("female");
 */
/*   const handleChange = (event) => {
    setValue(event.target.value);
  }; */

  return (
    <div className="RadioBox">
      <FormControl component="fieldset">
        <FormLabel component="legend">{props.title}</FormLabel>
        <RadioGroup
          aria-label={props.aria_label}
          name={props.name}
          value={props.value}
          onChange={props.onchange}
        >
          {props.children}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default RadioBox;
