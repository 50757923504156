import { Button, FormHelperText } from "@material-ui/core";
import JwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import CustomInput from "../../components/Inputs/borderedInput";
import CustomSelect from "../../components/Inputs/borderedSelect";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import FFModal from "../../components/Modal";
import AddressCard from '../../components/SavedAddress/AddressCard';
import { isAuthenticated } from "../../Utils/Authenticated";
import { IrishCounties } from "../../Utils/constant";
import { Toast } from "../../Utils/Notifications";
import { request } from "../../Utils/Request";
import UseForm from "../../Utils/UserForm";
import { multipleadrees } from "../../Utils/Validators";

const CheckoutAddress = ({ imgurl, itemid, title, amount, codPrice, props }) => { 

   const[user,setUser]=useState([])
   const [dis, setDis] = useState(false);
  const[add,setadd]=useState([])

  const onPop = async (e) => {
    setDis(true);
  };
    

  const getAddress=async()=>{
    // const res=await request("post","/getUserProfileDetails",{user_id:JwtDecode(isAuthenticated()).id},false,true)
    // setUser(res.data.data)
    try {
      const res = await request(
        "post",
        "/getAddressDetails",
        {
          user_id: localStorage.userId,
        },
        false,
        true
      );
      // const userData = JwtDecode(res.data.data);
      // console.log(userData);
      if (res.data.success === "yes") {
           setadd(res.data.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    getAddress()
  },[])

  var {
    handleChange,
    handleSubmit,
    handleDateChange,
    values,
    errors,
    state,
    setValues,
  } = UseForm(addadrees, multipleadrees);



  async function addadrees(){
    
    // setValues({...values,user_id:})
    var data={
      user_id:JwtDecode(isAuthenticated()).id,
      name:values.address1,
      address:values.address2,
      country:values.country,
      state:values.state,
       city:values.city,
       zipcode:values.zipcode,
       

    }
  
   const res=await request("post","/addnewaddress",data,false,true)
   if(res.data.success==="yes"){
    console.log("+++++++")
     setDis(!dis)
     
     Toast.fire({type:"success",title:"New address added successfully"})
     getAddress()
  }
}


    return (
      
	    <div className="card checkout-card">
        {/* {console.log("++++",user)}  */}
	       <div className="card-body"> 
              <h4 className="clearfix cc-title">
                <span className="delivery">Delivery Address</span>
               <a><Button onClick={onPop}> <span className="material-icons">add</span> Add a new address</Button></a>
              </h4> 
              <div className="row">
            {add && add.map((val)=>
              <div className="col-md-6">
              
                <AddressCard 
                    building={val.name}
                    street={val.address}
                    town={val.city}
                    state={val.state}
                    eir={val.zipcode}
                    dis={"display"}
                    country={val.country} 
                    value={true}
                />
              
              </div> 
              )}
            </div>
	       </div>
         <FFModal
        open={dis}
        handleClose={(e) => setDis(false)}
        modaltitle="Add New Address"
      >
        <div className="row" style={{fontWeight:'500'}}>
          <div className="col-sm-6 form-group">
            <CustomInput
              label="Address Line 1"
              placeholder="Enter Address Line 1"
              tooltiptitle="Enter Address Line 1"
              name="address1"
              // con={false}
              value={values.address1}
              onchange={handleChange}
              error={state && !values.address1?true:false}
              helperText={state && errors.address1}
            />
          </div>
          <div className="col-sm-6 form-group">
            <CustomInput
              label="Townsland / Address Line 2"
              name="address2"
              con={"sn"}
              value={values.address2}
              onchange={handleChange}
             
              placeholder="Enter Townsland / Address Line 2"
              tooltiptitle="Enter Townsland / Address Line 2"
              error={state && !values.address2?true:false}
              helperText={state && errors.address2}
            />
          </div>
          <div className="col-12 form-group">
            <CustomInput
              label="Town Name"
              placeholder="Enter Town Name"
              tooltiptitle="Enter Town Name"
              name="city"
              value={values.city}
              con={false}
              onchange={handleChange}
              error={state && !values.city?true:false}
              helperText={state && errors.city}
            />
          </div>
          <div className="col-12 form-group">
          <CustomSelect
                    id="County"
                    label="Select your county"
                    tooltiptitle="County"
                    name="state"
                    value={values.state}
                    pc_onchange={handleChange}
                    selectMenuHeader={"Select your county"}
                    error={state ? !values.state && true : false}
                  >
                    {/* <option key="" value="">
                      Select your county
                    </option> */}
                  
                    {IrishCounties.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.county}
                    </FormHelperText>)}
          </div>
          <div className="col-12 form-group">
          <CustomSelect
                    id="Country"
                    label="Select your country"
                    tooltiptitle="Country"
                    name="country"
                    value={values.country}
                    pc_onchange={handleChange}
                    selectMenuHeader={"Select your country"}
                    error={state ? !values.country && true : false}
                  >
                    {/* <option key="" value="">
                      Select your county
                    </option> */}
                    <option key="Ireland" value="Ireland">
                
                      Ireland
                    </option>
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.country}
                    </FormHelperText>
                  )}
          </div>
          <div className="col-12 form-group">
                  <CustomInput
                    id="eir-code"
                    label="EIR code"
                    placeholder="Enter EIR code"
                    tooltiptitle="EIR code"
                    name="zipcode"
                    con={"sn"}
                    value={values.zipcode}
                    onchange={handleChange}
                    error={state ? !values.zipcode && true : false}
                    helperText={state && errors.zipcode}
                  />
                </div>
          <div className="pl-btn pt-4">
            <PrimaryButton onsubmit={handleSubmit} label="SAVE" />
          </div>
        </div>
      </FFModal>
	    </div>
    );
}

export default CheckoutAddress