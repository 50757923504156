import React,{useState, useEffect} from 'react'; 
import DBLayout from '../../components/DBLayout';  
import CheckBox from "../../components/Inputs/CheckBox";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { request } from "../../Utils/Request"

import {Popup, Toast} from "../../Utils/Notifications"
const Notifications = props => { 
  const [data1, setDataemail] = useState({
    watchlist: true,
    bid: true,
    outbid: true,
    won: true,
    save: true,
    id:""
  })
  
  const [data2, setDatasms] = useState({
    watchlist: true,
    bid: true,
    outbid: true,
    won: true,
    save: true
  })

  const [data3, setDatnotification] = useState({
    watchlist: true,
    bid: true,
    outbid: true,
    won: true,
    save: true
  })

  const getNotification=async(e)=>{
    // var data = {
    //   status:"watchlist"
    // }
    try{
    const res = await request (
      "post",
      "/getnotification",
       {},
       false,
       true
    )
    if (res.data.success === 'yes') {
    setDataemail({ 
      ...data1,
      watchlist: res.data.data[0].email.includes('1') ? true : false,
      bid: res.data.data[0].email.includes('2') ? true : false,
      outbid: res.data.data[0].email.includes('3') ? true : false,
      won: res.data.data[0].email.includes('4') ? true : false,
      save: res.data.data[0].email.includes('5') ? true : false,
      id:res.data.data[0].id
    })
    setDatasms({ 
      ...data2,
      watchlist: res.data.data[0].sms.includes('1') ? true : false,
      bid: res.data.data[0].sms.includes('2') ? true : false,
      outbid: res.data.data[0].sms.includes('3') ? true : false,
      won: res.data.data[0].sms.includes('4') ? true : false,
      save: res.data.data[0].sms.includes('5') ? true : false,
    })

    setDatnotification({ 
      ...data3,
      watchlist: res.data.data[0].push_notification.includes('1') ? true : false,
      bid: res.data.data[0].push_notification.includes('2') ? true : false,
      outbid: res.data.data[0].push_notification.includes('3') ? true : false,
      won: res.data.data[0].push_notification.includes('4') ? true : false,
      save: res.data.data[0].push_notification.includes('5') ? true : false,
    })

  }
  } catch (error) {
    console.log(error)
  }
  }
  const updateNotification = async () => {
    try {
     
      var arr = []
      if (data1.watchlist) 
        arr.push('1')
      if (data1.bid) 
        arr.push('2')
      if (data1.outbid) 
        arr.push('3')
      if (data1.won) 
        arr.push('4')
      if (data1.save) 
        arr.push('5')

        var arr1=[]

      if (data2.watchlist) 
        arr1.push('1')
      if (data2.bid) 
        arr1.push('2')
      if (data2.outbid) 
        arr1.push('3')
      if (data2.won) 
        arr1.push('4')
      if (data2.save) 
        arr1.push('5')

        var arr2=[]
      if (data3.watchlist) 
        arr2.push('1')
      if (data3.bid) 
        arr2.push('2')
      if (data3.outbid) 
        arr2.push('3')
      if (data3.won) 
        arr2.push('4')
      if (data3.save) 
        arr2.push('5')  

      const res = await request("post",'/updatenotification',{notify_id:data1.id,email_settings:arr.toString(),sms_settings:arr1.toString(),push_settings:arr2.toString()},false,true)
      if (res.data.success === 'yes') {
        
        return Toast.fire({ title: 'Updated Successfully!', type: 'success' })
      }
      
    } catch (error) {
      
      console.log(error)
    }
  }
  const notifiChangeemail = (e) => {
    var { name, value } = e.target
    if(value === 'true' || value ===  true) {
      value = false
    } else if(value === 'false' || value === false) {
        value = true
    }
    setDataemail({ ...data1, [name]: value })
  }
  const notifiChangesms = (e) => {
    var { name, value } = e.target
    if(value === 'true' || value ===  true) {
      value = false
    } else if(value === 'false' || value === false) {
        value = true
    }
    setDatasms({ ...data2, [name]: value })
  }

  const notifiChangeotification = (e) => {
    var { name, value } = e.target
    if(value === 'true' || value ===  true) {
      value = false
    } else if(value === 'false' || value === false) {
        value = true
    }
     setDatnotification({ ...data3, [name]: value })
  }


  useEffect(()=>{
     getNotification()
  },[])

  return (
    <DBLayout title="Notifications"> 
       <div className="form-wrapper pw-box">
          <p className="label-txt">
            The marketplace sends email based on a wide variety of events that may occur as seen below. Some notifications such as Payment information will be sent regardless of your email preference setting.
          </p>
          
          <div className="table-responsive notify-table">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Notification</th>
                <th className="text-center">Email</th>
                <th className="text-center">SMS</th>
                {/* <th className="text-center">Push Notification</th> */}
              </tr>
            </thead>
            <tbody>
                <tr>
                  <td>01</td>
                  <td>Bid submitted.</td>
                  <td className="notify-check">
                    <CheckBox name={"bid"} value={data1.bid} onchange={notifiChangeemail}/>
                  </td>
                  <td className="notify-check">
                    <CheckBox value={data2.bid} name={"bid"} onchange={notifiChangesms} />
                  </td>
                  {/* <td className="notify-check">
                    <CheckBox value={data3.bid} name={"bid"}  onchange={notifiChangeotification}/>
                  </td> */}
                </tr>
                <tr>
                  <td>02</td>
                  <td>Item successfully added to your watchlist.</td>
                  <td className="notify-check">
                    <CheckBox value={data1.watchlist} name={"watchlist"} onchange={notifiChangeemail} />
                  </td>
                  <td className="notify-check">
                    <CheckBox value={data2.watchlist} name={"watchlist"} onchange={notifiChangesms} />
                  </td>
                  {/* <td className="notify-check">
                     <CheckBox value={data3.watchlist} name={"watchlist"}  onchange={notifiChangeotification}/>
                  </td> */}
                </tr>
                <tr>
                  <td>03</td>
                  <td>Please make the payment for the product you won.</td>
                  <td className="notify-check">
                    <CheckBox value={data1.won} name={"won"} onchange={notifiChangeemail} />
                  </td>
                  <td className="notify-check">
                  <CheckBox value={data2.won} name={"won"} onchange={notifiChangesms} />
                  </td>
                  {/* <td className="notify-check">
                    <CheckBox value={data3.won} name={"won"} onchange={notifiChangeotification} /> 
                  </td> */}
                </tr>
                <tr>
                  <td>04</td>
                  <td>You have been outbid on a product.</td>
                  <td className="notify-check">
                    <CheckBox value={data1.outbid} name={"outbid"} onchange={notifiChangeemail} />
                  </td>
                  <td className="notify-check">
                  <CheckBox value={data2.outbid} name={"outbid"} onchange={notifiChangesms} />
                  </td>
                  {/* <td className="notify-check">
                    <CheckBox value={data3.outbid} name={"outbid"} onchange={notifiChangeotification} />
                  </td> */}
                </tr>
                <tr>
                  <td>05</td>
                  <td>Saved Search.</td>
                  <td className="notify-check">
                    <CheckBox value={data1.save } name={"save"} onchange={notifiChangeemail} />
                  </td>
                  <td className="notify-check" >
                    <CheckBox value={data2.save} name={"save"} onchange={notifiChangesms} />
                  </td>
                  {/* <td className="notify-check">
                    <CheckBox value={data3.save} name={"save"} onchange={notifiChangeotification} />
                  </td> */}
                </tr>
                {/* <tr>
                  <td>06</td>
                  <td>Notification when Favorite seller ads new livestock</td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                </tr>
                <tr>
                  <td>07</td>
                  <td>Auction ended</td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                </tr>
                <tr>
                  <td>08</td>
                  <td>Notification of Countdown to watched item</td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                  <td className="notify-check">
                    <CheckBox value={true} />
                  </td>
                </tr> */}
            </tbody>
            
          </table>
          </div>
          <div className="pl-btn pt-4">
            <PrimaryButton onsubmit={updateNotification} label="SAVE" />
          </div>
       </div>
    </DBLayout>
  );
};

export default Notifications;
