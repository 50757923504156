import React, { useEffect } from 'react';
import { isAuthenticated } from "../../Utils/Authenticated";
import PrimaryButton from "../../components/Inputs/PrimaryButton";

const Tempdashboard = ({props,history})=>{
console.log(localStorage.userId)
console.log(localStorage.userRole)
console.log(isAuthenticated())
  useEffect(() => {
    if (isAuthenticated()) {
      history.push('/')
    }
  }, [])

const handleSubmit_tier2=()=>{
      history.push('/Reg_tier_2')
}
const handleSubmit_tier3=()=>{
  history.push('/Reg_tier_3')
}

if(localStorage.userRole === "tier1"){
  return (
    <div className="ffReg">
          <div className="ffRegCnt">
            <h1 className="logo">LOGO</h1>
            <div className="greetMsg">
              <h2 className="playFair">Visitor dashboard</h2>
              <p className="playFair">Welcome to Farm Fayre {localStorage.userRole} dashboard</p>
              <h3 className="playFair"> visitor dashboard goes here</h3>
              <p className="playFair">
                Upgrade your account to tier 2
              </p>
            </div>
            <form>
              <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
              </div>
              <div className="actBtn">
                <PrimaryButton label="Upgrade to Tier 2" 
                onsubmit = {handleSubmit_tier2}
                />
              </div>
            </form>
    
          </div>
          <p className="footerText">© 2020 Farm Fayre. All rights reserved.</p>
        </div>   
  )

} else if (localStorage.userRole === "tier2"){

  return(
    <div className="ffReg">
    <div className="ffRegCnt">
      <h1 className="logo">LOGO</h1>
      <div className="greetMsg">
        <h2 className="playFair">Visitor dashboard</h2>
        <p className="playFair">Welcome to Farm Fayre {localStorage.userRole} dashboard</p>
        <h3 className="playFair"> Seller/Buyer dashboard goes here</h3>
        <p className="playFair">
          Upgrade your account to tier 3
        </p>
      </div>
      <form>
        <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
        </div>
        <div className="actBtn">
          <PrimaryButton label="Upgrade to Tier 3" 
          onsubmit = {handleSubmit_tier3}
          />
        </div>
      </form>

    </div>
    <p className="footerText">© 2020 Farm Fayre. All rights reserved.</p>
  </div>   
  )
}else if (localStorage.userRole === "tier3"){

  return(
    <div className="ffReg">
    <div className="ffRegCnt">
      <h1 className="logo">LOGO</h1>
      <div className="greetMsg">
        <h2 className="playFair">Visitor dashboard</h2>
        <p className="playFair">Welcome to Farm Fayre {localStorage.userRole} dashboard</p>
        <h3 className="playFair"> Seller dashboard goes here</h3>

      </div>
      <form>
        <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
        </div>  
      </form>

    </div>
    <p className="footerText">© 2020 Farm Fayre. All rights reserved.</p>
  </div>  

  )
}


    }


export default Tempdashboard;