import React, { useEffect, useState } from "react";
import "./Registration.css";
import { isAuthenticated } from "../../Utils/Authenticated";
import { ValidateRegisterationtier_1 } from "../../Utils/Validators";
import UseForm from "../../Utils/UserForm";
import { request } from "../../Utils/Request";
import JwtDecode from "jwt-decode";
import { Popup, Toast } from "../../Utils/Notifications";
import InputField from "../../components/Inputs/InputField";
import InputFieldAuto from "../../components/Inputs/InputFieldAuto";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { HeardFrom, TextOnly } from "../../Utils/constant";
import SelectField from "../../components/Inputs/SelectField";
import Tooltip from "@material-ui/core/Tooltip";
import { Button, FormHelperText, ListItem } from "@material-ui/core";
import FFModal from "../../components/Modal";
import CheckBox from "../../components/Inputs/CheckBox";
import ReactPlayer from "react-player"
import PhoneInput from 'react-phone-input-2';
import moment from "moment";

const Reg_tier_1 = ({ props, history }) => {

  const search = history.location.search;
const params = new URLSearchParams(search);
// const refer_id = params.get('refer_id');
  const[modalopen1,setModalopen]=useState(false)
  const[modalopen2,setModalopen2]=useState(false)
  const[refer_id,setReferID]=useState(params.get('refer_id'))
  const[video,setVideo]=useState(params.get('video'))
  const[refer_count,setReferCount]=useState(-1)
  const [btnloading,setBtnloading]=useState(false)
  const moveon=(value)=>{
     console.log("+++++++++++++")
      if(value=="Other")
      {

        setModalopen(!modalopen1)
        setValues({...values,heard_from:""})
      } 
      else if (value=="Existing Member")
      {
        setModalopen2(!modalopen2)
        setValues({...values,heard_from:""})
      } else if (value=="Haulier" )
      {
        setValues({...values,
          heard_from:"John Cullen",
          refer_email: "Maxboleyblondes15@gmail.com"
        })

        callcommand2("John Cullen", "Maxboleyblondes15@gmail.com")
      }
    }
    const toggleModal1=()=>{
      setModalopen(!modalopen1)
    }
    const callcommand=()=>{
      HeardFrom.push(values.heard_from)
       setModalopen(!modalopen1)
    }  
    const toggleModal2=()=>{
      setModalopen2(!modalopen2)
    }
    const callcommand2= async(heard_from, email)=>{

      var data = {
        heard_from: values.heard_from,
        type: 'heard_from',
        //address: values.refer_address,
        email: values.refer_email,
        phone: values.refer_phone,
      }

      if (heard_from && email){
        data = {
          heard_from: heard_from,
          type: 'heard_from',
          email: email,
          phone: values.refer_phone,
        }
      }

      if (values.refer_email == 'mbl.transport@farmfayre.com'){
        data = {
          heard_from: "John Cullen",
          type: 'heard_from',
          email: "Maxboleyblondes15@gmail.com",
          phone: values.refer_phone,
        }
      }

       const res = await request(
        "post",
        "/getReferDetails",
        data,
        false,
        true
      );
      console.log('UserProfile p',values.refer_email)
      if (res.data.success){
        console.log('UserProfile',res.data.data.id)
        setReferID(res.data.data.id)
        setValues({...values,heard_from:res.data.data.name})
        if(!HeardFrom.includes(res.data.data.name)){
          HeardFrom.push(res.data.data.name)
        }
        setModalopen2(false)
      } else {
        setReferCount(res.data.count)
        if (res.data.count == 0){
          Toast.fire({ title: "Can’t find a member match. Please enter member email or phone number and try again.", icon: "info" });
        } else {
          Toast.fire({ title: "More then one match members are found. Please enter member email or phone number and try again.", icon: "info" });
        }
      }
    }  

  useEffect(() => {
    if (isAuthenticated()) {
      history.push("/");
    }
    
    if (refer_id){
      console.log("refer_id",refer_id)
      ReferUser()
    } else {
      console.log("no refer")
    }
  }, []);

  const ReferUser = async () => {
    const res = await request(
      "post",
      "/getReferDetails",
      {
        refer_id: refer_id,
        type: 'refer_id'
      },
      false,
      true
    );
    if (res.data.success){
      console.log('UserProfile',res.data.data.name)
      HeardFrom.push(res.data.data.name)
      setValues({...values,heard_from:res.data.data.name})
    }  
  };

  var { handleSubmit, handleChange, values, errors, state,setValues } = UseForm(
    formSubmit,
    ValidateRegisterationtier_1
  );


  const [loader, setLoader] = useState(false);

  async function formSubmit() {
    setBtnloading(true)
    const form_data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      heard_from: values.heard_from,
      company_name: values.company_name,
      username: values.username,
      refer_id:refer_id,
      role: "tier1",
      status: "unverified",
      redirectURL: process.env.REACT_APP_FRONT_URL + '/login',
    };
    console.log('form_data',form_data)
    try {
      const res = await request("post", "/checkEmailExistance", {
        email: form_data.email,
        username: form_data.username,
      });
      console.log(res.data);
      if (!res.data.status) {
        setLoader(false);
        setBtnloading(false)
        return Toast.fire({ type: "warning", title: res.data.message });
      } else {
        const response = await request("post", "/register", form_data);
        console.log(res.data);
        setBtnloading(false)
        if (response.data.status === "yes") {
          await history.push("/login");
          return Popup.fire({
            type: "success",
            html: "<b>Thank you for Registering with Farm Fayre.<br> Please check you email to verify your details.<br> Thank you.</b>",
          });
        } else {
          await history.push("/Reg_tier_1");
          return Popup.fire({
            type: "error",
            title: "Something went wrong, Please try again",
          });
        }
      }
      setLoader(false);
      setBtnloading(false)
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }

  return (
    <div className="ffReg reg1">
      <div className="ffRegCnt">
        <Link to="/">
          <img src="/svg/logo.svg" alt="Farm Fayre" height="75" />
        </Link>
        <div className="greetMsg">
        
        {(refer_id || video)? 
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '40px',
        }}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=nk_E1mI4b60"
            />
          </div>
          :''
        }

          <h2 className="playFair">Visitor Registration</h2>
          {/* <p className="playFair">Welcome to Farm Fayre</p> */}
          <p className="playFair">
            Create your account to access the future of online livestock trading.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="ffRegBox d-flex justify-content-between align-items-center flex-wrap">
            <div className="ffRegInp">

              <InputFieldAuto
                label="First Name*"
                placeholder="Enter your first name"
                type="text"
                name="first_name"
                value={values.first_name}
                // con={false}
                onchange={handleChange}
                helperText={state && errors.first_name}
              />
            </div>

            <div className="ffRegInp">
              <InputFieldAuto
                label="Last Name*"
                placeholder="Enter your last name"
                type="text"
                name="last_name"
                // con={false}
                value={values.last_name}
                onchange={handleChange}
                helperText={state && errors.last_name}
              />
            </div>

            <div className="ffRegInp">
              <InputFieldAuto
                label="Company Name"
                placeholder="Enter your company name"
                type="text"
                name="company_name"
                // con={false}
                value={values.company_name}
                onchange={handleChange}
                helperText={state && errors.company_name}
              />
              <Tooltip title="Only input if you are signing up as a Company. If signing up as an individual leave blank." placement="top-bottom">
                <ListItem className="helpingTooltip d-flex align-items-center">
                  <span class="material-icons">help</span> 
                  {/* why is this required */}
                </ListItem>
              </Tooltip>
            </div>

            <div className="ffRegInp">
              <InputFieldAuto
                label="Email Address*"
                placeholder="Enter your email address"
                type="email"
                name="email"
                value={values.email}
                onchange={handleChange}
                helperText={state && errors.email}
              />
            </div>
            <div className="ffRegInp">
              <InputField
                label="Username*"
                placeholder="Enter your username"
                type="text"
                name="username"
                // con={false}
                value={values.username}
                onchange={handleChange}
                helperText={state && errors.username}
              /> 
            </div>

            <div className="ffRegInp">
              <InputFieldAuto
                label="Password*"
                placeholder="Enter your password"
                type="password"
                name="password"
                value={values.password}
                onchange={handleChange}
                helperText={state && errors.password}
              />
            </div>

            <div className="ffRegInp">
              <InputFieldAuto
                label="Confirm Password*"
                placeholder="Re-enter your password"
                type="password"
                name="confirm_password"
                value={values.confirm_password}
                onchange={handleChange}
                helperText={state && errors.confirm_password}
              />
            </div>

            <div className="ffRegInp hear">
              <SelectField
                label="Where did you hear about us?*"
                name="heard_from"
                selectMenu={HeardFrom}
                value={values.heard_from}
               

                onchange={(e)=>{handleChange(e);moveon(e.target.value)}}

                helperText={state && errors.heard_from}
              />
              <FormHelperText error>{state && errors.heard_from}</FormHelperText>
            </div>
           
          </div>
        
          <div className="agreeTerms">
          <CheckBox
                      label={<p><span>I confirm that I have read, understand,  and agree to the </span><a style={{color:"green"}} target={"_blank"} href={"/terms"}>Terms & Conditions</a><span> and </span><a style={{color:"green"}} target={"_blank"} href={"/privacypolicy"}>Privacy Policy</a><span> of Farm Fayre.</span></p>}
                      name="terms" 
                      /* value={values.singleitemlot_auction} */
                      value={values.terms}
                      click={(e) => !values.terms}
                      onchange={handleChange}
                    />
                    <FormHelperText error>{state && errors.terms}</FormHelperText>
          </div>


          <div className="actBtn">
            <PrimaryButton label="Register" type="submit" />
        </div>
        </form>

        <div className="accHelp">
          <p>Already have an account?</p>
          <span>Login</span> <Link to="/login">here</Link>
        </div>
      </div>
      <p className="footerText">© {moment().year()} Farm Fayre. All rights reserved.</p>
    
      <FFModal
           open={modalopen1}
           handleClose={toggleModal1}
           modaltitle="Message"
           >
          <div style={{fontWeight:'500'}}>
          <textarea name="heard_from" value={values.heard_from}  onChange={handleChange}>Enter text here...</textarea>
          {/* onKeyPress={TextOnly} */}
           {/* <Button onClick={callcommand}>submit</Button> */}
           <div className="pl-btn pt-4">
           <PrimaryButton label={"Submit"} onsubmit={callcommand} btnSize={"9"}></PrimaryButton>
           </div>
          </div> 
           </FFModal>

        <FFModal
           open={modalopen2}
           handleClose={toggleModal2}
           modaltitle="Message"
           >
          <div style={{fontWeight:'500'}}>

              <InputFieldAuto
                label="Enter member name here"
                placeholder="Enter member name here"
                type="text"
                name="heard_from"
                value={values.heard_from}
                // con={false}
                onchange={handleChange}
              />
              {refer_count >= 0 && refer_count != 1 ? 
              <>
              <InputField
                label="Enter member email here"
                placeholder="Enter member email here"
                type="text"
                name="refer_email"
                value={values.refer_email}
                // con={false}
                onchange={handleChange}
              />
              <InputField
                label="Enter member phone here"
                placeholder="Enter member phone here"
                type="text"
                name="refer_phone"
                value={values.refer_phone}
                // con={false}
                onchange={handleChange}
              />
             </>
              :''}
              {/* {refer_count > 1 ? 
              <InputField
                label="Enter member address here"
                placeholder="Enter member address here"
                type="text"
                name="refer_address"
                value={values.refer_address}
                // con={false}
                onchange={handleChange}
              />:''} */}
           {/* <Button onClick={callcommand}>submit</Button> */}
           <div className="pl-btn pt-4">
           <PrimaryButton label={"Submit"} dis={btnloading} onsubmit={callcommand2} btnSize={"9"}></PrimaryButton>
           </div>
          </div> 
        </FFModal>
    </div>
  );
};

export default Reg_tier_1;
