//Image URLs
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL + "/uploads/product/"; //Product Image
export const AvatarURL = process.env.REACT_APP_IMAGE_URL + "/uploads/"; //Avatar / profile images

export const callNumber = (num) => {
  return new Intl.NumberFormat().format(num);
};
export const perkgamount = (num, avgweight) => {
  return parseFloat(num / avgweight).toFixed(2);
};
export const callNumber1 = (num) => {
  return new Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(num);
};
// export const callNumber=(num)=>{
//   return  new DecimalFormat().format(new Intl.NumberFormat().format(num)); }

export const NumberOnly = (e) => {
  console.log("+++++++++++++++", e.which);
  if ((e.which !== 8 && e.which !== 0 && e.which < 48) || e.which > 57)
    e.preventDefault();
};
export const Cd = (e) => {
  console.log("+++++++++++++++", e.which);
  if (
    (e.which !== 8 && e.which !== 0 && e.which < 48 && e.which !== 47) ||
    e.which > 57
  )
    e.preventDefault();
};
export const Phone = (e) => {
  console.log("+++++++++++++++", e.which);
  if (
    (e.which !== 8 && e.which !== 0 && e.which < 48 && e.which !== 43) ||
    e.which > 57
  )
    e.preventDefault();
};
export const TextOnly = (e) => {
  console.log("+++++++++++++++++++++++++++k", e.which);
  if (
    (e.which <= 64 && e.which !== 32) ||
    e.which === 91 ||
    e.which === 93 ||
    e.which === 92 ||
    e.which === 94 ||
    e.which === 95 ||
    e.which === 96 ||
    e.which > 122
  ) {
    e.preventDefault();
  }
};
export const dallow = (e) => {
  e.preventDefault();
};
export const specialcharacter = (e) => {
  console.log("++====================", e.which);
  if (
    (e.which >= 33 &&
      e.which <= 47 &&
      e.which !== 35 &&
      e.which !== 45 &&
      e.which !== 47) ||
    (e.which >= 58 && e.which <= 64) ||
    (e.which >= 91 && e.which <= 96) ||
    (e.which >= 123 && e.which <= 126)
  ) {
    e.preventDefault();
  }
};
export const HerdNum = (e) => {
  console.log("++====================", e.which);
  if (
    (e.which >= 32 && e.which <= 47) ||
    (e.which >= 58 && e.which <= 64) ||
    (e.which >= 91 && e.which <= 96) ||
    (e.which >= 123 && e.which <= 126)
  ) {
    e.preventDefault();
  }
};

export const Sentencecase = (val) => {
  var a = "";
  a = val.toString();
  var b = a.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) {
    return c.toUpperCase();
  });
  console.log("++====================", b);
  return b;
};

export const number_of_animals = [1, 2, 3, 4, 5, "More.."];
export const Quality_asurance = [
  { value: 1, name: "QA Farm" },
  { value: 2, name: "Twenty20 Club" },
  { value: 3, name: "ABP Advantage" },
  { value: 4, name: "Organic" },
  { value: 5, name: "FeedLot Status" },
];
export const Section = ["Beef", "Dairy", "Calf", "Pedigree"];
export const HeardFrom = [
  "Facebook",
  "Twitter",
  "Instagram",
  "Farmers Journal",
  "Haulier",
  "Existing Member",
  "Other",
];
export const Type = [
  "Bull",
  "Bullock",
  "Cow",
  "Heifer",
  "Suckler",
  "Weanling",
  "Specialist",
  "Cow & Calf",
];
export const Calf_Type = ["Bull", "Bullock", "Heifer", "Mix"];
export const Owners = ["0", "1", "2", "3", "4", ">5"];
export const Crossbreed = [
  "Angus",
  "Aubrac",
  "Belgian Blue",
  "Blonde D'Aquitaine",
  "Charolais",
  "Dexter",
  "Friesian",
  "Hereford",
  "Holstein",
  "Jersey",
  "Kerry",
  "Limousin",
  "Meuse Rhine Issel",
  "Montbeliarde",
  "Norwegian Red",
  "Parthenaise",
  "Piedmontese",
  "Salers",
  "Shorthorn",
  "Simmental",
  "Speckle Park",
];
export const Breed = [
  "Angus",
  "Aubrac",
  "Belgian Blue",
  "Blonde D'Aquitaine",
  "Charolais",
  "Dexter",
  "Friesian",
  "Hereford",
  "Holstein",
  "Jersey",
  "Kerry",
  "Limousin",
  "Meuse Rhine Issel",
  "Montbeliarde",
  "Norwegian Red",
  "Parthenaise",
  "Piedmontese",
  "Salers",
  "Shorthorn",
  "Simmental",
  "Speckle Park",
  "Other",
  "Crossbreed",
];
export const BreedICBF = [
  "Angus",
  "Arkole",
  "Armoricaine",
  "Angler",
  "Aubrac",
  "Ayrshire",
  "Beefalo",
  "Irish Moiled Moiled",
  "Bison",
  "Braunbieh",
  "Bazadaise",
  "Chillingham",
  "Charolais",
  "Chianina",
  "Chillingham",
  "Devon",
  "Droimeann",
  "Devon",
  "Dexter",
  "Fleckvieh",
  "Friesian",
  "Galloway",
  "Gasconne",
  "Gloucester",
  "Gloucester",
  "Guernsey",
  "Gelbvieh",
  "Gayal",
  "Hereford",
  "Highland",
  "Holstein",
  "INRA95",
  "Jersey",
  "Australia Lowline AB",
  "Blonde aAquitaine",
  "Belgian Blue",
  "Water Buffalo",
  "Blue Grey",
  "British White",
  "Blue Albion",
  "Beef Master",
  "Blue Albion",
  "Bretonne Pie-Noire",
  "Brown Swiss",
  "Baltata Romaneasca",
  "British White",
  "Belted Galloway",
  "Canadian Black",
  "Dwarf Zebu",
  "East Finnish Brown",
  "English Park",
  "Frisona Espagnola",
  "Groninger Blaarkop",
  "Murray Grey",
  "Kerry",
  "Longhorn",
  "Limousin",
  "Lincoln Red",
  "Luing",
  "Lakenvelder",
  "Malkekorthorn",
  "Welsh White",
  "Normande",
  "Parthenaise",
  "Romagnola",
  "Undefined Red & White",
  "Salers",
  "South Devon",
  "Swiss Grey",
  "Shorthorn",
  "Simmental",
  "Shetland",
  "Speckle Park",
  "Tyrone Black",
  "Tarataise-Tarina",
  "Tyrone Black",
  "Vaynol",
  "Verbeterd Roodbont Vleesras",
  "Valdostana Nera",
  "Vosgienne",
  "Wagyu",
  "Welsh Black",
  "White Galloway",
  "Welsh Black",
  "White Park",
  "Yak",
  "Zebu",
  "Lithuanian Black And White",
  "Maine Anjou",
  "Marchigiana",
  "Montbeliarde",
  "Milking Shorthorn",
  "Meuse Rhine Issel",
  "Norwegian Red",
  "Old English",
  "Piedmontese",
  "Pustertaler Sprizen",
  "Pinzgauer",
  "Rotbunte",
  "Danish Red",
  "Reggiana",
  "Red Poll",
  "Swedish Red",
  "Stabiliser",
  "Sussex",
  "Unknown",
  "Vaynol",
];
export const Weight = [
  "150 or less",
  "151 - 300",
  "301 - 450",
  "451 - 600",
  "601 - 750",
  "751 - 900",
  "901 or more",
];
export const Sex = ["Male", "Female"];
export const Age = [
  "< 6 Months",
  "7 - 12 Months",
  "13 - 18 Months",
  "19 -24 Months",
  "25 - 30 Months",
  " > 31 Months",
];

export const Provinces = [
  "South East",
  "Leinster North (Coming Soon)",
  "Munster  (Coming Soon)",
  "Connaught  (Coming Soon)",
  "Ulster  (Coming Soon)",
];
export const weimethod = ["Weighing Scales", "Guesstimate"];

export const salemethod = ["Sold by Weight", "Not Sold by Weight"];

export const NewCounties = [
  "Carlow",
  "Kilkenny",
  "Kildare",
  "Laois",
  "Wexford",
  "Wicklow",
  "Tipperary",
  "Waterford",
];

export const IrishCounties = [
  "Antrim",
  "Armagh",
  "Carlow",
  "Cavan",
  "Clare",
  "Cork",
  "Derry",
  "Donegal",
  "Down",
  "Dublin",
  "Fermanagh",
  "Galway",
  "Kerry",
  "Kildare",
  "Kilkenny",
  "Laois",
  "Leitrim",
  "Limerick",
  "Longford",
  "Louth",
  "Mayo",
  "Meath",
  "Monaghan",
  "Offaly",
  "Roscommon",
  "Sligo",
  "Tipperary",
  "Tyrone",
  "Waterford",
  "Westmeath",
  "Wexford",
  "Wicklow",
];

export const CountiesShortform = [
  { fname: "Antrim", sname: "Antrim" },
  { fname: "Armagh", sname: "Armagh" },
  { fname: "Carlow", sname: "CW" },
  { fname: "Cavan", sname: "CN" },
  { fname: "Clare", sname: "CE" },
  { fname: "Cork", sname: "C" },
  { fname: "Derry", sname: "Derry" },
  { fname: "Donegal", sname: "DL" },
  { fname: "Down", sname: "Down" },
  { fname: "Dublin", sname: "Dublin" },
  { fname: "Fermanagh", sname: "Fermanagh" },
  { fname: "Galway", sname: "G" },
  { fname: "Kerry", sname: "KY" },
  { fname: "Kildare", sname: "KE" },
  { fname: "Kilkenny", sname: "KK" },
  { fname: "Laois", sname: "LS" },
  { fname: "Leitrim", sname: "LM" },
  { fname: "Limerick", sname: "L" },
  { fname: "Longford", sname: "LD" },
  { fname: "Louth", sname: "LH" },
  { fname: "Mayo", sname: "MO" },
  { fname: "Meath", sname: "MH" },
  { fname: "Monaghan", sname: "MN" },
  { fname: "Offaly", sname: "OY" },
  { fname: "Roscommon", sname: "RN" },
  { fname: "Sligo", sname: "SO" },
  { fname: "Tipperary", sname: "T" },
  { fname: "Tyrone", sname: "Tyrone" },
  { fname: "Waterford", sname: "W" },
  { fname: "Westmeath", sname: "WH" },
  { fname: "Wexford", sname: "WX" },
  { fname: "Wicklow", sname: "WW" },
];

export const Countries = [
  "Ireland",
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
];

export const BreedshortNames = [
  { fname: "Angus", sname: "AA" },
  { fname: "Aubrac", sname: "AU" },
  { fname: "Belgian Blue", sname: "BB" },
  { fname: "Blonde D'Aquitaine", sname: "DAQ" },
  { fname: "Charolais", sname: "CH" },
  { fname: "Dexter", sname: "DEX" },
  { fname: "Friesian", sname: "FR" },
  { fname: "Hereford", sname: "HER" },
  { fname: "Holstein", sname: "HOL" },
  { fname: "Jersey", sname: "JER" },
  { fname: "Kerry", sname: "KER" },
  { fname: "Limousin", sname: "LIM" },
  { fname: "Meuse Rhine Issel", sname: "MRI" },
  { fname: "Montbeliarde", sname: "MB" },
  { fname: "Norwegian Red", sname: "NR" },
  { fname: "Parthenaise", sname: "PAR" },
  { fname: "Piedmontese", sname: "PIE" },
  { fname: "Salers", sname: "SAL" },
  { fname: "Shorthorn", sname: "SH" },
  { fname: "Simmental", sname: "SIM" },
  { fname: "Speckle Park", sname: "SPK" },
];

export const BreedshortNamesICBF = [
  { sname: "AA", fname: "Angus" },
  { sname: "AK", fname: "Arkole" },
  { sname: "AM", fname: "Armoricaine" },
  { sname: "AN", fname: "Angler" },
  { sname: "AU", fname: "Aubrac" },
  { sname: "AY", fname: "Ayrshire" },
  { sname: "BE", fname: "Beefalo" },
  { sname: "IM", fname: "Irish Moiled Moiled" },
  { sname: "BI", fname: "Bison" },
  { sname: "BR", fname: "Braunbieh" },
  { sname: "BZ", fname: "Bazadaise" },
  { sname: "CG", fname: "Chillingham" },
  { sname: "CH", fname: "Charolais" },
  { sname: "CI", fname: "Chianina" },
  { sname: "CL", fname: "Chillingham" },
  { sname: "DE", fname: "Devon" },
  { sname: "DN", fname: "Droimeann" },
  { sname: "DV", fname: "Devon" },
  { sname: "DX", fname: "Dexter" },
  { sname: "FL", fname: "Fleckvieh" },
  { sname: "FR", fname: "Friesian" },
  { sname: "GA", fname: "Galloway" },
  { sname: "GC", fname: "Gasconne" },
  { sname: "GL", fname: "Gloucester" },
  { sname: "GT", fname: "Gloucester" },
  { sname: "GU", fname: "Guernsey" },
  { sname: "GV", fname: "Gelbvieh" },
  { sname: "GY", fname: "Gayal" },
  { sname: "HE", fname: "Hereford" },
  { sname: "HI", fname: "Highland" },
  { sname: "HO", fname: "Holstein" },
  { sname: "IN", fname: "INRA95" },
  { sname: "JE", fname: "Jersey" },
  { sname: "AL", fname: "Australia Lowline AB" },
  { sname: "BA", fname: "Blonde aAquitaine" },
  { sname: "BB", fname: "Belgian Blue" },
  { sname: "BF", fname: "Water Buffalo" },
  { sname: "BG", fname: "Blue Grey" },
  { sname: "BH", fname: "British White" },
  { sname: "BL", fname: "Blue Albion" },
  { sname: "BM", fname: "Beef Master" },
  { sname: "BN", fname: "Blue Albion" },
  { sname: "BP", fname: "Bretonne Pie-Noire" },
  { sname: "BS", fname: "Brown Swiss" },
  { sname: "BT", fname: "Baltata Romaneasca" },
  { sname: "BW", fname: "British White" },
  { sname: "BY", fname: "Belted Galloway" },
  { sname: "CB", fname: "Canadian Black" },
  { sname: "DZ", fname: "Dwarf Zebu" },
  { sname: "EF", fname: "East Finnish Brown" },
  { sname: "EP", fname: "English Park" },
  { sname: "FE", fname: "Frisona Espagnola" },
  { sname: "GB", fname: "Groninger Blaarkop" },
  { sname: "GS", fname: "Murray Grey" },
  { sname: "KE", fname: "Kerry" },
  { sname: "LH", fname: "Longhorn" },
  { sname: "LM", fname: "Limousin" },
  { sname: "LR", fname: "Lincoln Red" },
  { sname: "LU", fname: "Luing" },
  { sname: "LV", fname: "Lakenvelder" },
  { sname: "MK", fname: "Malkekorthorn" },
  { sname: "WW", fname: "Welsh White" },
  { sname: "NO", fname: "Normande" },
  { sname: "PT", fname: "Parthenaise" },
  { sname: "RM", fname: "Romagnola" },
  { sname: "RW", fname: "Undefined Red & White" },
  { sname: "SA", fname: "Salers" },
  { sname: "SD", fname: "South Devon" },
  { sname: "SG", fname: "Swiss Grey" },
  { sname: "SH", fname: "Shorthorn" },
  { sname: "SI", fname: "Simmental" },
  { sname: "SL", fname: "Shetland" },
  { sname: "SP", fname: "Speckle Park" },
  { sname: "TB", fname: "Tyrone Black" },
  { sname: "TT", fname: "Tarataise-Tarina" },
  { sname: "TY", fname: "Tyrone Black" },
  { sname: "VA", fname: "Vaynol" },
  { sname: "VB", fname: "Verbeterd Roodbont Vleesras" },
  { sname: "VN", fname: "Valdostana Nera" },
  { sname: "VO", fname: "Vosgienne" },
  { sname: "WA", fname: "Wagyu" },
  { sname: "WB", fname: "Welsh Black" },
  { sname: "WG", fname: "White Galloway" },
  { sname: "WK", fname: "Welsh Black" },
  { sname: "WP", fname: "White Park" },
  { sname: "YK", fname: "Yak" },
  { sname: "ZE", fname: "Zebu" },
  { sname: "LJ ", fname: "Lithuanian Black And White" },
  { sname: "MA", fname: "Maine Anjou" },
  { sname: "MH", fname: "Marchigiana" },
  { sname: "MO", fname: "Montbeliarde" },
  { sname: "MS", fname: "Milking Shorthorn" },
  { sname: "MY", fname: "Meuse Rhine Issel" },
  { sname: "NR", fname: "Norwegian Red" },
  { sname: "OE", fname: "Old English" },
  { sname: "PI", fname: "Piedmontese" },
  { sname: "PS", fname: "Pustertaler Sprizen" },
  { sname: "PZ", fname: "Pinzgauer" },
  { sname: "RB", fname: "Rotbunte" },
  { sname: "RD", fname: "Danish Red" },
  { sname: "RE", fname: "Reggiana" },
  { sname: "RP", fname: "Red Poll" },
  { sname: "SR", fname: "Swedish Red" },
  { sname: "ST", fname: "Stabiliser" },
  { sname: "SU", fname: "Sussex" },
  { sname: "SX", fname: "Sussex" },
  { sname: "UN", fname: "Unknown" },
  { sname: "VL", fname: "Vaynol" },
];
export const HaulierMatrix = [
  // distance array length 7
  [
    // animalCount array length 11
    [100.0, 100.0, 100.0, 100.0], // averageWeight array length 4
    [50.0, 50.0, 50.0, 50.0],
    [33.0, 33.0, 33.0, 33.0],
    [25.0, 25.0, 25.0, 25.0],
    [20.0, 20.0, 20.0, 25.0],
    [17.0, 17.0, 20.0, 25.0],
    [14.0, 17.0, 20.0, 25.0],
    [14.0, 17.0, 20.0, 25.0],
    [14.0, 17.0, 20.0, 25.0],
    [14.0, 17.0, 20.0, 25.0],
    [14.0, 17.0, 20.0, 25.0],
  ],
  [
    [225.0, 225.0, 225.0, 225.0],
    [112.5, 112.5, 112.5, 112.5],
    [75.0, 75.0, 75.0, 75.0],
    [56.3, 56.3, 56.3, 56.3],
    [45.0, 45.0, 45.0, 56.3],
    [37.5, 37.5, 45.0, 56.3],
    [32.1, 37.5, 45.0, 56.3],
    [32.1, 37.5, 45.0, 56.3],
    [32.1, 37.5, 45.0, 56.3],
    [32.1, 37.5, 45.0, 56.3],
    [32.1, 37.5, 45.0, 56.3],
  ],
  [
    [275.0, 275.0, 275.0, 275.0],
    [137.5, 137.5, 137.5, 137.5],
    [91.7, 91.7, 91.7, 91.7],
    [68.8, 68.8, 68.8, 68.8],
    [55.0, 55.0, 55.0, 68.8],
    [45.8, 45.8, 55.0, 68.8],
    [39.3, 45.8, 55.0, 68.8],
    [39.3, 45.8, 55.0, 68.8],
    [39.3, 45.8, 55.0, 68.8],
    [39.3, 45.8, 55.0, 68.8],
    [39.3, 45.8, 55.0, 68.8],
  ],
  [
    [325.0, 325.0, 325.0, 325.0],
    [162.5, 162.5, 162.5, 162.5],
    [108.3, 108.3, 108.3, 108.3],
    [81.3, 81.3, 81.3, 81.3],
    [65.0, 65.0, 65.0, 81.3],
    [54.2, 54.2, 65.0, 81.3],
    [46.4, 54.2, 65.0, 81.3],
    [46.4, 54.2, 65.0, 81.3],
    [46.4, 54.2, 65.0, 81.3],
    [46.4, 54.2, 65.0, 81.3],
    [46.4, 54.2, 65.0, 81.3],
  ],
  [
    [375.0, 375.0, 375.0, 375.0],
    [187.5, 187.5, 187.5, 187.5],
    [125.0, 125.0, 125.0, 125.0],
    [93.8, 93.8, 93.8, 93.8],
    [75.0, 75.0, 75.0, 93.8],
    [62.5, 62.5, 75.0, 93.8],
    [53.6, 62.5, 75.0, 93.8],
    [53.6, 62.5, 75.0, 93.8],
    [53.6, 62.5, 75.0, 93.8],
    [53.6, 62.5, 75.0, 93.8],
    [53.6, 62.5, 75.0, 93.8],
  ],
  [
    [425.0, 425.0, 425.0, 425.0],
    [212.5, 212.5, 212.5, 212.5],
    [141.7, 141.7, 141.7, 141.7],
    [106.3, 106.3, 106.3, 106.3],
    [85.0, 85.0, 85.0, 106.3],
    [70.8, 70.8, 85.0, 106.3],
    [60.7, 70.8, 85.0, 106.3],
    [60.7, 70.8, 85.0, 106.3],
    [60.7, 70.8, 85.0, 106.3],
    [60.7, 70.8, 85.0, 106.3],
    [60.7, 70.8, 85.0, 106.3],
  ],
];

export const getHaulierPrice = (
  distance,
  animalCount,
  averageWeight,
  type,
  loading
) => {
  if (loading) {
    return type == 0 ? "Loading..." : type == 1 ? 0 : 0;
  }

  console.log(
    "getHaulierPrice=========distance",
    distance,
    "==animalCount",
    animalCount,
    "==averageWeight",
    averageWeight,
    "==loading",
    loading
  );
  if (
    !distance ||
    !animalCount ||
    !averageWeight ||
    animalCount < 0 ||
    averageWeight < 0
  ) {
    return type == 0
      ? "We don't have enough details to estimate haulage cost"
      : type == 1
      ? 0
      : 0;
  }
  var ratePerHead = 0;
  var distanceIndex = 0;
  var countIndex = 0;
  var weightIndex = 0;

  // distance ->  0-100, 100-150, 150-200, 200-250, 250-300, 300-350, 350-400
  if (distance < 0) {
    return type == 0
      ? "We don't have enough details to estimate haulage cost"
      : type == 1
      ? 0
      : 0;
  } else if (0 <= distance && distance < 150) {
    distanceIndex = 0;
  } else if (150 <= distance && distance < 200) {
    distanceIndex = 1;
  } else if (200 <= distance && distance < 250) {
    distanceIndex = 2;
  } else if (250 <= distance && distance < 300) {
    distanceIndex = 3;
  } else if (300 <= distance && distance < 350) {
    distanceIndex = 4;
  } else if (350 <= distance && distance < 400) {
    distanceIndex = 5;
  } else {
    distanceIndex = 5;
  }

  // animalCount => 1,2,3,4,5,6,7,8,9,10,>10
  countIndex = animalCount <= 10 ? animalCount - 1 : 10;

  // averageWeight ->  0-300, 300-450, 450-600, >600
  if (parseFloat(averageWeight) < 300) {
    weightIndex = 0;
  } else if (
    300 <= parseFloat(averageWeight) &&
    parseFloat(averageWeight) < 450
  ) {
    weightIndex = 1;
  } else if (
    450 <= parseFloat(averageWeight) &&
    parseFloat(averageWeight) < 600
  ) {
    weightIndex = 2;
  } else if (600 <= parseFloat(averageWeight)) {
    weightIndex = 3;
  }

  ratePerHead = HaulierMatrix[distanceIndex][countIndex][weightIndex];
  console.log("getHaulierPrice=========result", ratePerHead, animalCount);
  return type == 0
    ? "€" + parseFloat(ratePerHead / 2).toFixed(2) + " per head"
    : type == 1
    ? parseFloat(ratePerHead * animalCount)
    : type == 2
    ? ratePerHead * animalCount
    : parseFloat((ratePerHead * animalCount) / 2);
};
