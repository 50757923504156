import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography'; 
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container'; 
import DBLayout from '../../components/DBLayout'; 
import FavoriteLots from '../../components/FavoriteItems/FavoriteLots'; 
import FavoriteCatalogue from '../../components/FavoriteItems/FavoriteCatalogue'; 
import FavoriteSeller  from '../../components/FavoriteItems/FavoriteSeller'; 



const TabPanel = ({ children, value, index, ...other }) => { 
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `buyer-dashboard-${index}`,
    'aria-controls': `buyer-dashboard-tabpanel-${index}`
  };
}

const Favorites = props => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <DBLayout title="Favourites"> 
      <Container className="db-bids-wrapper mt-4 customTabs pw-box"> 
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="buyer-dashboard" 
        >
          <Tab label="Lots" {...a11yProps(0)} />
          
          <Tab label="Seller" {...a11yProps(1)} /> 
        </Tabs>
        <TabPanel value={value} index={0}> 
          <FavoriteLots />
        </TabPanel>
       
        <TabPanel value={value} index={1}> 
          <FavoriteSeller />
        </TabPanel> 
      </Container>
    </DBLayout>
  );
};

export default Favorites;
