import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuctionCard from "../AuctionCard";
import { parseWithOptions } from "date-fns/fp";
import { request } from "../../Utils/Request";
import { isAuthenticated } from "../../Utils/Authenticated";
import JwtDecode from "jwt-decode";
import { Toast } from "../../Utils/Notifications";

const FavoriteLots = () => {
  const [watch, setWatch] = useState({
    product: [],
  });
  const getFavourite = async (e) => {
    var data = {
      status: "watchlist",
    };
    const res = await request("post", "/buyerDashboard", data, false, true);

    if (res.data.success == "yes") {
      res.data.results.watchlisted = "1";
      setWatch({ ...watch, product: res.data.results });
    }
  };
  const remove = async (e) => {
    const { id } = e.target;
    const res = await request(
      "post",
      "/removeFwatchlist",
      { id: id, userid: JwtDecode(isAuthenticated()).id },
      false,
      true
    );

    // console.log("+++++",res)
    if ((res.data.success = "yes")) {
      Toast.fire({ type: "success", title: "Lot removed from Favorite" });
    } else {
      Toast.fire({ type: "error", title: "Something went wrong" });
    }
    getFavourite();
  };
  useEffect(() => {
    getFavourite();
  }, []);

  return (
    <div className="row">
      {watch.product &&
        watch.product.map((val) => (
          <div className="col-md-4">
            <AuctionCard data={val} onchange={remove} />
          </div>
        ))}
      {watch.product == "" ? (
        <div className="col-12">
          <div className="card card-body text-center">
            No Favourite Lots Found
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FavoriteLots;
