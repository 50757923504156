import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { request } from "../../Utils/Request";
import JwtDecode from "jwt-decode";
import AddressCard from "./AddressCard";
import NewAddress from "./NewAddress";
import { Button, FormHelperText } from "@material-ui/core";
import FFModal from "../Modal";
import CustomInput from "../Inputs/borderedInput";
import PrimaryButton from "../Inputs/PrimaryButton";
import CustomSelect from "../../components/Inputs/borderedSelect";
import { isAuthenticated } from "../../Utils/Authenticated";
import { AirlineSeatIndividualSuiteSharp } from "@material-ui/icons";
import UseForm from "../../Utils/UserForm";
import { Countries, IrishCounties } from "../../Utils/constant";
import { multipleadrees } from "../../Utils/Validators";
import { Toast } from "../../Utils/Notifications";


const SavedAddress = (props) => {
  const [dis, setDis] = useState(false);
  const [dis1, setDis1] = useState(false);
  const [btnloading,setBtnloading]=useState(false)
  const[add,setadd]=useState({
    arr:[]
  })

  const onPop = async (e) => {
    setDis(true);
  };

  const userDetail = async () => {
   
    try {
      const res = await request(
        "post",
        "/getAddressDetails",
        {
          user_id: localStorage.userId,
        },
        false,
        true
      );
      // const userData = JwtDecode(res.data.data);
      // console.log(userData);
      if (res.data.success === "yes") {
           setadd({...add,arr:res.data.data})
      }
      else{
        setadd({...add,arr:[]})
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userDetail();
  }, []);
  
  var {
    handleChange,
    handleSubmit,
    handleDateChange,
    values,
    errors,
    state,
    setValues,
    setState
  } = UseForm(addadrees, multipleadrees);
  
  async function addadrees(){
    setBtnloading(true)
      // setValues({...values,user_id:})
      var data={
        user_id:JwtDecode(isAuthenticated()).id,
        name:values.address1,
        address:values.address2,
        country:values.country,
        state:values.state,
        city:values.city,
        zipcode:values.zipcode,
        address_id:values.id

      }

    if(!dis1)
    {
     const res=await request("post","/addnewaddress",data,false,true)

     if(res.data.success==="yes"){
       console.log("+++++++")
        setDis(!dis)
        Toast.fire({type:"success",title:"New address added successfully"})
        setValues({
           address1:'',
           address2:'',
           country:'',
           state:'',
           city:'',
           zipcode:''
        })
        setState(false)
        userDetail()
     }
    }
    else{
      const res=await request("post","/updateaddress",data,false,true)
      if(res.data.success==="yes"){
         setDis(!dis)
        Toast.fire({type:"success",title:"Address updated successfully"})
        userDetail()

    }
    }
    setBtnloading(false)
  }

  const adddel=async(value)=>{
    const res=await request('post',"/deleteaddress",{address_id:add.arr[value].id},false,true)
    if(res.data.success==="yes"){
        Toast.fire({type:"success",title:"Address deleted successfully"})
        userDetail()

    }
}
const update=(value)=>{
 // const {value}=e.target;
 console.log("+++",value)
 setValues({...values,id:add.arr[value].id,address1:add.arr[value].name,address2:add.arr[value].address1,city:add.arr[value].city,country:add.arr[value].country,state:add.arr[value].state,zipcode:add.arr[value].zipcode})
 setDis1(true)
 onPop()
}

  return (
    <div className="row">
      <div className="col-sm-5">
        <Button onClick={onPop} className="p-0 addBank">
          <NewAddress />
        </Button>
      </div>
      {console.log("++",add.arr)}
      {add.arr && add.arr.map((val,key)=>
      <div className="col-sm-5 form-group">
      <AddressCard
          building={val.name}
          street={val.address1}
          del={adddel}
          edi={update}
          ke={key}
          town={val.city}
          state={val.state}
          eir={val.zibcode}
          country={val.country}
          deliverytype={val.type}
          value="checked"
        />
      
      </div>
      )}
      {/* )} */}
      {/* <div className="col-sm-5">
        <AddressCard
          building="Stuart Write"
          street="Unit L2 Baldonnell Enterprise Park"
          town="Dublin"
          state="Dublin"
          eir="15521"
          country="Ireland"
          deliverytype="Delivery"
        />
      </div> */}
      <FFModal
        open={dis}
        handleClose={(e) => setDis(false)}
        modaltitle="Add New Address"
      >
        <div className="row" style={{fontWeight:'500'}}>
          <div className="col-sm-6 form-group">

            <CustomInput
              label="Address Line 1"
              placeholder="Enter Address Line 1"
              tooltiptitle="Enter Address Line 1"
              name="address1"
              // con={false}
              value={values.address1}
              onchange={handleChange}
              error={state && !values.address1?true:false}
              helperText={state && errors.address1}
            />
          </div>
          <div className="col-sm-6 form-group">
            <CustomInput
              label="Townsland / Address Line 2"
              name="address2"
              value={values.address2}
              onchange={handleChange}
              placeholder="Enter Townsland / Address Line 2"
              tooltiptitle="Enter Townsland / Address Line 2"
              error={state && !values.address2?true:false}
              helperText={state && errors.address2}
            />
          </div>
          <div className="col-12 form-group">
            <CustomInput
              label="Town Name"
              placeholder="Enter Town Name"
              tooltiptitle="Enter Town Name"
              name="city"
              con={false}
              value={values.city}
              onchange={handleChange}
              error={state && !values.city?true:false}
              helperText={state && errors.city}
            />
          </div>
          <div className="col-12 form-group">
          <CustomSelect
                    id="County"
                    label="Select your county"
                    tooltiptitle="County"
                    name="state"
                    
                    value={values.state}
                    pc_onchange={handleChange}
                    selectMenuHeader={"Select your county"}
                    error={state ? !values.state && true : false}
                  >
                    {/* <option key="" value="">
                      Select your county
                    </option> */}
                  
                    {IrishCounties.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.county}
                    </FormHelperText>)}
          </div>
          <div className="col-12 form-group">
          <CustomSelect
                    id="Country"
                    label="Select your country"
                    tooltiptitle="Country"
                    name="country"
                    value={values.country}
                    pc_onchange={handleChange}
                    selectMenuHeader={"Select your country"}
                    error={state ? !values.country && true : false}
                  >
                    {/* <option key="" value="">
                      Select your county
                    </option> */}
                    <option key="Ireland" value="Ireland">
                      Ireland
                    </option>
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.country}
                    </FormHelperText>
                  )}
          </div>
          <div className="col-12 form-group">
                  <CustomInput
                    id="eir-code"
                    label="EIR code"
                    placeholder="Enter EIR code"
                    tooltiptitle="EIR code"
                    name="zipcode"
                    con={values.zipcode.length==8?"dallow":"sn"}
                    value={values.zipcode}
                    onchange={handleChange}
                    error={state ? !values.zipcode && true : false}
                    helperText={state && errors.zipcode}
                  />
                </div>
          <div className="pl-btn pt-4">
            <PrimaryButton dis={btnloading} onsubmit={handleSubmit} label="SAVE" />
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default SavedAddress;
