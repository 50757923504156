import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography'; 
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container'; 
import DBLayout from '../../components/DBLayout'; 
import ActiveBids from '../../components/MyBids/ActiveBids';

import BidsWon from '../../components/MyBids/BidsWon'; 
import BidsLost from '../../components/MyBids/BidsLost'; 
import BuyNow from '../../components/MyBids/BuyNow'; 
import { useHistory } from 'react-router-dom';



const TabPanel = ({ children, value, index, ...other }) => { 

 
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `buyer-dashboard-${index}`,
    'aria-controls': `buyer-dashboard-tabpanel-${index}`
  };
}

const Mypurchases = props => {
  let history=useHistory()
 
  const [value, setValue] = useState(props.history.location.state?props.history.location.state.val:0);

  const handleChange = (event, newValue) => setValue(newValue);


  return (
    <DBLayout title="MY PURCHASES"> 
      <Container className="db-bids-wrapper mt-4 customTabs pw-box"> 
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="buyer-dashboard" 
        >
          <Tab label="Bids Active" {...a11yProps(0)} />
          <Tab label="Bids Won" {...a11yProps(1)} />
          <Tab label="Bids Lost" {...a11yProps(2)} />
          <Tab label="Buy Now" {...a11yProps(3)} /> 
        </Tabs>
        <TabPanel value={value} index={0}> 
          <ActiveBids />
        </TabPanel>
        <TabPanel value={value} index={1}> 
          <BidsWon />
        </TabPanel>
        <TabPanel value={value} index={2}> 
          <BidsLost />
        </TabPanel>
        <TabPanel value={value} index={3}> 
           <BuyNow />
        </TabPanel> 
        
      </Container>
    </DBLayout>
  );
};

export default Mypurchases;
