import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import JwtDecode from "jwt-decode";
import { isAuthenticated } from "../Utils/Authenticated";

const Layout = (props) => {
  return (
    <>
      {!window.location.href.includes("?app_ios_and=1") ? (
        <Header
          haulier={
            isAuthenticated() && JwtDecode(isAuthenticated()).role === "haulier" ? true : false
          }
        />
      ) : (
        ""
      )}
      {props.children}
      {!window.location.href.includes("?app_ios_and=1") ? <Footer /> : ""}
    </>
  );
};

export default Layout;
