import React from "react";
import AddressCard from '../../components/SavedAddress/AddressCard'; 
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import PayCard from './PayCard';
import CardForm from './CardForm'
import { CircularProgress } from '@material-ui/core';
import {CardElement,useStripe,IbanElement} from '@stripe/react-stripe-js';
import './checkout.css'
import FFModal from "../../components/Modal";
import { Button, Tooltip, FormHelperText } from "@material-ui/core";
import CheckBox from "../../components/Inputs/CheckBox";

const PaymentCard = (props) => { 


    const [type, setType] = React.useState("");
    const [modalopen, setModelopen] = React.useState(false);
    const [modalopen2, setModelopen2] = React.useState(false);
    const [name, setName] = React.useState(localStorage.userFirstName + ' ' + localStorage.userLastName);
    const [email, setEmail] = React.useState(localStorage.userEmail);
    const [buynowCheckbox, setBuynowCheckbox] = React.useState(false);
    const [buynowCheckboxErr, setBuynowCheckboxError] = React.useState(false);
    

    const changeName = (e) => {
      const { id, name, value } = e.target;
      setName(value)
  
    }
    const changeEmail = (e) => {
      const { id, name, value } = e.target;
      setEmail(value)
  
    }

    const changeType = (change)  => {

      if (change != ""){
        setType(change)

        if (change == "sepa"){
          props.paymentType(false);
        } else if (change == "card") {
          props.paymentType(true);
        }
      } else {
        if (type == "sepa"){
          setType("card")
          props.paymentType(true);
        } else if (type == "card") {
          setType("sepa")
          props.paymentType(false);
        }
      }
    }

    const toggleModal = ()  => {
      setModelopen(false);
      setModelopen2(false);
    }

    const toggleBuynowConfirm = ()  => {
      if (!buynowCheckbox) {
        setBuynowCheckboxError("Please accept to continue");
      } else {
        setModelopen(false);
        setModelopen2(false);
        setBuynowCheckbox(false);
        setBuynowCheckboxError('');
      }
    }

    const toggleBuynowClose = ()  => {
      setType('')
      setModelopen(false);
      setModelopen2(false);
      setBuynowCheckbox(false);
      setBuynowCheckboxError('');
    }

    const confirmBuynow = () => {
      setBuynowCheckbox(!buynowCheckbox);
    }
    
    const IBAN_STYLE = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      }
    };

    const IBAN_ELEMENT_OPTIONS = {
      supportedCountries: ['SEPA'],
      // Elements can use a placeholder as an example IBAN that reflects
      // the IBAN format of your customer's country. If you know your
      // customer's country, we recommend that you pass it to the Element as the
      // placeholderCountry.
      placeholderCountry: 'IE',
      style: IBAN_STYLE,
    };
     
    return (
	    <div className="card checkout-card"> 
	       <div className="card-body"> 
              <h4 className="cc-title text-center">
                Payment Details
              </h4> 

              {(type == "sepa") ?
              // <CardForm recall={e=>{props.acall(e);newCard(e)}} /> 
              <>
                <div className="form-group mt-4">
                 
                </div>
                {props.loading ? <CircularProgress /> : <PrimaryButton onsubmit={(e) => {props.sub2(name, email)}} type="submit" label="BANK CHECKOUT"/> }
                <div className="use-diff">
                    <a onClick={(e) =>{setType('')}}><span className="material-icons">account_balance</span> Use a different mode of payment</a>
                  </div>
                </>
              : (type == "card") ?
                <>
                {/* {props.card.map((data,key)=>
                  <div className="form-group">
                    <PayCard 
                      cardholder={data.name}
                      cardnumber={"**** **** **** "+data.last4}
                      valid={data.exp_month+"/"+data.exp_year} 
                      val={key}
                      value={props.checking}
                      onCall={e=>props.firstcall(e)}
                    />
                  </div>
                )} */}
            <div className="form-group mt-4">
                  <CardElement
                    options={{
                        style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                            color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                        },
                        hidePostalCode:true
                    }}
                    />
                   </div>
                 {props.loading ? <CircularProgress /> : <PrimaryButton onsubmit={props.sub} label="MAKE CARD PAYMENT"/> }
                  <div className="use-diff">
                    <a onClick={(e) =>{setType('')}}><span className="material-icons">account_balance</span> Use a different mode of payment</a>
                  </div>
                </>

              :
                <>
                {props.loading2 ? '' : <PrimaryButton onsubmit={(e) =>{
                  changeType('sepa');
                  
                  if (props.payType && props.payType == 'auction'){
                  } else {
                    setModelopen2(true)
                  }
                  }} label="Pay By Bank Transfer"/>}
                <br></br>
                {/* {props.loading2 ? '' : <PrimaryButton onsubmit={(e) =>{setModelopen(true)}} label="Pay By Card"/>} */}
                </>
              }
              
	       </div>

         <FFModal
          open={modalopen}
          handleClose={toggleModal}
          modaltitle="Message"
          noHeader={1}
        >
          <div style={{fontWeight:'500'}}>
            <p className="card-text">
            The preferred, and most cost-effective, payment method is via Bank Transfer (SEPA).
            </p>
            <p className="card-text">
            If you choose to pay by card, you'll be responsible for any additional card service fees, which could be up to 3%.Do you agree to cover these fees if applicable?
            </p>

            <div className="modalCTA d-flex justify-content-end align-items-center">
              <Button onClick={toggleModal}>Cancel</Button>

              <PrimaryButton label={"I agree"} onsubmit={(e) => {
                toggleModal()
                changeType('card')
                }} />
            </div>
          </div>
        </FFModal>
          <FFModal
          open={modalopen2}
          handleClose={toggleBuynowClose}
          modaltitle="IMPORTANT MESSAGE"
        >
          <div className="seller-note" style={{ fontWeight: "700" }}>

<br></br>
              <p style={{ fontSize: "16px" }}>
              CLICKING THROUGH TO "BANK CHECKOUT" BUYS THIS LOT
              </p>
              <p style={{ fontSize: "16px" }}>
              By clicking on "BANK CHECKOUT" you are AGREEING TO PURCHASE THIS LOT, and are CONTRACTUALLY OBLIGED TO COMPLETE TRANSFER PAYMENT WITHIN 48 HOURS
              </p>
              <p style={{ fontSize: "16px" }}>
              Do you agree?
              </p>

            <div className="jc-center">
              <CheckBox
                onchange={confirmBuynow}
                value={buynowCheckbox}
                label="I confirm I shall transfer these funds within 48 hours"
              />
            </div>

            <div className="pl-btns">
              <FormHelperText error>
                {buynowCheckboxErr ? buynowCheckboxErr : ""}
              </FormHelperText>

              <PrimaryButton
                  label={"Continue"}
                  btnSize={"smallsize"}
                  onsubmit={toggleBuynowConfirm}
                  disable={!buynowCheckbox}
              />
            </div>
          </div>
        </FFModal>
          
	    </div>



    );
}

export default PaymentCard