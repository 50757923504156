import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Tooltip from "@material-ui/core/Tooltip";

export default function DatePicker(props) {


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Tooltip title={props.tooltiptitle} arrow placement="right" disableHoverListener={true} disableFocusListener={props.disableTooltip}>
        {props.format?
        <KeyboardDateTimePicker
        // <KeyboardDatePicker
        /*   disableToolbar */
          /* variant="inline" */
          format={props.format?props.format:"dd/MM/yyyy"}
          margin="normal"
          // id={props.id}
          label={props.label}
          value={props.value}
          InputProps={{disabled:true}}
          disableFuture= {props.disableFuture}
          name="singleitemlot_dob"
          inputVariant="outlined"
          fullWidth
         
          // timeIcon={false}
         /*  onChange={props.onchange} */
          onChange={(evt)=>props.onchange(evt,props.name,props.id)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...props}
        />:<KeyboardDatePicker
        format={props.format?props.format:"dd/MM/yyyy"}
          margin="normal"
          id={props.id}
          label={props.label}
          value={props.value}
           minDate={props.min}
           maxDate={props.max}
          disableFuture= {props.disableFuture}
          // readOnly={"true"}
          name="singleitemlot_dob"
          inputVariant="outlined"
          fullWidth
          // timeIcon={false}
         /*  onChange={props.onchange} */
          onChange={(evt)=>props.onchange(evt,props.name,props.id)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            
          }}
        InputProps={{disabled:true}}
          
          {...props}
        />}
            </Tooltip>

    </MuiPickersUtilsProvider>
  );
}
