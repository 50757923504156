import { ADD_TOKEN, CHANGE_USER, ADD_TIMER } from "../types"

export function addLoginToken(payload){
    return {
        type: ADD_TOKEN,
        payload
    }
}
export function changeUserData(payload){
    return {
        type: CHANGE_USER,
        payload
    }
}
export function addTimer(payload){
    return {
        type: ADD_TIMER,
        payload
    }
}
