import React from "react";
import "./AuctionCard.css";
import FavoriteCheckbox from "../FavouriteCheck/FavouriteCheckbox";
import Timer from "../../Utils/Timer"

const CatlougeCard =  (props) => {
  return (
    <div className="auctionCard">
      <div className="acImg"> 
        <FavoriteCheckbox favIdChild="id1" watchlisted={props.watchlist} />
        <img src={props.image}></img>
        <span className="no-items"> 11 Items</span>
      </div>
      <div className="acTimer d-flex justify-content-center align-items-center">
        <span class="material-icons">timer</span>
        <Timer date_added={props.date_added} date_closed={props.date_closed} withText={1}></Timer>
      </div>
      <div className="acTitle">
        <h1 className="playFair">{props.title}</h1>
        <p>Sale number: #12121</p>
      </div> 
      <p className="cc-descp brother-regular">
        {props.description}
      </p> 
      <div className="acPrice d-flex justify-content-between align-items-center">
        <p>Bid <span className="brother-regular">€ {props.sprice}</span></p>
        <p>Avg weight 200Kgs</p> 
      </div>

      <div className="acMisc d-flex justify-content-between align-items-center">
        <p>
          <span class="material-icons">location_on</span> Leinster, Ireland
        </p>
        <p>
          <span class="material-icons">star_half</span> 4.5
        </p>
      </div>
    </div>
  );
}


export default  CatlougeCard