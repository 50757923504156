import React, { useEffect, useState } from "react";
import CatlougeFilters from "../../components/CatlougeFilters";
import Layout from "../../components/Layout";
import GridView from "./GridView";
import ListView from "./ListView";
import CustomDropdown from "../../components/Inputs/CustomDropdown"; 
import "../Search/Search.css";
import { useHistory } from "react-router-dom";
import { request } from "../../Utils/Request";
import SearchFilters from "../../components/SearchFilters";

const Catlouge = (props) => {
  let history=useHistory()
  const [gridView, setGridView] = useState(true);
  const [state,setState]=useState({
     location:props.history.location.state.loc 
  })
  const[product,setProduct]=useState([]);
  const [filter, setFilter] = useState(false);

  const toggleView = () => setGridView(!gridView);
  const toggleFilter = () => setFilter(!filter);

  // const getProductdetails=async()=>{
  //   const res=await request('post','/mobilesearch',state, false, true);
  //      setProduct(res.data.results)
  // }
  
  // useEffect(()=>{
  //        getProductdetails()
  // },[])
  const SearchResults = (data)=>{
    setProduct(
      data
    )
  }
  return (
    <Layout>
      <div className="ffSearch catlouge-wrapper">
        <div className="clearfix catlouge-banner">
            <img src="/images/catlouge-back.png" alt="" className="catlouge-banner-img" />
            <div className="catlouge-head clearfix">
              Connacht Sale
            </div>
        </div>
        <div className="container">
          <h2 className="catlouge-head1">SALE TITLE</h2>
          <p className="catlouge-descp">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
          </p>
          <div className="catlouge-label brother-regular">
           <span className="material-icons">event</span> 20th July 2020, Thursday
          </div>
          <div className="catlouge-label brother-regular">
           <span className="material-icons">timer</span> 10 AM IST to 08 PM IST
          </div>
          <div className="catlouge-label brother-regular green-txt">
            <span className="material-icons">notifications_active</span> Set alerts
          </div>

        <a className="search-filter-link" onClick={toggleFilter}>Filter By</a>
        {filter && <a className="sclose-filter" onClick={toggleFilter}><i className="fa fa-times"></i></a> }
        <div className={`searchOptions search-filter-wrapper ${filter ? "show-filter-xs" : "hide-filter-xs"}`}>
          <SearchFilters SearchResults = {SearchResults}/>
        </div>
          
        </div>
        <div className="searchResults container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="searchTitle d-flex justify-content-start align-items-center"> 
              <span className="pl0">{product.results?"Showing "+product.results.length+" results":""}</span>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <div
                className={`${gridView
                    ? "searchOptChildren active"
                    : "searchOptChildren"
                }`}
                onClick={toggleView}
              >
                <span class="material-icons">apps</span>
              </div>
              <div
                className={`${gridView
                    ? "searchOptChildren"
                    : "searchOptChildren active"
                }`}
                onClick={toggleView}
              >
                <span class="material-icons">view_list</span>
              </div>
              <div>
                <CustomDropdown label="Results per page">
                  <li>
                    <a href="#">10</a>
                  </li>
                  <li>
                    <a href="#">25</a>
                  </li>
                  <li>
                    <a href="#">45</a>
                  </li>
                </CustomDropdown>
              </div>
              <div>
                <CustomDropdown label="Sort by">
                  <li>
                    <a href="#">Newest First</a>
                  </li>
                  <li>
                    <a href="#">Price- High to Low</a>
                  </li>
                  <li>
                    <a href="#">Price- Low to High</a>
                  </li>
                  <li>
                    <a href="#">Ending first</a>
                  </li>
                  <li>
                    <a href="#">Ending last</a>
                  </li>
                </CustomDropdown>
              </div>
            </div>
          </div>
          {gridView ? <GridView productdet={product} /> : <ListView productdet={product} />}
        </div>
      </div>
    </Layout>
  );
};

export default Catlouge;
