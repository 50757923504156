import React, { useEffect, useState } from "react";
import { AvatarURL } from "../../Utils/constant";
import SellerFavourite from "../FavouriteCheck/SellerFavourite";
import { request } from "../../Utils/Request";
import { Toast } from "../../Utils/Notifications";
import Rating from "@material-ui/lab/Rating";
import moment from "moment";
import { useHistory } from "react-router-dom";

const SellerProfileTop = (props) => {

  const [profData, setProfdata] = useState([]);
  const [disabled_loc,setDisloc]=useState(false)
  const history = useHistory()
  const addSeller = async (e) => {
    if(!localStorage.usertoken){
      Toast.fire({ type: "error", title: 'Please login and continue' });
      history.push(`/login`);
      return false;
    }
    if (e.target.checked) {
      const res = await request("post", "/addsellerwatchlist", {
        user_id: localStorage.userId,
        seller_id: props.profdata.id,
      });
      if (res.data.success === "yes") {
        setDisloc(false)
        setProfdata({...profData,sell_fav:1})
        Toast.fire({ type: "success", title: "Seller added to favourite" });
      }
    } else {
      const res = await request("post", "/removesellerwatchlist", {
        user_id: localStorage.userId,
        seller_id: props.profdata.id,
      });
      if (res.data.success === "yes") {
        setDisloc(false)
        setProfdata({...profData,sell_fav:0})
        Toast.fire({ type: "success", title: "Seller removed from favourite" });
      }
    }
  };

  const getSellerRatings = () => {
    var ratingSum = 0;
    props.sellerFeedback.forEach((feedback) => {
      ratingSum += feedback.rating;
    });

    return ratingSum / props.sellerFeedback.length;
  };


  const getprofData = async (id) => {
    try {
      const res = await request(
        "post",
        "/getUserProfileDetails",
        {
          user_id: id,
        },
        false,
        true
      );
      //console.log("kkkkkkk", res);
      if (res.data.success === "yes") {
        var user_send_data={
          ...res.data.data,...{sell_fav:res.data.sell_fav,soldcount:res.data.sold_count}
        }
        //setProfdata({...res.data.data},...{sell_fav:res.data.favourite});
        setProfdata(user_send_data)
        //console.log("UserProfileDetails", res.data.data);
        //seDis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
      getprofData(props.profdata.id)
  },[])


  return (
    <div className="sprofile-box">
      <div className="sp-img-wrap">
        <SellerFavourite disabled={disabled_loc} setDisabled={setDisloc} sellerfav={profData.sell_fav} onCall={(e) => addSeller(e)} />
        <div>
          <img
            src={
              props.profdata.avatar
                ? AvatarURL + props.profdata.avatar
                : "/images/user-img.svg"
            }
            alt=""
          />
        </div>
        <div className="sp-user-name">{props.profdata.username}</div>
      </div>
      {/* <div className="row">
        <div className="col-6 col-md-3">
          <div className="sp-head-lbl">Member Since:</div>
          <div className="sp-descp">
            {props.profdata.created_at
              ? moment(props.profdata.created_at).format("MMM DD, YYYY")
              : "Pending..."}
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="sp-head-lbl">Lots Sold:</div>
            <div className="sp-descp">{profData.soldcount}</div>
        </div>
        <div className="col-6 col-md-4 col-lg-3">
          <div className="sp-head-lbl">Rating:</div>
          <div className="light-red">
            <Rating
              name="simple-controlled"
              value={getSellerRatings()}
              readOnly={true}
            />
             <i className="fas fa-star-half-alt"></i>
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-3">
          <div className="sp-head-lbl">Reviews:</div>
          <div className="sp-descp">{props.sellerFeedback.length}</div>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default SellerProfileTop;
