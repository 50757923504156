import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FSCard from "../FSCard";
import { request } from "../../Utils/Request";
import { isAuthenticated } from "../../Utils/Authenticated";
import JwtDecode from "jwt-decode";
import { Toast } from "../../Utils/Notifications";

const FavoriteSeller = () => {
  const [data, setData] = useState([]);

  const getDetails = async (e) => {
    const res = await request("post", "/getsellerwatchlist", {
      user_id: localStorage.userId,
    });
    if (res.data.success) {
      setData(res.data.data);
    }
  };

  const remove = async (e) => {
    const { id } = e.target;
    const res = await request(
      "post",
      "/removesellerwatchlist",
      { seller_id: id, user_id: JwtDecode(isAuthenticated()).id },
      false,
      true
    );
    if ((res.data.success = "yes")) {
      Toast.fire({ type: "success", title: "Seller removed from Favorite" });
    } else {
      Toast.fire({ type: "error", title: "Something went wrong" });
    }
    getDetails();
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="row">
      {data &&
        data.map((val) => (
          <div className="col-md-4">
            <FSCard fav={val} watchlist="1" onchange={remove} />
          </div>
        ))}
      {data.length == 0 ? (
        <div className="col-12">
          <div className="card card-body text-center">
            No Favourite Seller Found
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FavoriteSeller;
