import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { NumberOnly, TextOnly, specialcharacter, HerdNum, dallow } from "../../../Utils/constant";
import { Phone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: '3em',
      width: "100%",
    },
    "& .MuiInput-underline": {
      paddingBottom: "1em",
    },
    "& label.Mui-focused": {
      color: "#21285D",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--primColor)",
    },
    input: {
      fontFamily: 'Brother-1816-Light',
    },
  },
}));

function InputField(props) {
  console.log("++++++++++++++++",props.con)
  const classes = useStyles();
  return (
    <form className={classes.root} noValidate autoComplete="off">
     
      <TextField
        id={props.id}
        disabled={props.disabled}
        label={props.label}
        placeholder={props.placeholder}
        type={props.type}
        InputProps={{inputProps:{onKeyPress:props.con==true?NumberOnly:props.con==false?TextOnly:props.con=="sn"?specialcharacter:props.con=="dallow"?dallow:props.con=="phone"?Phone:props.con=="hd"?HerdNum:""}}}
        multiline={props.multiline}
        error = {props.error}
        name= {props.name}
        value = {props.value}
        onChange= {props.onchange}
        helperText={props.helperText}
        onKeyPress={props.onKeyPress}
      />
    </form>
  );
}

export default InputField;
