import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography'; 
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Layout from "../../components/Layout"; 
import { useHistory } from 'react-router-dom'; 
import SellerProfileTop from '../../components/SellerProfileTop';
import CustomDropdown from "../../components/Inputs/CustomDropdown"; 
import GridView from "./GridView";
import './sellerprofile.css'
import { request } from '../../Utils/Request';
import Rating from '@material-ui/lab/Rating';
import { AvatarURL } from '../../Utils/constant';
import { Avatar } from '@material-ui/core';
import Reviews from "../../components/Reviews";



const TabPanel = ({ children, value, index, ...other }) => { 
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `buyer-dashboard-${index}`,
    'aria-controls': `buyer-dashboard-tabpanel-${index}`
  };
}

const SellerProfile = props => {
  let history=useHistory()
  const [search,setSearch]=useState([])
  const [value, setValue] = useState(0);
  const [command,setCommand] = useState([])
  const handleChange = (event, newValue) => setValue(newValue);

const getProduct=async()=>{
   
  const res=await request("post","/mobilesearch",{})
   var arr=[];
  if(res.data.success === "yes"){
  res.data.results.map((val)=>{
       if(val.user_id === props.location.state.profData.id){
          arr.push(val)
       }  
  })}
  setSearch(arr)
  

}

const getCommand=async()=>{
    const res=await request("post","/getSellerFeedbacks",{seller_id:props.location.state.profData.id})
    if(res.data.status === "yes"){
      var feedback = res.data.data.filter((item)=>{
        return ((item.subject != 'review_1' && item.subject != 'review_2' && item.subject != 'review_3' && item.subject != 'review_4' && item.subject != 'review_5'))
      })
      setCommand(feedback)
    }
  }
  
useEffect(()=>{
        console.log("mmmkkkk",props)
       getProduct()
       getCommand()
  },[])

  return (
    <Layout>
      <Container className="db-bids-wrapper mt-4 customTabs seller-profile-wrapper"> 
          <SellerProfileTop profdata={props.location.state.profData} sellerFeedback={command !== undefined && command}/>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="buyer-dashboard" 
            className="pt-5"
          >

            <Tab label="Active Lots" {...a11yProps(0)} />
            <Tab label="Reviews" {...a11yProps(1)} /> 
          </Tabs>
          <TabPanel value={value} index={0}> 
             <div className="text-right sort-by-sp">
                <div className="sort-by mt-2 mb-2">
                  <label>Sort By</label>
                  <select>
                    <option>Newest first</option>
                  </select>
                </div>
              </div>
              {console.log("++++++++++++++++++++++++++",search)}
             <GridView activeLots={search} />
          </TabPanel>
          <TabPanel value={value} index={1}>  
              <div className="text-right sort-by-sp">
                <div className="sort-by mt-2 mb-2">
                  <label>Sort By</label>
                  <select>
                    <option>Newest first</option>
                  </select>
                </div>
              </div>

              <div className="reviews-wrapper">

                {command && command.map((val)=>
                <Reviews review={val} />
                )}
              </div>
          </TabPanel> 
      </Container>
    </Layout>
  );
};

export default SellerProfile;
