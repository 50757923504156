import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../components/Inputs/borderedInput";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { GreenRadio } from "../../components/Inputs/RadioBox";
import CheckBox from "../../components/Inputs/CheckBox";
import Radio from "@material-ui/core/Radio";
import { Player } from "video-react";
import {
  Multiple,
  ValidateMultipleLot,
  ValidatePostSingleLot,
} from "../../Utils/Validators";
import imageCompression from "browser-image-compression";
import {
  Breed,
  IrishCounties,
  Section,
  Type,
  weimethod,
  salemethod,
  NewCounties,
  NumberOnly,
  callNumber,
  dallow,
  Calf_Type,
  Sex,
  number_of_animals,
  Quality_asurance,
  Owners,
  BreedICBF,
  Crossbreed,
  TextOnly,
} from "../../Utils/constant";
import moment from "moment";
import UseForm from "../../Utils/UserForm";
import { request } from "../../Utils/Request";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./post-project.css";
import Upload from "../../components/Upload";
import CustomSelect from "../../components/Inputs/borderedSelect";
import Layout from "../../components/Layout";
import { Button, FormHelperText, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CustomDatePicker from "../../Utils/DatePicker";
import JwtDecode from "jwt-decode";
import { Toast } from "../../Utils/Notifications";
import AWS from "aws-sdk";
import FFModal from "../../components/Modal";
import { IMAGE_URL } from "../../Utils/constant";
import SearchOptions from "../../components/Inputs/SearchOptions";
import DatePicker, {
  DateObject,
  getAllDatesInRange,
} from "react-multi-date-picker";
const MultipleItemsStep1 = (props) => {
  const [modalopennew, setModalopennew] = useState(false);
  const [modalopen2, setModalopen2] = useState(false);
  const [modalopen3, setModalopen3] = useState(false);
  const [modalopenOther, setModalopenOther] = useState(false);
  const [modalopenCB, setModalopenCB] = useState(false);
  const [modalopenCB1, setModalopenCB1] = useState(false);
  let history = useHistory();
  const [tag_number, setTagnumber] = useState([1, 2, 3, 4]);
  const [tag_number_pro, setTagnumberPro] = useState([]);
  const [icbf_data, setICBFData] = useState([]);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET,
    region: process.env.REACT_APP_S3_REGION,
  });

  var s3 = new AWS.S3();

  const Nextpage = async (v = 0) => {
    // if (props.history.location.state.page2) {
    //   var data={
    //     page2:props.history.location.state.page2
    //   }
    //   console.log("=======================",data)
    //  return props.history.push({ pathname: "/multiple-items-step2", state:data});

    // }
    // console.log("values -------- ",values)
    if (
      values?.multiItems_first_dob > values?.multiItems_first_cardissued ||
      values?.multiItems_first_dob > values?.multiItems_first_old_date
    ) {
      Toast.fire({
        type: "warning",
        title: "Oldest DOB must be before Oldest Test date and Old Movement!",
      });
      return false;
    }
    if (
      new Date(values?.multiItems_first_dob) >
        new Date(values?.multiItems_first_cardissued) &&
      values?.multiItems_first_movement > 0
    ) {
      Toast.fire({
        type: "warning",
        title: "Oldest movement dates cannot be older than the Oldest DOB!",
      });
      return false;
    }
    // if (values?.multiItems_dob_range.length !== 2) {
    //   Toast.fire({
    //     type: "warning",
    //     title: "Please Select DOB Range!!",
    //   });
    //   return false;
    // }
    // if (
    //   values.multiItems_first_movement > 0 &&
    //   values?.multiItems_movement_range.length !== 2
    // ) {
    //   Toast.fire({
    //     type: "warning",
    //     title: "Please Select Movement Range!!",
    //   });
    //   return false;
    // }

    if (
      !values.multiItems_auction_checked &&
      !values.multiItems_buynow_checked
    ) {
      Toast.fire({
        type: "warning",
        title: "Please Select Sale or Buy Now",
      });
      return false;
    }
    if (values.multiItems_icbf == "yes") {
      if (tag_number_pro.length < 4) {
        Toast.fire({
          type: "warning",
          title: "Please Enter Minimum 4 Tag Number!",
        });
        return false;
      } else {
        if (v == 0) {
          setModalopennew(true);
          return false;
        }
      }
    }

    if (
      NewCounties.toString().includes(
        values.multiItems_county
          ? values.multiItems_county
          : values.present_county
      )
    ) {
      let location = values.multiItems_county
        ? values.multiItems_county
        : values.present_county;

      const res = await request(
        "post",
        "/getAuctionDetails",
        {
          state: location,
        },
        false,
        true
      );

      if (res.data.success) {
        //setValues({ ...values, auctionlot_id: res.data.data.id});
      } else {
        Toast.fire({
          type: "warning",
          title:
            "Unfortunately you have missed the deadline for listing in this weeks sale. Don't worry " +
            location +
            " sale room will reopen for listings for the next sale. Please list your Lot then. Thank you",
        });
        return false;
      }
      values.icbf_number = tag_number_pro;

      var data = {
        val: values,
        ite:
          props.history.location.state !== undefined
            ? props.history.location.state.arr
            : "",
        page2:
          props.history.location.state !== undefined
            ? props.history.location.state.page2
            : "",
        icbf_data,
      };

      props.history.push({ pathname: "/multiple-items-step2", state: data });
    } else {
      Toast.fire({
        type: "warning",
        title:
          "Initially, we are only launching in South East, so please hold off for a little while",
      });
    }
  };

  const onProChange = (index, val) => {
    console.log(
      "values.tag_number_pro",
      tag_number_pro,
      localStorage.getItem("icbfAccessToken")
    );

    if (!localStorage.getItem("icbfAccessToken")) {
      setTagnumberPro([]);
      setValues({ ...values, multiItems_icbf: "no" });
      geticbfcode();
    } else {
      var tag_number_pr = tag_number_pro;
      tag_number_pr[index] = val;
      setTagnumberPro([...tag_number_pr]);

      geticbfdata(index, val);
    }
  };

  const removeImage = (e) => {
    if (e === "videos") {
      setValues({ ...values, product_videos: "" });
    } else {
      values.product_image.splice(values.product_image.indexOf(e), 1);
      setValues({ ...values });
    }
  };

  const uploadfile = (params) => {
    console.log("uploadfile ", params);
    return s3.upload(params, function (err, data) {
      var progressDiv = document.getElementById("myProgressDiv");
      if (progressDiv) {
        progressDiv.style.display = "none";
        var progressBar = document.getElementById("myBar");
        progressBar.style.width = "1%";
      }
      if (err) {
        console.log("There was an error uploading your file: ", err);
        removeImage("videos");
        Toast.fire({
          type: "warning",
          title: "Upload failed! Please try again.",
        });
        return false;
      }
      console.log("Successfully uploaded file.", data);
      return true;
    });
  };

  const breedchage = (e) => {
    setValues({ ...values, collection_breed: e.target.value });
  };

  const callcommand = () => {
    Breed.push(values.collection_breed);
    setValues({
      ...values,
      multiItems_first_breed: [values.collection_breed],
      collection_breed: "",
    });

    setModalopenOther(!modalopenOther);
  };
  const callcommand1 = () => {
    if (!values.collection_breed) {
      return setValues({ ...values, bre: "Please sellect Breed # 1" });
    }

    setValues({
      ...values,
      join_breed: values.collection_breed,
      collection_breed: "",
      bre: "",
    });
    setModalopenCB(!modalopenCB);
    setModalopenCB1(!modalopenCB1);
  };
  const finalsubmit = () => {
    if (!values.collection_breed) {
      return setValues({ ...values, bre: "Please sellect Breed # 2" });
    }
    Breed.push(values.join_breed + "," + values.collection_breed);
    setValues({
      ...values,
      multiItems_first_breed: [
        values.join_breed + "," + values.collection_breed,
      ],
      collection_breed: "",
      bre: "",
    });

    setModalopenCB1(!modalopenCB1);
  };
  console.log("values -", values);
  const onClickVideo = (e) => {
    console.log("==================", e.target.files[0]);
    let ext = "";
    if (e.target.files[0].name.indexOf(".") > 0) {
      ext = "." + e.target.files[0].name.split(".").slice(-1)[0];
    }
    let file_name = localStorage.userId + "_" + new Date().getTime() + ext;

    const params = {
      ACL: "public-read",
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: "farmfayre/" + file_name,
      Body: e.target.files[0],
    };

    e.target.files[0].s3_name = file_name;
    setValues({ ...values, product_videos: e.target.files[0] });

    var progressDiv = document.getElementById("myProgressDiv");
    progressDiv.style.display = "block";
    var progressBar = document.getElementById("myBar");

    let fileUpload = {
      id: "",
      name: file_name,
      nameUpload: file_name,
      size: e.target.files[0].size,
      type: "",
      timeReference: "Unknown",
      progressStatus: 0,
      displayName: e.target.files[0].name,
      status: "Uploading..",
    };
    uploadfile(params).on("httpUploadProgress", function (progress) {
      let progressPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      console.log(progressPercentage);
      progressBar.style.width = progressPercentage + "%";
      if (progressPercentage < 100) {
        fileUpload.progressStatus = progressPercentage;
      } else if (progressPercentage == 100) {
        fileUpload.progressStatus = progressPercentage;

        fileUpload.status = "Uploaded";
      }
    });
  };

  var {
    handleChange,
    handleSubmit,
    handleDateChange,
    values,
    errors,
    state,
    setValues,
    setState,
    setErrors,
  } = UseForm(Nextpage, Multiple);

  console.log("mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm", errors);

  // var {
  //   handleChange1,
  //   handleSubmit1,
  //   handleDateChange1,
  //   values1,
  //   errors1,
  //   state1,
  //   setValues1,
  // } = UseForm(Nextpage1, Multiple1);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage =
        "Are you sure you want to leave this page? All unsaved data will be lost";
      event.preventDefault();
      event.return = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (values.multiItems_pickup_location === "present_address") {
      let err_new = errors;
      delete err_new.multiItems_bldgname;
      delete err_new.multiItems_stname;
      delete err_new.multiItems_town;
      delete err_new.multiItems_county;
      delete err_new.multiItems_eircode;
      delete err_new.multiItems_country;
      setErrors(err_new);
      setState(false);
    }
  }, [values.multiItems_pickup_location]);

  useEffect(() => {
    if (
      !localStorage.getItem("trustapIDToken") &&
      localStorage.getItem("trustapID") != ""
    ) {
      window.location.href = "/trustap-redirect-new";
    } else {
      if (localStorage.getItem("trustapID") != "") {
      // } else if (localStorage.getItem("trustapIDToken") != "") {
      //   window.location.href = "/trustap-redirect-new";
      } else {
        window.location.href = "/trustap-redirect";
      }
    }
    const UserProfile = async () => {
      try {
        const res = await request(
          "post",
          "/getUserProfileDetails",
          {
            user_id: localStorage.userId,
          },
          false,
          true
        );
        console.log(res);

        if (res.data.success === "yes") {
          setValues({
            ...values,
            present_bldgname: res.data.data.address1,
            present_stname: res.data.data.address2,
            present_town: res.data.data.city,
            present_country: res.data.data.country,
            present_county: res.data.data.state,
            present_eircode: res.data.data.zip,
            multiIteme_pickup_location: "present_address",
            multiItems_truck_turn: "yes",
            multiItems_resprice_checked: "public",
          });
          // if (parseInt(res.data.data.terms_check) == 0) {
          //      setModalopennew(true)
          // }
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };
    UserProfile();

    if (props.history.location.state) {
      const res = props.history.location.state.values;
      console.log("++++++++++++++", res);
      values = res;
      setValues({ ...res });
      setState(false);
    }
  }, []);

  useEffect(() => {
    console.log(
      "values.multiItems_icbf",
      values.multiItems_icbf,
      localStorage.getItem("icbfAccessToken")
    );
    if (
      values.multiItems_icbf == "yes" &&
      !localStorage.getItem("icbfAccessToken")
    ) {
      setValues({ ...values, multiItems_icbf: "no" });
      geticbfcode();
    }
  }, [values.multiItems_icbf]);

  const geticbfcode = async () => {
    const res = await request("post", "/codetobackend", {
      type: "icbf",
      userid: localStorage.userId,
      refresh_token: true,
    });
    console.log("values.multiItems_icbf", values.multiItems_icbf);
    if (res.data.success) {
      localStorage.setItem("icbfAccessToken", res.data.code);
    } else {
      setModalopen3(true);
    }
  };

  const geticbfcode2 = () => {
    setModalopen3(false);
    var redirect_uri = process.env.REACT_APP_FRONT_URL + "/icbf/receivecode/";
    var oauth_url =
      "https://api.icbf.com/oauth/authorize?response_type=code&client_id=farmfayre_client&redirect_uri=" +
      redirect_uri +
      "&state=1";
    window.open(oauth_url, "_blank");
    // for test only, remove when push to live
    window.open(
      process.env.REACT_APP_FRONT_URL + "/icbf/receivecode/?code=123",
      "_blank"
    );
  };

  const geticbfdata = async (index, tag_no) => {
    var icbf = icbf_data;
    if (tag_no.length > 10) {
      const res = await request("post", "/icbf_api", {
        tag_number: tag_no,
        userid: localStorage.userId,
        access_token: localStorage.getItem("icbfAccessToken"),
      });
      if (res.data.success) {
        icbf[index] = res.data.data;
      } else {
        Toast.fire({
          type: "warning",
          title: res.data.msg || "No Data found for this Tag Number.",
        });
        icbf[index] = {};
      }
      console.log("icbf_data", icbf);
    } else {
      icbf[index] = {};
    }
    setICBFData([...icbf]);
  };

  const accept_terms = async (val) => {
    const res = await request(
      "post",
      "/terms_check_update_farm",
      {
        user_id: localStorage.userId,
        term_val: val == "yes" ? 1 : 2,
      },
      false,
      true
    );
    if (res.data.success) {
      // setModalopennew(false)
      Toast.fire({
        type: "success",
        title: "Terms and condition accepted.",
      });
    }
  };
  const handleChange1 = () => {
    console.log(" came to handle change");
    setValues({
      ...values,
      FLterms: true,
    });

    setModalopen2(false);
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;

    if (
      value.some((item) => item === "Other") &&
      name === "multiItems_first_breed"
    ) {
      return toggleModalOther();
    }
    if (
      value.some((item) => item === "Crossbreed") &&
      name === "multiItems_first_breed"
    ) {
      return toggleModalCB();
    }

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleModalOther = () => {
    setModalopenOther(!modalopenOther);
  };
  const toggleModalCB = () => {
    setModalopenCB(!modalopenCB);
  };
  const toggleModalCB1 = () => {
    setModalopenCB(!modalopenCB1);
  };

  useEffect(() => {
    const assuranceValue = values.multiItems_assurance.find(
      (item) => item.name === "FeedLot Status"
    );
    if (assuranceValue && values.FLterms == false) {
      setModalopen2(true);
    }
  }, [values.multiItems_assurance]);

  const add_item_to = () => {
    if (tag_number.length > 0) {
      var last_item = tag_number[tag_number.length - 1],
        tag_number_pro = tag_number;
      tag_number_pro.push(parseInt(last_item) + 1);
      setTagnumber([...tag_number_pro]);
    }
  };

  //console.log("+++++++++++==", errors);
  // const onClickImages = (e) => {
  //   Object.keys(e.target.files).map((value, key) => {
  //     console.log(e.target.files[key]);
  //     // setValues({
  //     //   ...values,
  //     //   product_image: [...values.product_image, e.target.files[key]],
  //     // });
  //     setValues(prevState => ({ ...prevState, product_image: [...prevState.product_image, e.target.files[key]]}));
  //   });
  //   console.log(e.target.files);
  // };
  const [options] = useState({
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });

  const onClickImages = (e) => {
    console.log(e.target.files);
    Object.keys(e.target.files).map(async (value, key) => {
      var img = await imageCompression(e.target.files[key], options);
      var formData = new FormData();
      formData.append("product_image", img);
      const res = await request("post", "/image_upload", formData, false, true);
      console.log("+++++++++++++++++image_upload res", res.data);
      if (res.data && res.data.arrayimages) {
        res.data.arrayimages.map((image) => {
          setValues((prevState) => ({
            ...prevState,
            product_image: [...prevState.product_image, image],
          }));
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 380, left: 0, behavior: "smooth" });
  }, []);

  const matches = useMediaQuery("(max-width:575px)");

  //console.log(values);
  return (
    <Layout>
      <div className="main-container">
        <div className="pp-back">
          <img src="/images/listingSingle.jpg" alt="" />
        </div>
        <div className="pl-white-box">
          <h2 className="pl-head">POST A LISTING</h2>
          <div className="step-wrapper multi-items-step">
            <div className="clearfix step step1 active">
              <div className="step-circle">01</div>
              <div className="step-label">LOT DETAILS</div>
            </div>
            <div className="clearfix step step2">
              <div className="step-circle">02</div>
              <div className="step-label">ITEM DETAILS</div>
            </div>
          </div>
          <div className="pl-form">
            <h4 className="form-ghead">Lot Details</h4>
            <div className="row">
              <div className="col-md-6 form-group">
                <CustomInput
                  id="lot-title"
                  label="Lot title"
                  placeholder="Enter your lot title"
                  tooltiptitle="Keep this short and informative. This is what Buyers will see first."
                  // con={}
                  name="multiItems_title"
                  value={values.multiItems_title}
                  onChange={handleChange}
                  error={state ? !values.multiItems_title && true : false}
                  helperText={state && errors.multiItems_title}
                  InputProps={{
                    onKeyPress: values.multiItems_title
                      ? values.multiItems_title.length === 100
                        ? dallow
                        : ""
                      : "",
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <CustomInput
                  id="description"
                  label="Description"
                  placeholder="Enter a brief description"
                  tooltiptitle="Here you can get into more detail. Describe your livestock. Don't hold back, a good description can add many &#8364; to your sale price"
                  multiline
                  rows={4}
                  name="multiItems_description"
                  value={values.multiItems_description}
                  onChange={handleChange}
                  error={state ? !values.multiItems_description && true : false}
                  helperText={state && errors.multiItems_description}
                  // InputProps={{
                  //   onKeyPress:(values.multiItems_description ?(values.multiItems_decription.length === 500?dallow:""):"")
                  // }}
                />
              </div>
              <div className="col-12 col-md-6 form-group">
                <CustomInput
                  id="description"
                  label="Disclaimer"
                  placeholder="Enter a Disclaimer"
                  tooltiptitle=""
                  multiline
                  rows={4}
                  name="multiItems_declaration"
                  value={values.multiItems_declaration}
                  onChange={handleChange}
                  InputProps={{
                    onKeyPress: values.multiItems_declaration
                      ? values.multiItems_declaration.length === 500
                        ? dallow
                        : ""
                      : "",
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div
                className={`col-sm-6 col-md-3 form-group ${matches && "col-6"}`}
              >
                <CustomSelect
                  id="type"
                  label="Section"
                  tooltiptitle="Type of an Item"
                  name="multiItems_section"
                  selectMenu={Section}
                  value={values.multiItems_section}
                  error={state && !values.multiItems_section ? true : false}
                  pc_onchange={handleChange}
                  selectMenuHeader={"Select Section"}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.multiItems_section}
                  </FormHelperText>
                )}
              </div>
              <div
                className={`col-sm-6 col-md-3 form-group ${matches && "col-6"}`}
              >
                <CustomSelect
                  id="breed"
                  label="Type"
                  tooltiptitle="Breed of the animal"
                  name="multiItems_type"
                  value={values.multiItems_type}
                  pc_onchange={handleChange}
                  selectMenu={
                    values.multiItems_section == "Calf" ? Calf_Type : Type
                  }
                  selectMenuHeader={"Select type"}
                  error={state ? !values.multiItems_type && true : false}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.multiItems_type}
                  </FormHelperText>
                )}
              </div>
              <div className="col-sm-6 col-md-3 form-group">
                <CustomSelect
                  //id="method"
                  label="Sale Method"
                  tooltiptitle="Sale Method"
                  name="sale_method"
                  selectMenu={salemethod}
                  selectMenuHeader={"Select Sale method"}
                  value={values.sale_method}
                  pc_onchange={handleChange}
                  error={state ? !values.sale_method && true : false}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>{errors.sale_method}</FormHelperText>
                )}
              </div>

              {values.sale_method == "Sold by Weight" ? (
                <div className="col-sm-6 col-md-3 form-group">
                  <CustomSelect
                    id="weimethod"
                    label="Weighing method"
                    tooltiptitle="Weighing method"
                    name="multiItems_weighing_method"
                    selectMenu={weimethod}
                    selectMenuHeader={"Select Weighing method"}
                    value={values.multiItems_weighing_method}
                    pc_onchange={handleChange}
                    error={
                      state ? !values.multiItems_weighing_method && true : false
                    }
                  ></CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_weighing_method}
                    </FormHelperText>
                  )}
                </div>
              ) : (
                ""
              )}

              {/* {values.sale_method == "Not Sold on Weight" ? (
              <div className="col-sm-6 col-md-3 form-group">
              <CustomInput
                  id="lot-avg-weight"
                  label="Estimate Avg. Weight"
                  placeholder="Enter estimate avg weight"
                  fullWidth
                  margin="normal"              
                  name="multiItems_estimation_avg"
                  value={values.multiItems_estimation_avg}
                  onChange={handleChange}
                  error={state ? !values.multiItems_estimation_avg && true : false}
                  helperText={state && errors.multiItems_estimation_avg}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ 
                    onKeyPress: NumberOnly
                  }}
                  tooltiptitle="Reserver price of an product"
                />
              </div>) : ""} */}

              <div
                className={`col-sm-6 col-md-3 form-group ${matches && "col-6"}`}
              >
                <CustomInput
                  label="Avg Weight (Kgs)"
                  con={true}
                  placeholder="Enter avg weight in kgs"
                  tooltiptitle="Average weight of the animal in kgs"
                  name="multiItems_first_weight"
                  value={values.multiItems_first_weight}
                  onChange={handleChange}
                  error={
                    state ? !values.multiItems_first_weight && true : false
                  }
                  helperText={state && errors.multiItems_first_weight}
                />
              </div>

              <div
                className={`col-sm-6 col-md-3 form-group ${matches && "col-6"}`}
              >
                <CustomInput
                  label="Number of Animals"
                  con={true}
                  placeholder="Enter number of animals"
                  tooltiptitle="Number of Animals"
                  name="no_of_animals"
                  value={values.no_of_animals}
                  onChange={handleChange}
                  type="number"
                  max={99}
                  error={state ? !values.no_of_animals && true : false}
                  helperText={state && errors.no_of_animals}
                />
              </div>

              <div className="col-sm-6 col-md-3 form-group">
                {/* <CustomSelect
                  select
                  label="QA Replacement"
                  name="multiItems_assurance"
                  value={values.multiItems_assurance}
                  selectMenuHeader={"Select Qa"}
                  tooltiptitle=""
                  selectMenuNew={Quality_asurance}
                  //selectMenu={Quality_asurance}
                  error={state && !values.multiItems_assurance ? true : false}
                  pc_onchange={handleChange}
                ></CustomSelect>
                {state && (
                  <FormHelperText error>
                    {errors.multiItems_assurance
                      ? errors.multiItems_assurance
                      : ""}
                  </FormHelperText>
                )} */}
                <SearchOptions
                  label="Designations / Clubs, etc."
                  selection={Quality_asurance}
                  onchange={handleChange}
                  name="multiItems_assurance"
                  value={values?.multiItems_assurance}
                />
              </div>
              {/* <div className="col-12">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    ICBF Integrated?
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="multiItems_icbf"
                    value={values.multiItems_icbf}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl> */}

              {/* <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    QA Farm ?
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="multiItems_qafarm"
                    value={values.multiItems_qafarm}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="n/a"
                      control={<GreenRadio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl> */}
              {/* </div> */}

              {values.multiItems_icbf == "yes" ? (
                <>
                  {tag_number.map((val, index) => (
                    <>
                      {matches ? null : (
                        <div className="col-2">{val + "."}</div>
                      )}
                      <div
                        className={
                          matches
                            ? "col-12 d-flex align-items-center flex-nowrap icbfColumn"
                            : "col-4"
                        }
                      >
                        {/* <span className="indexVal">{val + "."}</span> */}
                        <CustomInput
                          label="Tag Number"
                          con={true}
                          placeholder={"Animal " + val}
                          tooltiptitle="Gender type of the animal"
                          name="multiItems_first_tag_number"
                          value={tag_number_pro[index]}
                          onChange={(e) => onProChange(index, e.target.value)}
                        />
                      </div>
                      <div className="col-6"></div>
                    </>
                  ))}
                </>
              ) : (
                ""
              )}
              {values.multiItems_icbf == "yes" ? (
                <div className="col-12 customContainer">
                  <div className="addItem">
                    <Button onClick={(e) => add_item_to()}>
                      <span className="material-icons">add_circle_outline</span>
                      ADD ITEM
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {values.multiItems_icbf != "yes" ? (
                <>
                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    {/* <DatePicker
                      placeholder="Select DOB Range"
                      name="multiItems_dob_range"
                      format="DD/MM/YYYY"
                      range
                      calendarPosition="top-left"
                      fixMainPosition
                      value={values.multiItems_dob_range}
                      maxDate={new Date()}
                      onChange={(dateObjects) => {
                        handleDateChange(dateObjects, "multiItems_dob_range");
                      }}
                    /> */}
                    <CustomDatePicker
                      key="test-date"
                      label="Oldest D.O.B"
                      placeholder="Select dob"
                      name="multiItems_first_dob"
                      disableFuture={true}
                      value={values.multiItems_first_dob}
                      onchange={handleDateChange}
                      tooltiptitle="Date animal was tested"
                      error={
                        state ? !values.multiItems_first_dob && true : false
                      }
                      helperText={state && errors.multiItems_first_dob}
                    />
                  </div>

                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    <CustomDatePicker
                      key="test-date"
                      label="Youngest D.O.B"
                      placeholder="Select dob"
                      name="multiItems_first_dob1"
                      disableFuture={true}
                      value={values.multiItems_first_dob1}
                      onchange={handleDateChange}
                      tooltiptitle="Date animal was tested"
                      min={new Date(values.multiItems_first_dob)}
                      error={
                        state ? !values.multiItems_first_dob1 && true : false
                      }
                      helperText={state && errors.multiItems_first_dob1}
                    />
                  </div>

                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    {/* <CustomSelect
                      select
                      label="Breed"
                      name="multiItems_first_breed"
                      value={values.multiItems_first_breed}
                      tooltiptitle=""
                      selectMenuHeader={"Select Breed"}
                      selectMenu={Breed}
                      error={
                        state && !values.multiItems_first_breed ? true : false
                      }
                      pc_onchange={handleChange}
                    ></CustomSelect> */}
                    {/* {console.log("breed",values.multiItems_first_breed)} */}
                    <SearchOptions
                      label="Breed"
                      selection={Breed}
                      onchange={handleChange2}
                      name="multiItems_first_breed"
                      value={values?.multiItems_first_breed}
                    />
                  </div>

                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    {/* <CustomSelect
                      select
                      label="Sex"
                      tooltiptitle=""
                      name="multiItems_first_sex"
                      value={values.multiItems_first_sex}
                      selectMenuHeader={"Select Sex"}
                      selectMenu={Sex}
                      error={
                        state && !values.multiItems_first_sex ? true : false
                      }
                      pc_onchange={handleChange}
                    ></CustomSelect>
                    {state && (
                      <FormHelperText error>
                        {!errors.multiItems_first_sex
                          ? errors.multiItems_first_sex
                          : ""}
                      </FormHelperText>
                    )} */}
                    <SearchOptions
                      label="Sex"
                      selection={Sex}
                      onchange={handleChange}
                      name="multiItems_first_sex"
                      value={values?.multiItems_first_sex}
                    />
                  </div>

                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    <CustomSelect
                      key="export"
                      label="Export"
                      name="multiItems_first_export"
                      value={
                        values.multiItems_first_export == "Yes"
                          ? "All Suitable for Export"
                          : values.multiItems_first_export == "No"
                          ? "All Not Suitable for Export"
                          : values.multiItems_first_export
                      }
                      tooltiptitle="Export"
                      selectMenu={[
                        "All Suitable for Export",
                        "All Not Suitable for Export",
                      ]}
                      selectMenuHeader={"Select export"}
                      pc_onchange={handleChange}
                      error={
                        state && !values.multiItems_first_export ? true : false
                      }
                      //helperText={values.multiItems_export[val]===undefined || !values.multiItems_export[val]?errors.multiItems_export[val]:""}
                    ></CustomSelect>
                    {state && (
                      <FormHelperText error>
                        {!values.multiItems_first_export
                          ? errors.multiItems_first_export
                          : ""}
                      </FormHelperText>
                    )}
                  </div>

                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    <CustomSelect
                      //id="method"
                      label="Movement"
                      name="multiItems_first_movement"
                      tooltiptitle=""
                      selectMenu={Owners}
                      selectMenuHeader={"Max # Movements"}
                      value={values.multiItems_first_movement}
                      pc_onchange={handleChange}
                      error={
                        state
                          ? !values.multiItems_first_movement && true
                          : false
                      }
                    ></CustomSelect>
                    {state && (
                      <FormHelperText error>
                        {errors.multiItems_first_movement}
                      </FormHelperText>
                    )}
                  </div>
                  {values?.multiItems_first_movement > 0 ? (
                    <>
                      <div
                        className={`col-sm-6 col-md-3 form-group ${
                          matches && "col-6"
                        }`}
                      >
                        {/* <DatePicker
                          placeholder="Select Movement Range"
                          name="multiItems_movement_range"
                          format="DD/MM/YYYY"
                          range
                          calendarPosition="top-left"
                          fixMainPosition
                          minDate={new Date(values.multiItems_first_dob)}
                          maxDate={new Date()}
                          value={values.multiItems_movement_range}
                          onChange={(dateObjects) => {
                            handleDateChange(
                              dateObjects,
                              "multiItems_movement_range"
                            );
                          }}
                        /> */}

                        <CustomDatePicker
                          key="test-date"
                          label="Oldest Movement"
                          placeholder="Select herd"
                          name="multiItems_first_cardissued"
                          disableFuture={true}
                          value={values.multiItems_first_cardissued}
                          min={new Date(values.multiItems_first_dob)}
                          onchange={handleDateChange}
                          //tooltiptitle="Date animal was tested"
                          error={
                            state
                              ? !values.multiItems_first_cardissued && true
                              : false
                          }
                          helperText={
                            state && errors.multiItems_first_cardissued
                          }
                        />
                      </div>

                      <div
                        className={`col-sm-6 col-md-3 form-group ${
                          matches && "col-6"
                        }`}
                      >
                        <CustomDatePicker
                          key="test-date"
                          label="Most Recent Movement"
                          placeholder="Select herd"
                          name="multiItems_first_cardissued1"
                          disableFuture={true}
                          value={values.multiItems_first_cardissued1}
                          min={new Date(values.multiItems_first_cardissued)}
                          onchange={handleDateChange}
                          //tooltiptitle="Date animal was tested"
                          error={
                            state
                              ? !values.multiItems_first_cardissued1 && true
                              : false
                          }
                          helperText={
                            state && errors.multiItems_first_cardissued1
                          }
                        />
                      </div>
                    </>
                  ) : null}
                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    <CustomDatePicker
                      key="test-date"
                      label="Oldest Test Date"
                      placeholder="Select dob"
                      name="multiItems_first_old_date"
                      disableFuture={true}
                      value={values.multiItems_first_old_date}
                      onchange={handleDateChange}
                      min={new Date(values.multiItems_first_dob)}
                      //tooltiptitle="Date animal was tested"
                      error={
                        state
                          ? !values.multiItems_first_old_date && true
                          : false
                      }
                      helperText={state && errors.multiItems_first_old_date}
                    />
                  </div>

                  <div
                    className={`col-sm-6 col-md-3 form-group ${
                      matches && "col-6"
                    }`}
                  >
                    <CustomSelect
                      key="calfReg"
                      label="Calf Reg"
                      name="multiItems_first_calfReg"
                      selectMenu={["Yes", "No"]}
                      selectMenuHeader={"Select Calf Reg"}
                      pc_onchange={handleChange}
                      value={values.multiItems_first_calfReg}
                      tooltiptitle="Select if calf is registered"
                      error={
                        state && !values.multiItems_first_calfReg ? true : false
                      }
                    ></CustomSelect>
                    {state && (
                      <FormHelperText error>
                        {!values.multiItems_first_calfReg
                          ? errors.multiItems_first_calfReg
                          : ""}
                      </FormHelperText>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {/* <div className="col-12 col-md-6 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Quality assurance farm
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="multiItems_assurance"
                    value={values.multiItems_assurance}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_truck_turn}
                    </FormHelperText>
                  )}
                </FormControl>
              </div> */}

              <div className="col-12 col-md-6 form-group">
                {/* <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    QA Farm ?
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="multiItems_qafarm"
                    value={values.multiItems_qafarm}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="n/a"
                      control={<GreenRadio />}
                      label="N/A"
                    />
                  </RadioGroup>
                </FormControl> */}
                {/* <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Can a Truck access and turn in your yard?
                  </FormLabel>
                  <RadioGroup
                    aria-label="truck-turn"
                    className="fd-row"
                    name="multiItems_truck_turn"
                    value={values.multiItems_truck_turn}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_truck_turn}
                    </FormHelperText>
                  )}
                </FormControl> */}
              </div>

              <div className="col-12 col-md-3 form-group">
                {/* <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Display Seller name and approximate location
                  </FormLabel>
                  <RadioGroup
                    aria-label="preferential-times"
                    name="multiItems_pref_times"
                    className="fd-row"
                    value={values.multiItems_pref_times}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_pref_times}
                    </FormHelperText>
                  )}
                </FormControl> */}
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <Upload
                  src="/images/insert-photo.png"
                  header="UPLOAD IMAGE"
                  subheader="You can upload multiple images"
                  id="upload-image"
                  title="Images displayed under each item"
                  accept="image/*"
                  onchange={onClickImages}
                  multiple={true}
                />
                {state && (
                  <FormHelperText error>{errors.product_image}</FormHelperText>
                )}
                <div className="row">
                  {values.product_image &&
                    values.product_image.map((m, key) => (
                      <div className="col-md-3 productPostThumbnails" key={key}>
                        <label
                          onClick={() => removeImage(m)}
                          name={key}
                          className="pp-edit material-icons"
                        >
                          close
                        </label>
                        <img
                          src={IMAGE_URL + m}
                          className="pro-img rounded img-fluid"
                        />
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-12 col-md-6 form-group">
                <Upload
                  src="/images/movie.png"
                  header="UPLOAD VIDEO"
                  subheader="You can upload a single video file"
                  accept="video/*"
                  id="upload-image"
                  onchange={onClickVideo}
                  multiple={false}
                  title="Videos displayed under each item"
                />

                {state && (
                  <FormHelperText error>{errors.product_videos}</FormHelperText>
                )}
                <div className="row">
                  {values.product_videos && (
                    <>
                      <div className="col-12 mt-2 productPostThumbnails multVideo">
                        <label
                          onClick={() => removeImage("videos")}
                          className="pp-edit material-icons"
                        >
                          close
                        </label>
                        <Player
                          // ClosedCaptionButton={true}
                          playsInline
                          poster="/assets/poster.png"
                          src={URL.createObjectURL(values.product_videos)}
                        />
                      </div>
                    </>
                  )}
                  <div
                    className="col-12 mt-2"
                    id="myProgressDiv"
                    style={{ display: "none" }}
                  >
                    <div
                      id="myProgress"
                      className=""
                      style={{ width: "100%", backgroundColor: "#CFCFCF" }}
                    >
                      <div
                        id="myBar"
                        style={{
                          width: "1%",
                          height: "30px",
                          backgroundColor: "#76bc43",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4 className="form-ghead mt-4">Price Details</h4>

            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <FormControl
                  fullWidth
                  component="fieldset"
                  className="listingType"
                >
                  {matches ? (
                    <FormLabel component="legend" className="pp-rc-label">
                      Listing Type
                    </FormLabel>
                  ) : (
                    <FormLabel component="legend" className="pp-rc-label">
                      Post this listing under
                    </FormLabel>
                  )}
                  <div className="listing-under d-flex">
                    <CheckBox
                      label="Sale"
                      name="multiItems_auction_checked"
                      value={values.multiItems_auction_checked}
                      onchange={handleChange}
                      click={() => !values.multiItems_auction_checked}
                    />
                    <CheckBox
                      label="Buy Now"
                      name="multiItems_buynow_checked"
                      value={values.multiItems_buynow_checked}
                      onchange={handleChange}
                      click={() => !values.multiItems_buynow_checked}
                    />
                  </div>
                </FormControl>
              </div>
              {matches ? (
                <>
                  <div className="col-6 col-md-3 form-group">
                    <CustomSelect
                      key="calfReg"
                      label="Reserve Type"
                      name="multiItems_resprice_checked"
                      selectMenuNew={[
                        { value: "public", name: "Public" },
                        { value: "private", name: "Private" },
                      ]}
                      pc_onchange={handleChange}
                      value={values.multiItems_resprice_checked}
                      tooltiptitle="Select"
                      error={
                        state && !values.multiItems_resprice_checked
                          ? true
                          : false
                      }
                    ></CustomSelect>
                  </div>
                  <div className="col-6 col-md-3 form-group">
                    <CustomInput
                      id="lot-title"
                      label="Reserve price"
                      placeholder="Enter reserve price"
                      fullWidth
                      margin="normal"
                      disabled={
                        values.multiItems_auction_checked ? false : true
                      }
                      name="multiItems_resprice"
                      value={values.multiItems_resprice}
                      onChange={handleChange}
                      error={
                        state && values.multiItems_auction_checked
                          ? !values.multiItems_resprice && true
                          : false
                      }
                      helperText={state && errors.multiItems_resprice}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span className="material-icons">euro</span>
                          </InputAdornment>
                        ),
                        onKeyPress: NumberOnly,
                      }}
                      tooltiptitle="Reserver price of an product"
                    />
                  </div>
                </>
              ) : null}
              <div
                className={`col-6 col-md-3 form-group ${matches && "col-6"}`}
              >
                <CustomInput
                  id="start-price"
                  label="Start price"
                  placeholder="Enter a start price"
                  name="multiItems_startprice"
                  value={values.multiItems_startprice}
                  onChange={handleChange}
                  error={
                    state && values.multiItems_auction_checked
                      ? !values.multiItems_startprice && true
                      : false
                  }
                  disabled={values.multiItems_auction_checked ? false : true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                    onKeyPress: NumberOnly,
                  }}
                  // InputProps={{

                  // }}
                  tooltiptitle="Starting price of the animal"
                  helperText={state && errors.multiItems_startprice}
                />
              </div>
              <div
                className={`col-6 col-md-3 form-group ${matches && "col-6"}`}
              >
                <CustomInput
                  id="buy-now-price"
                  label="Buy Now price"
                  placeholder="Enter Buy Now Price"
                  name="multiItems_buynowprice"
                  disabled={values.multiItems_buynow_checked ? false : true}
                  value={values.multiItems_buynowprice}
                  onChange={handleChange}
                  error={
                    state && errors.multiItems_buynowprice
                      ? !values.multiItems_buynowprice && true
                      : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="material-icons">euro</span>
                      </InputAdornment>
                    ),
                    onKeyPress: NumberOnly,
                  }}
                  tooltiptitle="Buy Now Price of an animal"
                  helperText={state && errors.multiItems_buynowprice}
                />
              </div>

              {/* <div className="col-12 col-md-3 form-group">
                <DatePicker
                  id="Auction start-date"
                  key="Auction start-date"
                  label="Auction start-date"
                  format={"MM/dd/yyyy hh:mm:ss"}
                  placeholder="Auction start-date"
                  disableFuture={false}
                  name="start_date"
                  value={values.start_date}
                  onchange={handleDateChange}
                  tooltiptitle="Auction start-date"
                  error={state ? !values.start_date && true : false}
                  helperText={state && errors.start_date}
                />
              </div> */}

              {/* <div className="col-12 col-md-3 form-group">
                <DatePicker
                  id="Auction end-date"
                  format={"MM/dd/yyyy hh:mm:ss"}
                  key="Auction end-date"
                  label="Auction end-date"
                  placeholder="Auction end-date"
                  disableFuture={false}
                  name="end_date"
                  value={values.end_date}
                  onchange={handleDateChange}
                  tooltiptitle="Auction end-date"
                  error={state ? !values.end_date && true : false}
                  helperText={state && errors.end_date}
                />
              </div>  */}
            </div>
            {matches ? null : (
              <div className="row">
                <div className="col-6 col-md-6 form-group">
                  <FormControl fullWidth component="fieldset">
                    <FormLabel component="legend" className="pp-rc-label">
                      Reserve Type
                    </FormLabel>
                    <RadioGroup
                      aria-label="reserve-price"
                      name="multiItems_resprice_checked"
                      className="reserve-price"
                      // className="fd-row"
                      value={values.multiItems_resprice_checked}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="public"
                        control={<GreenRadio />}
                        label="Public"
                      />
                      <FormControlLabel
                        value="private"
                        control={<GreenRadio />}
                        label="Private"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="col-6 col-md-3 form-group">
                  <CustomInput
                    id="lot-title"
                    label="Reserve price"
                    placeholder="Enter reserve price"
                    fullWidth
                    margin="normal"
                    disabled={values.multiItems_auction_checked ? false : true}
                    name="multiItems_resprice"
                    value={values.multiItems_resprice}
                    onChange={handleChange}
                    error={
                      state && values.multiItems_auction_checked
                        ? !values.multiItems_resprice && true
                        : false
                    }
                    helperText={state && errors.multiItems_resprice}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="material-icons">euro</span>
                        </InputAdornment>
                      ),
                      onKeyPress: NumberOnly,
                    }}
                    tooltiptitle="Reserver price of an product"
                  />
                </div>
              </div>
            )}

            <h4 className="form-ghead mt-4">Pick-up Location Details</h4>

            <div className="row">
              <div className="col-12 col-md-12 form-group">
                <FormControl
                  fullWidth
                  component="fieldset"
                  className="listingType"
                >
                  {matches ? (
                    <FormLabel component="legend" className="pp-rc-label">
                      Pick-up Address
                    </FormLabel>
                  ) : (
                    <FormLabel component="legend" className="pp-rc-label">
                      Collection location of livestock
                    </FormLabel>
                  )}

                  <RadioGroup
                    aria-label="reserve-price"
                    name="multiItems_pickup_location"
                    className="reserve-price"
                    value={values.multiItems_pickup_location}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="different_address"
                      control={<GreenRadio />}
                      label={matches ? "New Address" : "Different address"}
                    />
                    <FormControlLabel
                      value="present_address"
                      control={<GreenRadio />}
                      label={matches ? "Default" : "Same as my present address"}
                    />
                  </RadioGroup>
                </FormControl>
                {/*<p className="CountyLaunchNotice">
                  <span className="material-icons">info</span>Initially, we are only
                  launching in South Leinster, So please hold off for a little
                  while.
                </p>*/}
              </div>
            </div>
            {values.multiItems_pickup_location == "different_address" ? (
              <div className="row">
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="building-name"
                    label="Address Line 1"
                    // con={false}
                    placeholder="Enter your Address Line 1"
                    tooltiptitle="Enter your Address Line "
                    name="multiItems_bldgname"
                    value={values.multiItems_bldgname}
                    onChange={handleChange}
                    error={state ? !values.multiItems_bldgname && true : false}
                    helperText={state && errors.multiItems_bldgname}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="street-name"
                    label="Townsland / Address Line 2"
                    con={"sn"}
                    placeholder="Enter your Townsland / Address Line 2"
                    tooltiptitle="Enter your Townsland / Address Line 2"
                    name="multiItems_st"
                    value={values.multiItems_st}
                    onChange={handleChange}
                    error={state ? !values.multiItems_st && true : false}
                    helperText={state && errors.multiItems_stname}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="town-name"
                    label="Town name"
                    con={false}
                    placeholder="Enter town"
                    tooltiptitle="Enter your town"
                    name="multiItems_town"
                    value={values.multiItems_town}
                    onChange={handleChange}
                    error={state ? !values.multiItems_town && true : false}
                    helperText={state && errors.multiItems_town}
                  />
                </div>

                <div className="col-sm-6 form-group">
                  <CustomSelect
                    id="County"
                    label="Select your county"
                    tooltiptitle="County"
                    name="multiItems_county"
                    value={values.multiItems_county}
                    pc_onchange={handleChange}
                    error={state ? !values.multiItems_county && true : false}
                    selectMenuHeader={"Select your county"}
                  >
                    {NewCounties.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_county}
                    </FormHelperText>
                  )}
                  {/* <p style={{color:"black",fontSize:"12px"}}>Initially, we are only launching in South Leinster ,so please hold off for a little while</p> */}
                </div>
                <div className="col-sm-6 form-group">
                  <CustomSelect
                    id="Country"
                    label="Select your country"
                    tooltiptitle="Country"
                    name="multiItems_country"
                    value={values.multiItems_country}
                    pc_onchange={handleChange}
                    error={state ? !values.multiItems_country && true : false}
                    selectMenuHeader={"Select your country"}
                  >
                    <option key="Ireland" value="Ireland">
                      Ireland
                    </option>
                  </CustomSelect>
                  {state && (
                    <FormHelperText error>
                      {errors.multiItems_country}
                    </FormHelperText>
                  )}
                </div>

                <div className="col-sm-6 form-group">
                  <CustomInput
                    id="eir-code"
                    label="EIR code"
                    con={"sn"}
                    placeholder="Enter EIR code"
                    tooltiptitle="EIR code"
                    name="multiItems_eircode"
                    value={values.multiItems_eircode}
                    onChange={handleChange}
                    error={state ? !values.multiItems_eircode && true : false}
                    helperText={state && errors.multiItems_eircode}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <div className="row">
              <div className="col-12 col-md-4 form-group">
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend" className="pp-rc-label">
                    Do you want to link your lots
                  </FormLabel>
                  <RadioGroup
                    aria-label="reserve-price"
                    name="multiItems_linklots"
                    className="reserve-price"
                    value={values.multiItems_linklots}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div> */}

            <div className="pl-btn pt-4">
              <PrimaryButton label="NEXT" onsubmit={handleSubmit}>
                <span className="material-icons">arrow_forward</span>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <FFModal
        open={modalopennew}
        handleClose={() => setModalopennew(false)}
        modaltitle=""
        className="termsCond"
      >
        <div style={{ fontWeight: "500" }}>
          <p className="card-text">
            <CheckBox
              label={
                <p>
                  <span>I agree to</span>
                  <a
                    style={{ color: "green" }}
                    target={"_blank"}
                    href={"/terms"}
                  >
                    Terms & Conditions
                  </a>
                  <span></span>
                </p>
              }
              name="terms"
              /* value={values.singleitemlot_auction} */
              value={values.terms}
              click={(e) => !values.terms}
              onchange={(e) => {
                console.log("llllllllllllllllllllll", e.target.checked);
                if (e.target.checked) {
                  Nextpage(1);
                }
              }}
            />
          </p>

          {/* <div className="modalCTA d-flex justify-content-end align-items-center">
            <Button onClick={() => accept_terms('no')}>No</Button>
        
              <PrimaryButton
                label={"Yes"}
                onsubmit={() => accept_terms('yes')}
              />
            </div> */}
        </div>
      </FFModal>
      <FFModal
        open={modalopen2}
        handleClose={() => setModalopen2(false)}
        modaltitle=""
        className="termsCond"
      >
        <div style={{ fontWeight: "500" }}>
          <p className="card-text">
            You have chosen FEEDLOT STATUS,
            <br /> This means your herd has restricted selling.
            <br /> Do you agree to this designation?
          </p>

          <div className="modalCTA d-flex justify-content-end align-items-center">
            <Button onClick={() => setModalopen2(false)}>Cancel</Button>

            <PrimaryButton label={"Continue"} onsubmit={handleChange1} />
          </div>
        </div>
      </FFModal>

      <FFModal
        open={modalopen3}
        handleClose={() => setModalopen3(false)}
        modaltitle="ICBF integration"
      >
        <div className="seller-note" style={{ fontWeight: "700" }}>
          <br></br>
          <p style={{ fontSize: "16px" }}>
            By clicking on "Continue" you agree to log in with your ICBF account
            to enable ICBD integration features.
          </p>

          <div className="pl-btns">
            <PrimaryButton
              label={"Continue"}
              btnSize={"smallsize"}
              onsubmit={geticbfcode2}
            />
          </div>
        </div>
      </FFModal>
      <FFModal
        open={modalopenOther}
        handleClose={toggleModalOther}
        modaltitle="Input Breed"
      >
        <div style={{ fontWeight: "500" }} className="enterBreed">
          <textarea
            name="collection_breed"
            value={values.collection_breed}
            onKeyPress={TextOnly}
            onChange={breedchage}
            placeholder="Enter Breed here..."
          ></textarea>
          {/* <Button onClick={callcommand}>submit</Button> */}
          <div className="pl-btn pt-4">
            <PrimaryButton
              label={"Submit"}
              onsubmit={callcommand}
              btnSize={"9"}
            ></PrimaryButton>
          </div>
        </div>
      </FFModal>

      <FFModal
        open={modalopenCB}
        handleClose={toggleModalCB}
        modaltitle="Breed # 1"
      >
        <div style={{ fontWeight: "500" }}>
          {/* {console.log("cross",values.collection_breed)}
          <SearchOptions
              label={<span style={{backgroundColor:"white"}}>&nbsp;CrossBreed&nbsp;</span>}
              selection={Crossbreed}
              onchange={breedchage}
              name="collection_breed"
              value={values.collection_breed}
            /> */}

          <div className="col-12">
            <CustomSelect
              label="Breed # 1"
              tooltiptitle="Breed # 1 of the animal"
              name="collection_breed"
              value={values.collection_breed}
              selectMenuHeader={"Select Breed # 1"}
              selectMenu={Crossbreed}
              // error={state && !values.multiItems_breed[val]?true:false}
              // helperText={state?errors.multiItems_breed:""}
              pc_onchange={breedchage}
            ></CustomSelect>

            <FormHelperText error>
              {!values.collection_breed ? values.bre : ""}
            </FormHelperText>
          </div>

          {/* <textarea name="multiItems_breed" id={v} value={values.multiItems_breed[v]} onKeyPress={specialcharacter} onChange={handleChange}>Enter Breed here...</textarea> */}
          {/* <Button onClick={callcommand}>submit</Button> */}
          <div className="pl-btn pt-4">
            <PrimaryButton
              label={"Submit"}
              onsubmit={callcommand1}
              btnSize={"9"}
            ></PrimaryButton>
          </div>
        </div>
      </FFModal>

      <FFModal
        open={modalopenCB1}
        handleClose={toggleModalCB1}
        modaltitle="Breed # 2"
      >
        <div style={{ fontWeight: "500" }}>
          {/* {console.log("cross",values.collection_breed)}
          <SearchOptions
              label={<span style={{backgroundColor:"white"}}>&nbsp;CrossBreed&nbsp;</span>}
              selection={Crossbreed}
              onchange={breedchage}
              name="collection_breed"
              value={values.collection_breed}
            /> */}

          <div className="col-12">
            <CustomSelect
              label="Breed # 2"
              tooltiptitle="Breed # 2 of the animal"
              name="collection_breed"
              value={values.collection_breed}
              selectMenuHeader={"Select Breed # 2"}
              selectMenu={Crossbreed}
              // error={state && !values.multiItems_breed[val]?true:false}
              // helperText={state?errors.multiItems_breed:""}
              pc_onchange={breedchage}
            ></CustomSelect>

            <FormHelperText error>
              {!values.collection_breed ? values.bre : ""}
            </FormHelperText>
          </div>

          {/* <textarea name="multiItems_breed" id={v} value={values.multiItems_breed[v]} onKeyPress={specialcharacter} onChange={handleChange}>Enter Breed here...</textarea> */}
          {/* <Button onClick={callcommand}>submit</Button> */}
          <div className="pl-btn pt-4">
            <PrimaryButton
              label={"Submit"}
              onsubmit={finalsubmit}
              btnSize={"9"}
            ></PrimaryButton>
          </div>
        </div>
      </FFModal>
    </Layout>
  );
};

export default MultipleItemsStep1;
