import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ScrollToTop from '../../Router/ScrollToTop';
import "./Modal.css";

const useStyles = makeStyles((theme, maxWidth) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '100%',
    maxWidth: '46rem'
  }
}));

const FFModalPopup = ({ open, handleClose, children, modaltitle, customClassName }) => {
  const classes = useStyles();

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      className={classes.modal}
    >
      <div className={classes.paper}>
        {/*<ScrollToTop />*/}
        <div className={`modal fade show custom-modal ${customClassName}`}>
          <div className="popup-dialog modal-lg">
            <div  className="modal-content popup-content">
              <div className="modal-body cm-body popup-body">{children}</div>
              <button type="button" className="close pull_right" onClick={handleClose}>
                  <i className="fa fa-times"></i>
            </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FFModalPopup;
