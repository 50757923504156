
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Router/Routes";
import ScrollToTop  from "./Router/ScrollToTop"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import './App.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function App() {
  return (
    <div className="App">
     <Router> 
        <Elements stripe={stripePromise}>
        <ScrollToTop />
        <Routes />
        </Elements>
      </Router>
    </div>
  );
}

export default App;
