import JwtDecode from "jwt-decode";
import React from "react";
import AuctionList from "../../components/AuctionCard/AuctionList";
import { isAuthenticated } from "../../Utils/Authenticated";
import { Toast } from "../../Utils/Notifications";
import { request } from "../../Utils/Request";

const ListView = (props) => {
  const handlechange=async(e)=>{

    var {id,checked}=e.target;
    console.log("+++++++",id,checked)

  if(checked)
  {
    const res = await request (
      "post",
      "/watchlist",
       {id:id, userid: JwtDecode(isAuthenticated()).id},
       false,
       true
    )
    


    // console.log("+++++",res)
    if(res.data.success="yes"){
      Toast.fire({type:"success",title:"Lot added to Favourite"})
    }
    else
    {
     Toast.fire({type:"error",title:"Something went wrong"})
    }
  }
  else{
    const res = await request (
      "post",
      "/removeFwatchlist",
       {id:id, userid: JwtDecode(isAuthenticated()).id},
       false,
       true
    )
    
    // console.log("+++++",res)
    if(res.data.success="yes"){
      Toast.fire({ type: "success", title: "Lot removed from Favourite" });
    }
    else
    {
     Toast.fire({type:"error",title:"Something went wrong"})
    }

  }


  }
  return (
    <div className="searchList justify-content-start align-items-center">
      {props.searchData &&
        props.searchData.map((data) => <AuctionList data={data} onchange={handlechange} />)}
    </div>
  );
};
export default ListView;
