import { StepContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { request } from "../../Utils/Request";
import ReactHtmlParser from "react-html-parser";
import "./allcss.css";

const Faq = () => {
  const [content, setContent] = useState([]);
  const getContent = async (e) => {
    const res = await request("post", "/show_static_content", {
      page_id: "FAQs",
    });
    if (res && res.data && res.data.status == 'true') {
      setContent(res.data.data[0].content);
    }
  };

  useEffect((e) => {
    getContent();
  }, []);
  return (
    <Layout>
      <div className="static-wrap-container container-lg">
        <div className="static-content">{ReactHtmlParser(content)}</div>
      </div>
    </Layout>
  );
};
export default Faq;