import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BankCard from "./BankCard";
import AddCard from "./AddCard";
import { Button, FormHelperText } from "@material-ui/core";
import FFModal from "../Modal";
import CustomInput from "../Inputs/borderedInput";
import PrimaryButton from "../Inputs/PrimaryButton";
import CustomSelect from "../../components/Inputs/borderedSelect";
import { isAuthenticated } from "../../Utils/Authenticated";
import UseForm from "../../Utils/UserForm";
import { bankdetails } from "../../Utils/Validators";
import { request } from "../../Utils/Request";
import JwtDecode from "jwt-decode";
import { Toast } from "../../Utils/Notifications";
import { HerdNum } from "../../Utils/constant";

const BankDetails = (props) => {
  const [dis, setDis] = useState(false);
  const [dis1, setDis1] = useState(false);
  const [details,setDetails]=useState([]);
  const [btnloading,setBtnloading]=useState(false);
  const onPop = async (e) => {
    setDis(true);
  };
  

  var {
    handleSubmit,
    handleChange,
    values,
    errors,
    state,
    setState,
    setValues
  } = UseForm(formSubmit,bankdetails);

 async function formSubmit(){
  setBtnloading(true)
  var data={
    user_id:JwtDecode(isAuthenticated()).id,
    bank:values.bank,
    iso:values.iso_country_code,
    branch:values.branch_identifier,
    account:values.account_number,
    bank_id:values.bid
  }
  if(!dis1){
      const res=await request("post","/addnewbank",data)
       if(res.data.success==="yes"){
        setDis(false)
          
        Toast.fire({type:"success",title:"Bank details added successfully"})
         getDetails()
         setValues({
             bank:'',
             iso_country_code:'',
             branch_identifier:'',
             account_number:'',
             bid:'',
             sort_code:''
         })
         setState(false)
       }
      }
      else{
        const res=await request("post","/updateBankDetails",data)
        if(res.data.success==="yes"){
          setDis(false)
          
          Toast.fire({type:"success",title:"Bank details updated successfully"})
          getDetails()
        }
      }
      setBtnloading(false)
 } 

 const getDetails=async(e)=>{
      const res=await request('post',"/getBankDetails",{user_id:JwtDecode(isAuthenticated()).id})
      if(res.data.success==="yes"){
        setDetails(res.data.data)
      }
    }
    const bankdel=async(value)=>{
         const res=await request('post',"/deletebank",{bank_id:details[value].id},false,true)
         if(res.data.success==="yes"){
          Toast.fire({type:"success",title:"Bank details deleted successfully"})
          getDetails()
  
      }
    }
    const update=(value)=>{
      // const {value}=e.target;
      console.log("+++",value)
      setValues({...values,bid:details[value].id,bank:details[value].bank,iso_country_code:details[value].iso,branch_identifier:details[0].branch,account_number:details[0].account})
      setDis1(true)
      onPop()
    }

 useEffect(()=>{
    getDetails()
 },[])

  return (
    <div className="row">
      <div className="col-sm-4 form-group">
        <Button onClick={onPop} className="p-0 addBank">
          <AddCard />
        </Button>
      </div>
     {details && details.map((data,key)=>
      <div className="col-sm-4 form-group">
        

         <BankCard
          ke={key}
          bankname={data.bank}
          del={bankdel}
          edi={update}
          isocode={data.iso}
          branchid={data.branch}
          accountno={data.account}
          value="checked"
        />
    
      
      </div>  
      )}

      <FFModal
        open={dis}
        handleClose={(e) => setDis(false)}
        modaltitle="Add Bank Details"
      >
        <div className="row" style={{fontWeight:'500'}}>
        <div className="col-12 form-group">
            <CustomInput
              label="Account Holder Name*"
              placeholder="Enter account holder name"
              tooltiptitle="Enter account holder name"
              name="branch_identifier"
              con={false}
              onchange={handleChange}
              value={values.branch_identifier}
              error={state && errors.branch_identifier?errors.branch_identifier:""}
            />
             <FormHelperText error>{state && errors.branch_identifier}</FormHelperText>
          </div>
          <div className="col-12 form-group">
            <CustomInput
              label="IBAN*"
              // con={true}
              placeholder="Enter your IBAN"
              tooltiptitle="Enter your IBAN"
              name="iso_country_code"
              onchange={handleChange}
              InputProps={{
               
                onKeyPress: HerdNum,
              }}
              value={values.iso_country_code}
              error={state && errors.iso_country_code?errors.iso_country_code:""}
            />
             <FormHelperText error>{state && errors.iso_country_code}</FormHelperText>
          </div>
          <div className="col-12 form-group">
            <CustomInput
              id="Bank Name"
              label="Bank Name*"
              tooltiptitle="Bank Name"
              name="bank"
              onchange={handleChange}
              value={values.bank}
             
            
              error={state && errors.bank?true:false}

            ></CustomInput>
            <FormHelperText error>{state && errors.bank}</FormHelperText>
          </div>
         
         
          <div className="col-12 form-group">
            <CustomInput
              label="Account Number"
              placeholder="Enter account number"
              tooltiptitle="Enter account number"
              name="account_number"
              con={true}
              onchange={handleChange}
              value={values.account_number}
              // error={state && errors.account_number?errors.account_number:""}
            />
             {/* <FormHelperText error>{state && errors.account_number}</FormHelperText> */}
          </div>
          <div className="col-12 form-group">
            <CustomInput
              label="Sort Code"
              placeholder="Enter Sort Code"
              tooltiptitle="Enter Sort Code"
              name="sort_code"
              con={true}
              onchange={handleChange}
              value={values.sort_code}
             
            />
             
          </div>
          <div className="pl-btn pt-4">
            <PrimaryButton onsubmit={handleSubmit} dis={btnloading} label="SAVE" />
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default BankDetails;
