import React from "react";
import AddressCard from '../../components/SavedAddress/AddressCard'; 
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import PayCard from './PayCard';
import CardForm from './CardForm'
import { CircularProgress } from '@material-ui/core';
import {CardElement,useStripe,IbanElement} from '@stripe/react-stripe-js';
import './checkout.css'
import FFModal from "../../components/Modal";
import { Button, Tooltip } from "@material-ui/core";

const PaymentCardDeposit = (props) => { 


    const [type, setType] = React.useState("");
    const [name, setName] = React.useState(localStorage.userFirstName + ' ' + localStorage.userLastName);
    const [email, setEmail] = React.useState(localStorage.userEmail);

    const [diff , setDiff] = React.useState(false);
    const newCard = ()  => setDiff(!diff);

    const changeName = (e) => {
      const { id, name, value } = e.target;
      setName(value)
  
    }
    const changeEmail = (e) => {
      const { id, name, value } = e.target;
      setEmail(value)
  
    }

    const changeType = (change)  => {

      if (change != ""){
        setType(change)

        // if (change == "sepa"){
        //   // SEPA payment not support guest login
        //   window.open(`https://sso.trustap.com/auth/realms/trustap-stage/protocol/openid-connect/auth?response_type=code&client_id=ae44855d-6b59-499b-bd4e-d1e048be70f3&redirect_uri=${process.env.REACT_APP_FRONT_URL}&scope=openid%20p2p_ls:offline_create%20p2p_ls:offline_update%20p2p_tx:offline_accept_deposit%20p2p_tx:offline_set_price%20p2p_tx:offline_confirm_handover&state=close`, "_blank")
        // }
      } else {
        if (type == "sepa"){
          setType("card")
        } else if (type == "card") {
          setType("sepa")
        }
      }
    }
    
    const IBAN_STYLE = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      }
    };

    const IBAN_ELEMENT_OPTIONS = {
      supportedCountries: ['SEPA'],
      // Elements can use a placeholder as an example IBAN that reflects
      // the IBAN format of your customer's country. If you know your
      // customer's country, we recommend that you pass it to the Element as the
      // placeholderCountry.
      placeholderCountry: 'IE',
      style: IBAN_STYLE,
    };
     
    return (
	    <div className="card checkout-card"> 
	       <div className="card-body"> 
              <h4 className="cc-title text-center">
                Payment Details
              </h4> 

              {(type == "sepa") ?
              // <CardForm recall={e=>{props.acall(e);newCard(e)}} /> 
              <>
                <div className="form-group mt-4">
                  <form>
                    <div className="form-row">
                     
                        <label> Name </label>
                        <input name="name" placeholder="Account Holder Name" value={name} required onChange={changeName} />
                     
                    </div>
                    <div className="form-row">
                     
                        <label> Email Address</label>
                        <input name="email" type="email" placeholder="Email Address" value={email} required onChange={changeEmail} />
                    
                    </div>

                    <div className="form-row">
                      <label> IBAN </label>
                        <IbanElement options={IBAN_ELEMENT_OPTIONS} />
                      
                    </div>

                    {/* Display mandate acceptance text. */}
                    <div className="mandate-acceptance">
                      By providing your payment information and confirming this payment, you authorise (A) Trustap and Stripe, the payment service provider, to send instructions to your bank to debit your account; and, (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.
                    </div>
                  </form>
                </div>
                {props.loading ? <CircularProgress /> : <PrimaryButton onsubmit={(e) => {props.sub2(name, email)}} type="submit" label="SEPA CHECKOUT"/> }
                <div className="use-diff">
                    <a onClick={(e) =>{setType('')}}><span className="material-icons">account_balance</span> Use a different mode of payment</a>
                  </div>
                </>
              : (type == "card") ?
                <>

                {props.card.length == 0 ? <CardForm recall={e=>{props.acall(e);}} /> :
                <>
                {props.card.map((data,key)=>
                  <div className="form-group">
                    <PayCard 
                      cardholder={data.name}
                      cardnumber={"**** **** **** "+data.last4}
                      valid={data.exp_month+"/"+data.exp_year} 
                      val={key}
                      value={props.checking}
                      onCall={e=>props.firstcall(e)}
                    />
                  </div>
                )}
                {props.loading ? <CircularProgress /> : <PrimaryButton onsubmit={props.sub} label="MAKE CARD PAYMENT"/> }
                  
                </>
              }

                  <div className="use-diff">
                    <a onClick={(e) =>{setType('')}}><span className="material-icons">account_balance</span> Use a different mode of payment</a>
                  </div>
                </>
              :
                <>
                {/* {props.loading2 ? '' : <PrimaryButton onsubmit={(e) =>{changeType('sepa')}} label="Pay By Bank Transfer"/>} */}
                {/* <br></br> */}
                {props.loading2 ? '' : <PrimaryButton onsubmit={(e) =>{changeType('card')}} label="Pay By Card"/>}
                </>
              }
              
	       </div>

          
	    </div>



    );
}

export default PaymentCardDeposit