import React,{useState} from 'react';  
import { Link } from "react-router-dom"; 

const AddCard = props => { 

  return (
    <div className="address-card"> 
      <div className="ac-radio text-center">
          <img src="/images/bank.svg" alt="bank" className="mt-3 mb-3" />
          <h6>ADD BANK DETAILS</h6> 
      </div>
    </div>
  );
};

export default AddCard;
