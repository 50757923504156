import React,{useState, useEffect} from 'react';  
import { Link } from "react-router-dom"; 
import { Toast } from '../../Utils/Notifications';
import { request } from '../../Utils/Request';
import AddNewCard  from './AddNewCard';
import CCard  from './CCard' 

const SavedCards = props => { 
  const[savecard,setSavedCard]=useState([])
  const getAllcard=async(e)=>{
    const res = await request('post','/viewAllCard', {mode: process.env.REACT_APP_PAYMENT_MODE},false, true)
    setSavedCard(res.data.data)
  }
 
  useEffect(()=>{
      getAllcard()
  },[])
  

  const calldelete=async(id)=>{
    const res=await request("post","/deleteStripeCard",{cardId:id,mode:process.env.REACT_APP_PAYMENT_MODE},false,true)
    if(res.data.deleted){
      Toast.fire({type:"success",title:"Card deleted successfully"})
       getAllcard()      
  }
}

  return (
    <div className="row">
      <div className="col-sm-4 form-group">
        <AddNewCard calli={getAllcard} id={props.id} />
      </div>
      
        {savecard && savecard.map((val)=>
        <div className="col-sm-4 form-group">
         <CCard dele={calldelete} crad={val}/>
         </div> 
         )}
     
    </div>
  );
};

export default SavedCards;
