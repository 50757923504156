import { Button } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import JwtDecode from "jwt-decode";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FFModal from "../../components/Modal";
import { isAuthenticated } from "../../Utils/Authenticated";
import { AvatarURL, IMAGE_URL, callNumber } from "../../Utils/constant";
import { request } from "../../Utils/Request";
import { TimetoDate } from "../../Utils/TimerProfile";
import moment from "moment";
import "./HaulierDashboard.css";

const useStyle = makeStyles({
  italic: {
    fontStyle: "italic"
  },
  bold: {
    fontWeight: "bold"
  },
});

const Delivery = () => {

  const classes = useStyle();
  const [modal, setModal] = useState(false);
  const [state1, setState] = useState({
    details: [],
    pick: "",
  });
  const [dis, setDis] = useState(false);

  const toggleModal = () => setModal(!modal);
  const getdetails = async () => {
    const res = await request(
      "post",
      "/getHaulierDetails",
      { delivered: "delivered", user_id: JwtDecode(isAuthenticated()).id },
      false,
      true
    );
    if (res.data.success == "yes") {
      var arr=[],t=""
      res.data.data.map((val,key)=>{
        arr=val.weight.split(",");
        arr.map((v)=>{
          t=t+parseInt(v)
        })        
        res.data.data[key].totalw=t;
        res.data.data[key].avw=parseFloat(t/arr.length).toFixed(2);
        t=0;
    
      
        var sex=[],m="";
        sex= res.data.data[key].sex.split(",");
         sex.map((key)=>{
              if(sex[0]!==sex[key] && sex[key]!==""){
                 m="Mixed"
              } 
        })
        if(m=="Mixed")
        {
            res.data.data[key].sex="Mixed"
        }
        else{
          res.data.data[key].sex=sex[0];
        }

        var breed=[],m="";
        breed=res.data.data[key].breed.split(",");
        breed.map((key)=>{
              if(breed[0]!==breed[key] && breed[key]!==""){
                 m="Mixed"
              } 
        })
        if(m=="Mixed")
        {
            res.data.data[key].breed="Mixed"
        }
        else{
          res.data.data[key].breed=breed[0];
        }

        var type=[],m="";
        type= res.data.data[key].type.split(",");
        type.map((key)=>{
              if(type[0]!==type[key] && type[key]!==""){
                 m="Mixed"
              } 
        })
        if(m=="Mixed")
        {
            res.data.data[key].type="Mixed"
        }
        else{
          res.data.data[key].type=type[0];
        }


        var age=[],age1=[];
        age1= res.data.data[key].dob.split(",");
        age1.map((key)=>{
              if(key!==""){
                age.push(parseInt(TimetoDate(key).toString() == 'NaN' ?0:TimetoDate(key)))
              } 
        })
        var min=age.reduce(function(a,b) {
          return Math.min(a,b)
        })
        var max=age.reduce(function(a,b) {
          return Math.max(a,b)
        })
        console.log("hhh",age,max,min)
        res.data.data[key].dob=min+"-"+max;

    

      })


      setState({ ...state1, details: res.data.data });
      setDis(true);
    }
  };

  const popupdata = (data) => (e) => {
    setModal(!modal);
    setState({ ...state1, pick: data });
  };

  useEffect(() => {
    getdetails();
  }, []);
  return (
    <div className="ff-resp-table haulierData table-responsive">
      <table className="table w-100">
        <thead className="mobile-hide">
          <tr>
            <th>#</th>
            <th>Lot number</th>
            <th>Seller</th>
            <th>Buyer</th>
            <th>Delivery time</th>
            <th className="wd-175">Action</th>
            <th className="wd-100">Status</th>
          </tr>
        </thead>
        <tbody>
          {dis &&
            state1.details.map((data, key) => (
              <tr>
                <td className="resp-td" data-label="#">
                  <div className="rtc-wrap">{key + 1}</div>
                </td>
                <td className="resp-td" data-label="Lot number">
                  <div className="rtc-wrap">
                  
                    {"#"+data.lotid}
                  </div>
                </td>
                <td className="resp-td" data-label="Seller">
                  <div className="rtc-wrap">
                    <div className={classes.bold}>{data.sellername.charAt(0).toUpperCase() + data.sellername.slice(1)}</div>
                    <div className={classes.italic}>
                    {data.selleraddress + ", " + data.sellercity + ", " + data.sellerstate + " " + data.sellerzip}
                    </div>
                    <div>{moment(data.sellertime).format("DD/MM/YYYY hh:mm")}</div>
                  </div>
                </td>
                <td className="resp-td" data-label="Buyer">
                  <div className="rtc-wrap">
                    <div className={classes.bold}>{data.buyername.charAt(0).toUpperCase() + data.buyername.slice(1)}</div>
                    <div className={classes.italic}>
                    {data.buyeraddress + ", " + data.buyercity + ", " + data.buyerstate + " " + data.buyerzip}
                    </div>
                    <div>{moment(data.buyertime).format("DD/MM/YYYY hh:mm")}</div>
                  </div>
                </td>
                <td className="resp-td" data-label="Delivery time">
                  <div className="rtc-wrap">
                    {moment(data.time_d).format("DD/MM/YYYY hh:mm")}
                  </div>
                </td>
                <td className="resp-td" data-label="Delivery time">
                  <div className="rtc-wrap">
                    <button className="gray-btn" onClick={popupdata(data)}>
                      View
                    </button>
                  </div>
                </td>
                <td className="resp-td" data-label="Status">
                  <div className="rtc-wrap">Delivered</div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <FFModal open={modal} handleClose={toggleModal} modaltitle="Delivered">
        <div className="hdb-media" style={{fontWeight:'500'}}>
          <div className="hdb-media-left">
            <img
              src={IMAGE_URL + state1.pick.avatar}
              alt="Item title"
              className="dp-img"
            />
          </div>
          <div className="hdb-media-body">
            <div className="di-title-con">
              <div>{state1.pick.title}</div>
              {/* <Link
                to={state1.pick.numberofitems==="1"?"/product-view/"+state1.pick.lotid:"/multi-items-view/"+state1.pick.lotid}
                className="dp-link"
              >
                View Item
              </Link> */}
            </div>
            <div className="di-details">
              <div>
                <div>Sex</div>
                <div>{state1.pick.sex}</div>
              </div>
              <div>
                <div>Age</div>
                <div>{state1.pick.dob} Months</div>
              </div>
              <div>
                <div>Section</div>
                <div>{state1.pick.category_id}</div>
              </div>
              <div>
                <div>Type</div>
                <div>{state1.pick.type}</div>
              </div>
              <div>
                <div>Breed</div>
                <div>{state1.pick.breed}</div>
              </div>
            </div>
            <div className="dp-con-head">Details before pickup</div>
            <div className="dp-con">
              <div>Number of livestocks</div>
              <div>{state1.pick.number_p}</div>
            </div>
            <div className="dp-con">
              <div>Number of livestocks collected</div>
              <div>{state1.pick.number_d}</div>
            </div>
            <div className="dp-con">
              <div>Tare weight before Loading</div>
              <div>{state1.pick.weight_b_p} kg</div>
            </div>
            <div className="dp-con">
              <div>Tare weight after Loading</div>
              <div>{state1.pick.weight_a_p} kg</div>
            </div>
            <div className="dp-con">
              <div>Expected weight</div>
              <div>
                {callNumber(parseInt(state1.pick.totalw))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Net weight</div>
              <div>
                {(state1.pick.weight_a_p == "" || state1.pick.weight_b_p == "") ? "0" : callNumber(Math.round(parseInt(state1.pick.weight_a_p) - parseInt(state1.pick.weight_b_p)))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Weight disparity</div>
              {(state1.pick.weight_a_p == "" || state1.pick.weight_b_p == "") ? "0 kg" : 
              (callNumber(Math.round(((parseInt(state1.pick.totalw))-Math.abs(parseInt(state1.pick.weight_a_p)-parseInt(state1.pick.weight_b_p))))).toString()[0] =='-' ?<div style={{color:"red"}}>
                {callNumber(Math.round((parseInt(state1.pick.totalw)) -Math.abs((parseInt(state1.pick.weight_a_p) - parseInt(state1.pick.weight_b_p)))))}{" "}
                kg
              </div>:<div>{callNumber(Math.round((parseInt(state1.pick.totalw)) -Math.abs((parseInt(state1.pick.weight_a_p) - parseInt(state1.pick.weight_b_p)))))}{" "}
                kg</div>)}
            </div>
            <div className="dp-con">
              <div>Average animal weight</div>
              <div>{callNumber(Math.round(state1.pick.average_weight))} kg</div>
            </div>
            <div className="dp-con">
              <div>Pickup time</div>
              <div>{moment(state1.pick.time_p).format("DD/MM/YYYY hh:mm")}</div>
            </div>
            <div className="dp-con">
              <div>
               Have you collected ID cards & Paperwork
              </div>
              <div>Yes</div>
            </div>
            <div className="dp-con">
              <div>
                Were any complaints raised by Seller at point of pickup ?
              </div>
              <div>No</div>
            </div>
            <div className="dp-con">
              <div>Seller signature</div>
              <div>
                <Button className="dp-link">Received</Button>
              </div>
            </div>
            <div className="dp-con">
              <div>Documents uploaded</div>
              <div>
              <a target={"_blank"} href={AvatarURL+state1.pick.document_p} className="dp-link">View</a>
              </div>
            </div>
            <div className="dp-con-head">Details after delivery</div>
            <div className="dp-con">
              <div>Number of livestocks</div>
              <div>{state1.pick.number_p}</div>
            </div>
            <div className="dp-con">
              <div>Number of livestocks collected</div>
              <div>{state1.pick.number_d}</div>
            </div>
            <div className="dp-con">
              <div>Tare weight before Loading</div>
              <div>{callNumber(Math.round(state1.pick.weight_b_d))} kg</div>
            </div>
            <div className="dp-con">
              <div>Tare weight after Loading</div>
              <div>{callNumber(Math.round(state1.pick.weight_a_d))} kg</div>
            </div>
            {/* <div className="dp-con">
              <div>Expected weight</div>
              <div>{state1.pick.e_weight} kg</div>
            </div> */}
            <div className="dp-con">
              <div>Expected weight</div>
              <div>
                {callNumber(parseInt(state1.pick.totalw))}{" "}
                kg
              </div>
            </div>
            
            <div className="dp-con">
              <div>Net weight</div>
              <div>
                {(state1.pick.weight_b_d == "" || state1.pick.weight_a_d == "") ? "0" : callNumber(Math.round(parseInt(state1.pick.weight_b_d) - parseInt(state1.pick.weight_a_d)))}{" "}
                kg
              </div>
            </div>
            
            <div className="dp-con">
              <div>Weight disparity</div>
              {(state1.pick.weight_b_d == "" || state1.pick.weight_a_d == "") ? "0 kg" : 
              (callNumber(Math.round(((parseInt(state1.pick.totalw))-Math.abs(parseInt(state1.pick.weight_b_d)-parseInt(state1.pick.weight_a_d))))).toString()[0] =='-' ?<div style={{color:"red"}}>
                {callNumber(Math.round((parseInt(state1.pick.totalw)) -Math.abs((parseInt(state1.pick.weight_b_d) - parseInt(state1.pick.weight_a_d)))))}{" "}
                kg
              </div>:<div>{callNumber(Math.round((parseInt(state1.pick.totalw)) -Math.abs((parseInt(state1.pick.weight_b_d) - parseInt(state1.pick.weight_a_d)))))}{" "}
                kg</div>)}
            </div>
            <div className="dp-con">
              <div>Average animal weight</div>
              <div>{callNumber(Math.round(state1.pick.average_weight))} kg</div>
            </div>
            <div className="dp-con">
              <div>Delivery time</div>
              <div>{moment(state1.pick.time_d).format("DD/MM/yyy hh:mm")}</div>
            </div>
            <div className="dp-con">
              <div>
                Have you delivered ID cards & Paperwork
              </div>
              <div>Yes</div>
            </div>
            <div className="dp-con">
              <div>
                Were any complaints raised by Seller at point of pickup ?
              </div>
              <div>No</div>
            </div>
            <div className="dp-con">
              <div>Buyer signature</div>
              <div>
              <Button className="dp-link">Received</Button>
              </div>
            </div>

            <div className="dp-con">
              <div>Documents uploaded</div>
              <div>
                <a target={"_blank"} href={AvatarURL+state1.pick.document_d} className="dp-link">View</a>
              </div>
            </div>
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default Delivery;
