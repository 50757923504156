import { Button } from '@material-ui/core';
import React,{useState} from 'react';  
import { createDispatchHook } from 'react-redux';
import { Link } from "react-router-dom";


const CCard = props => { 

  return (
    <>
 
    <div className="ccard-wrap">
   
      
       <img src="/images/map.svg" alt="bank" className="map-svg" />
       <div className="row">
          <div className="col-6">
          	<img src="/images/chip.svg" alt="bank"  />
          </div>
          <div className="col-6 pt-1 text-right">
            <img src="/images/visa-pay-logo.svg" alt="bank"  /> 
          </div>
       </div>
       <div className="card-num">
          <span>****</span>
          <span>****</span>
          <span>****</span>
  <span>{props.crad.last4}</span>
       </div>
       <div className="clearfix">
          <div className="float-left">
            <div className="fs-12">CARD HOLDER</div>
  <div>{props.crad.name}</div>
          </div>
          <div className="float-right">
          	<div className="fs-12">EXPIRES</div>
            <div>{props.crad.exp_month+"/"+props.crad.exp_year}</div>
          </div>
       </div>
    </div>
       <Button style={{fontStyle:"bold"}} onClick={e=>props.dele(props.crad.id)}>
        <span className="material-icons">delete</span>Delete
      </Button>
    </>
  );
};

export default CCard;
