import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { request } from "../../Utils/Request";
import CustomInput from "../../components/Inputs/borderedInput";
import { VaildateUpdateProfile } from "../../Utils/Validators";
import UseForm from "../../Utils/UserForm";
import JwtDecode from "jwt-decode";
import { Popup, Toast } from "../../Utils/Notifications";
import {
  AvatarURL,
  HerdNum,
  NumberOnly,
  callNumber,
  specialcharacter,
} from "../../Utils/constant";
import { IrishCounties, Countries } from "../../Utils/constant";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/Add";
import InputField from "../../components/Inputs/InputField";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import CustomSelect from "../../components/Inputs/borderedSelect";
import PrimaryButton from "../../components/Inputs/PrimaryButton";
import { Link } from "react-router-dom";
import { FormHelperText, Button, InputAdornment } from "@material-ui/core";
import FFModal from "../../components/Modal";
import { CircularProgress } from "@material-ui/core";
import "react-phone-number-input/style.css";
// import MuiPhoneNumber from "material-ui-phone-number";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CheckBox from "../../components/Inputs/CheckBox";
import PaymentCardDeposit from "../Checkout/PaymentCardDeposit";
import { isAuthenticated } from "../../Utils/Authenticated";
import {
  CardElement,
  useStripe,
  Elements,
  useElements,
  IbanElement,
} from "@stripe/react-stripe-js";
var allHerdNumbers = "";

const ProfileForm = (props) => {
  var {
    handleSubmit,
    handleChange,
    values,
    errors,
    state,
    setValues,
    setState,
  } = UseForm(formSubmit, VaildateUpdateProfile);
  const [add, setAdd] = useState({
    a: [0, 1, 2, 3, 4],
  });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [getSaved, setGetSaved] = useState("");
  const [terms, setTerms] = useState(false);
  const [modalopen1, setModelopen1] = React.useState(false);
  const toggleModal1 = (e) => {
    if (e.target.checked) {
      setModelopen1(!modalopen1);
    } else {
      setValues({ ...values, enablebid: e.target.checked });
    }
  };
  const toggleModal2 = () => {
    console.log("modal open 1", modalopen1);
    return setModelopen1(!modalopen1);
  };

  const moveon = () => {
    console.log("modal open 1", modalopen1);
    if (!terms) {
      return setValues({ ...values, ter: "Please accept the Conditions" });
    } else {
      setValues({ ...values, ter: "" });
    }

    setModelopen1(!modalopen1);
    setValues({ ...values, enablebid: true });
    setTerms(false);
  };
  const handleToggle = (e) => {
    setChecked(e.target.checked);
    handleChange(e);
  };
  const handleimageLoader = (e) => {
    setValues({ ...values, profile_image: e.target.files[0] });
    console.log(e.target.files);
  };

  const UserProfile = async () => {
    try {
      const res = await request(
        "post",
        "/getUserDetails",
        {
          user_id: localStorage.userId,
        },
        false,
        true
      );
      var arr = ["", "", "", "", ""];
      //   allHerdNumbers="";
      const userData = JwtDecode(res.data.data);
      console.log(userData.registered_number.split(" "));
      if (res.data.status === "yes") {
        allHerdNumbers = "";
        userData.registered_number.split(" ").map((val, key) => {
          console.log("+++++++++++++++++++++v", val);
          if (val !== "") {
            arr.splice(key, 1, val);
            allHerdNumbers += val + " ";

            if (key > 4) {
              add.a.push(key);
            }
          }
        });
        setAdd({ ...add });
        console.log("+++++++++++++++++++++v", arr);
        setValues({
          ...values,
          address1: userData.address1,
          address2: userData.address2,
          enablebid: userData.aboutme !== "1" ? false : true,
          first_name: userData.first_name,
          last_name: userData.last_name,
          username: userData.username,
          email: userData.email,
          phone: userData.phone ? userData.phone : "+353",
          role: userData.role,
          town: userData.city,
          country: userData.country ? userData.country : "Ireland",
          id: userData.id,
          county: userData.state,
          eir_code: userData.zip,
          herd_number: arr,
          company_name: userData.company_name,
          mailing_address: userData.mailing_address,
          secondary_mailing_address: userData.secondary_mailing_address,
          profile_image: userData.avatar,
        });
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDeposit = async () => {
    const res = await request(
      "post",
      "/getUserDeposit",
      {
        project_id: 0,
      },
      false,
      true
    );

    if (res.data.success === true) {
      let deposit_amount = parseFloat(res.data.deposit.credit_amount || 0);
      setDepositAmount(deposit_amount);
    }
    return false;
  };

  useEffect(() => {
    UserProfile();
    getDeposit();
  }, []);
  const profileOne = (
    <>
      Are you sure you wish to enable One-Click Bidding?
      <br /> All bids are legally binding.
    </>
  );

  console.log(values.herd_number_2);
  // var herd1 = values.herd_number_1? `${values.herd_number_1}`:""
  // var herd2 = values.herd_number_2? ` ,${values.herd_number_2}`:""
  // var herd3 = values.herd_number_3? ` ,${values.herd_number_3}`:""
  // var herd4 = values.herd_number_4? ` ,${values.herd_number_4}`:""
  // var herd5 = values.herd_number_5? ` ,${values.herd_number_5}`:""
  // var allHerdNumbers =  `${herd1} ${herd2} ${herd3} ${herd4} ${herd5}
  async function formSubmit() {
    const formdata = new FormData();
    formdata.set("address1", values.address1); //building name as address1
    formdata.set("last_name", values.last_name);
    formdata.set("first_name", values.first_name);
    formdata.set("username", values.username);
    formdata.set("address2", values.address2); //st name as address2
    formdata.set("email", values.email);
    formdata.set("phone", values.phone);
    formdata.set("role", values.role);
    formdata.set("city", values.town); //town as city
    formdata.set("country", values.country);
    formdata.set("id", values.id);
    formdata.set("aboutme", values.enablebid ? "1" : "0");
    formdata.set("state", values.county); //county stored as state
    formdata.set("zip", values.eir_code); //Eir_code stored as zip
    formdata.set("registered_number", allHerdNumbers);
    formdata.set("mailing_address", values.mailing_address);
    formdata.set("secondary_mailing_address", values.secondary_mailing_address);
    formdata.set("user_profile_image", values.profile_image);
    formdata.set("company_name", values.company_name);
    if (values.role !== "haulier" && values.role !== "tier1") {
      if (values.herd_number[0] == undefined || values.herd_number[0] == "") {
        return Toast.fire({
          type: "error",
          title:
            "Minimum One Herd number is required.And it should have be started with first row.",
        });
      }
    }

    try {
      setLoading(true);
      const response = await request(
        "post",
        "/updateUserProfile",
        formdata,
        false,
        true
      );
      console.log(checked);
      console.log(response.data);
      if (checked) {
        var newPasswordData = {
          current_password: values.current_password,
          new_password: values.new_password,
          confirm_new_password: values.confirm_password,
        };
        const resetpass = await request(
          "post",
          "/updatepassword_in_profile",
          newPasswordData,
          false,
          true
        );
        console.log(resetpass.data);
        setLoading(false);
        if (resetpass.data.status === "no") {
          return Toast.fire({
            type: "error",
            title: "Current password is not a match with your password!",
          });
        } else {
          localStorage.setItem("userCounty", values.county);
          localStorage.setItem("userFirstName", values.first_name);
          localStorage.setItem("userLastName", values.last_name);
          localStorage.setItem("userCompanyName", values.company_name);
          setValues({
            ...values,
            current_password: "",
            new_password: "",
            confirm_password: "",
          });
          setState(false);
          return Toast.fire({
            type: "success",
            title: "Profile Updated successfully!",
          });
        }
      } else {
        setLoading(false);
        if (response.data.status === "yes") {
          localStorage.setItem("userCounty", values.county);
          localStorage.setItem("userFirstName", values.first_name);
          localStorage.setItem("userLastName", values.last_name);
          localStorage.setItem("userCompanyName", values.company_name);
          setValues({
            ...values,
            current_password: "",
            new_password: "",
            confirm_password: "",
          });
          setState(false);
          return Toast.fire({
            type: "success",
            title: "Profile Updated successfully!",
          });
        }
      }
    } catch (error) {}
  }

  const [open, setOpen] = useState(false);
  const [amountpopup, setDepositpopup] = useState(false);
  const [showerror, setShowErrorpopup] = useState(false);
  const [savedCard, setSavedCard] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const [sepaValue, setSepaValue] = useState({
    name: localStorage.userFirstName + " " + localStorage.userLastName,
    email: localStorage.userEmail,
    company_name: localStorage.userCompanyName,
  });
  //Handle Dialog box

  const sepaValueChange = (evt) => {
    var { name, value } = evt.target;
    setSepaValue({
      ...sepaValue,
      [name]: value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen1 = () => {
    if (amountpopup) {
      setValues({ ...values, init_amount: "" });
    }
    setDepositpopup(!amountpopup);
  };

  const getAllCards = async (e) => {
    const res = await request(
      "post",
      "/viewAllCard",
      { mode: process.env.REACT_APP_PAYMENT_MODE },
      false,
      true
    );
    if (res.data && res.data.success) {
      setSavedCard(res.data.data);
    }
  };

  const addDepositCard = async () => {
    try {
      if (values.init_amount == "") {
        setShowErrorpopup(true);
        return false;
      }
      var deposit_amount = parseFloat(
        values.init_amount.toString().split(",").join("")
      );

      // saved card
      var cardId = "";
      if (!getSaved) {
        console.log("++++++");
        if (savedCard.length > 0) {
          setGetSaved(0);
          cardId = savedCard[0].id;
        } else {
          return Toast.fire({
            title: "Please select atleast one card!",
            type: "warning",
          });
        }
      } else {
        cardId = savedCard[getSaved].id;
      }

      setPaymentLoading(true);

      var trustap_params = {
        user_id: JwtDecode(isAuthenticated()).id,
        amount: deposit_amount,
        payment_method: "card",
        mode: process.env.REACT_APP_PAYMENT_MODE,
        cardId: cardId,
        currency: "eur",
      };
      console.log("+++++++++++++++++TrustapDeposit_params", trustap_params);

      const res = await request(
        "post",
        "/TrustapDeposit",
        trustap_params,
        false,
        true
      );
      console.log("+++++++++++++++++TrustapDeposit res", res.data);
      if (res.data.success) {
        trustap_params["transaction_id"] = res.data.results.transaction_id;

        console.log("updateUserDeposit======trustap_params", trustap_params);
        const res2 = await request(
          "post",
          "/updateUserDeposit",
          trustap_params,
          false,
          true
        );
        console.log("updateUserDeposit====== res", res2.data);
        if (res2.data.success) {
          setPaymentLoading(false);
          handleClickOpen1();
          getDeposit();
          return Toast.fire({ title: "Paid Successfully!", type: "success" });
        } else {
          setPaymentLoading(false);
          return Toast.fire({ type: "error", title: "Deposit update error" });
        }
      } else {
        setPaymentLoading(false);
        return Toast.fire({ type: "error", title: res.data.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectSavedCard = (e) => {
    setGetSaved(e.target.value);
  };

  const addDepositSEPA = async (name, email) => {
    console.log("submitSEPA", name, email);
    try {
      var deposit_amount = parseFloat(
        values.init_amount.toString().split(",").join("")
      );

      setPaymentLoading(true);

      // Use your Iban Element with other Stripe.js APIs
      const iban = elements.getElement(IbanElement);
      console.log("[iban]", iban);
      if (name == "") {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild name.",
        });
      } else if (name != sepaValue.company_name && name != sepaValue.name) {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild name which match your profile.",
        });
      } else if (email == "") {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild email.",
        });
      } else if (!iban) {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: "Please enter a vaild IBAN.",
        });
      }

      //const result = await stripe.createSource(iban, sourceData);

      const PaymentMethod = await stripe.createPaymentMethod({
        type: "sepa_debit",
        sepa_debit: iban,
        billing_details: {
          name: name,
          email: email,
        },
      });
      if (PaymentMethod.error) {
        setPaymentLoading(false);
        return Toast.fire({
          type: "error",
          title: PaymentMethod.error.message,
        });
      } else {
        console.log("paymentMethod", PaymentMethod.paymentMethod);
      }

      var trustap_params = {
        user_id: JwtDecode(isAuthenticated()).id,
        amount: deposit_amount,
        mode: process.env.REACT_APP_PAYMENT_MODE,
        payment_method: "sepa",
      };

      console.log("+++++++++++++++++TrustapDeposit_params", trustap_params);

      const res = await request(
        "post",
        "/TrustapDeposit",
        trustap_params,
        false,
        true
      );
      console.log("+++++++++++++++++TrustapDeposit res", res.data);
      if (res.data.success) {
        if (res.data.results.transaction_id && res.data.results.client_secret) {
          const { paymentIntent, error } = await stripe.confirmSepaDebitPayment(
            res.data.results.client_secret,
            {
              payment_method: PaymentMethod.paymentMethod.id,
            }
          );
          if (error || !paymentIntent) {
            console.log("confirmSepaDebitPayment+++++++++++++++++e", error);
            setPaymentLoading(false);
            return Toast.fire({ type: "error", title: error });
          } else {
            console.log(
              "confirmSepaDebitPayment+++++++++++++++++s",
              paymentIntent
            );
            //trustap_params['transid'] = paymentIntent.id
            trustap_params["transaction_id"] = res.data.results.transaction_id;
          }
        } else {
          setPaymentLoading(false);
          return Toast.fire({
            type: "error",
            title: "Trustap transanction creation error",
          });
        }

        console.log("updateUserDeposit======placeorder_params", trustap_params);

        const res2 = await request(
          "post",
          "/updateUserDeposit",
          trustap_params,
          false,
          true
        );
        console.log("updateUserDeposit====== res", res2.data);
        if (res2.data.success) {
          setPaymentLoading(false);
          handleClickOpen1();
          return Toast.fire({
            title: "Deposit Paid Successfully!",
            type: "success",
          });
        } else {
          setPaymentLoading(false);
          return Toast.fire({ type: "error", title: "Deposit update error" });
        }
      } else {
        setPaymentLoading(false);
        return Toast.fire({ type: "error", title: res.data.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const togglePaymentModal = () => {
  // 	setPaymentModalOpen(!paymentModalOpen);
  //   };

  const handleClose = () => {
    setOpen(false);

    setValues({ ...values, herd_number: [] });
    UserProfile();
    //    values.herd_number.splice(0,values.herd_number.length)

    // setValues({...values,herd_number:arr})
    // setValues({...values})
  };
  const handleClose1 = () => {
    allHerdNumbers = "";
    add.a.map((key) => {
      if (
        values.herd_number[key] != undefined &&
        values.herd_number[key] != ""
      ) {
        allHerdNumbers = allHerdNumbers + values.herd_number[key] + " ";
      }
    });
    console.log("=========", allHerdNumbers);
    setOpen(false);
    //push herd numbers to backend
    formSubmit();
  };

  const handlePhoneChange = (e) => {
    console.log("+++++++++++++", e);
    setValues({ ...values, phone: e });
  };

  const newrow = (v) => (e) => {
    add.a.push(v);
    setAdd({ ...add });
    values.herd_number.push("");
    setValues({ ...values });
  };
  const onHerdchange = (e) => {
    const { id, name, value } = e.target;

    //console.log("+++++++k",id,name,value)
    //  if(values.[name][key])
    //    if(values[name][id] === undefined)
    //    {
    //     // setValues((prevState) => ({
    // 	//  ...prevState,[name]:[...prevState[name],value]}));
    // 	 values[name].splice(id,1,value);
    //      setValues({...values})
    //     }

    //     else{
    values[name].splice(id, 1, value);
    setValues({ ...values });
    // }
  };

  useEffect(() => {
    getAllCards();
  }, []);

  return (
    <div className="form-wrapper pw-box">
      <div className="ppic-wrap">
        <img
          className="pp-img"
          src={
            values.profile_image !== ""
              ? typeof values.profile_image === "object"
                ? URL.createObjectURL(values.profile_image)
                : AvatarURL + values.profile_image
              : "/images/user-img.svg"
          }
          alt="profile.jpg"
        />
        <label for="profileImage" className="pp-edit material-icons">
          edit
        </label>
        <input
          id="profileImage"
          type="file"
          name="profile_image"
          onChange={handleimageLoader}
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
      <h4 className="form-ghead mt-4">Personal Details</h4>
      <div className="row">
        <div className="col-sm-6 form-group">
          <CustomInput
            id="first-name"
            label="First Name"
            error={state ? !values.first_name && true : false}
            placeholder="Enter your first name"
            disableTooltip={true}
            // con={false}
            value={values.first_name}
            name="first_name"
            onchange={handleChange}
            helperText={state && errors.first_name}
          />
        </div>

        <div className="col-sm-6 form-group">
          <CustomInput
            id="last-name"
            // con={false}
            error={state ? !values.last_name && true : false}
            label="Last Name"
            placeholder="Enter your last name"
            value={values.last_name}
            tooltiptitle="Last Name"
            disableTooltip={true}
            name="last_name"
            onchange={handleChange}
            helperText={state && errors.last_name}
          />
        </div>
        <div className="col-sm-6 form-group">
          <CustomInput
            id="email"
            label="Email address"
            tooltiptitle="Email"
            disableTooltip={false}
            value={values.email}
            disabled={true}
          />
        </div>
        {/* //////////////If only tier two and above //////////// */}

        <div className="col-sm-6 form-group">
          {/* <CustomInput
		                id="Phone Number"
						label="Phone Number"
						error={state ? !values.phone && true : false }
		                placeholder="Phone Number"
		                tooltiptitle="Enter your phone number"
						disableTooltip={false}
						value= {values.phone}
						name="phone"
						con={"phone"}
						onchange={handleChange}
						helperText = {state && errors.phone}
		              /> */}
          <PhoneInput
            placeholder="Phone Number"
            value={values.phone}
            country={"ie"}
            autoFormat={false}
            //    inputProps={{
            //    name:"phone"

            //    }}
            //    isValid={v=>{console.log("========",v)}}
            onChange={handlePhoneChange}
          />
          <FormHelperText error>{state && errors.phone}</FormHelperText>
        </div>
        <div className="col-sm-6 form-group">
          <CustomInput
            id="user-name"
            label="User Name"
            error={state ? !values.username && true : false}
            placeholder="Enter your user name"
            disableTooltip={true}
            // con={false}
            value={values.username}
            name="username"
            onchange={handleChange}
            disabled={true}
            helperText={state && errors.username}
          />
        </div>
        <div className="col-sm-6 form-group">
          <CustomInput
            id="user-name"
            label="Company Name"
            //error={state ? !values.company_name && true : false }
            placeholder="Enter your company name"
            disableTooltip={true}
            // con={false}
            value={values.company_name}
            name="company_name"
            onchange={handleChange}
            helperText={state && errors.company_name}
          />
        </div>
      </div>

      <h4 className="form-ghead mt-4">Location Details</h4>

      <div className="row">
        <div className="col-sm-6 form-group">
          <CustomInput
            id="building-name"
            label="Address Line 1"
            // con={false}
            error={state ? !values.address1 && true : false}
            placeholder="Enter your Address Line 1"
            tooltiptitle="Enter your Address Line 1"
            name="address1"
            value={values.address1}
            onchange={handleChange}
            helperText={state && errors.address1}
          />
        </div>
        <div className="col-sm-6 form-group">
          <CustomInput
            id="street-name"
            label="Townsland / Address Line 2"
            con={"sn"}
            error={state ? !values.address2 && true : false}
            placeholder="Enter your Townsland / Address Line 2"
            tooltiptitle="Enter your Townsland / Address Line 2"
            name="address2"
            value={values.address2}
            onchange={handleChange}
            helperText={state && errors.address2}
          />
        </div>
        <div className="col-sm-6 form-group">
          <CustomInput
            id="town-name"
            label="Town name"
            con={false}
            placeholder="Enter town"
            error={state ? !values.town && true : false}
            tooltiptitle="Enter your town"
            name="town"
            onchange={handleChange}
            value={values.town}
            helperText={state && errors.town}
          />
        </div>

        <div className="col-sm-6 form-group">
          <CustomSelect
            id="County"
            label="Select your County"
            tooltiptitle="County"
            error={state ? !values.county && true : false}
            name="county"
            value={values.county}
            selectMenu={IrishCounties}
            pc_onchange={handleChange}
            selectMenuHeader={"Select your county"}
            helperText={state && errors.county}
          ></CustomSelect>

          <FormHelperText error>{state && errors.county}</FormHelperText>
        </div>
        <div className="col-sm-6 form-group">
          <CustomSelect
            id="Country"
            label="Select your country"
            selectMenuHeader={"Select your country"}
            tooltiptitle="Country"
            name="country"
            value={values.country}
            error={state ? !values.country && true : false}
            selectMenu={Countries}
            helperText={state && errors.country}
            pc_onchange={handleChange}
          ></CustomSelect>

          <FormHelperText error>{state && errors.country}</FormHelperText>
        </div>

        <div className="col-sm-6 form-group">
          <CustomInput
            id="eir-code"
            label="EIR code"
            placeholder="Enter EIR code"
            tooltiptitle="EIR code"
            error={state ? !values.eir_code && true : false}
            name="eir_code"
            onchange={handleChange}
            con={values.eir_code.length == 8 ? "dallow" : "sn"}
            helperText={state && errors.eir_code}
            value={values.eir_code}
          />
        </div>
      </div>
      {values.role !== "haulier" && values.role !== "tier1" ? (
        <>
          <div className="herd-number">
            <div className="form-ghead pre_bidding">
              Pre-Bidding Deposit on Hold
            </div>
            {parseInt(depositAmount) > 0 ? (
              <span>€ {callNumber(depositAmount)}</span>
            ) : (
              <Link onClick={handleClickOpen1}>
                <span className="material-icons">edit</span> Add Amount
              </Link>
            )}

            <FFModal
              open={amountpopup}
              handleClose={handleClickOpen1}
              modaltitle="Pre-Bidding Deposit"
            >
              <p className="card-text" style={{ fontWeight: "500" }}>
                {/* Enter the value here: */}
                <div>
                  <CustomInput
                    id="bid-amount"
                    name="init_amount"
                    placeholder="Enter amount"
                    value={
                      callNumber(
                        values.init_amount.toString().split(",").join("")
                      ) == 0
                        ? ""
                        : callNumber(
                            values.init_amount.toString().split(",").join("")
                          )
                    }
                    onchange={(e) =>
                      setValues({ ...values, init_amount: e.target.value })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="material-icons">euro</span>
                        </InputAdornment>
                      ),
                      onKeyPress: NumberOnly,
                    }}
                    disableTooltip="true"
                  />
                  {!values.init_amount && showerror ? (
                    <p className="text-danger">{"Please enter amount"}</p>
                  ) : (
                    ""
                  )}

                  {/* <div className="modalCTA d-flex justify-content-end align-items-center">
                  
                    <>
                      <Button onClick={handleClickOpen1}>Cancel</Button>
                      <PrimaryButton
                        label={"Confirm"}
                        onsubmit={""}
                      />
                    </>
                  
                </div> */}
                </div>
              </p>

              <div className="col-md-12">
                <PaymentCardDeposit
                  sub={addDepositCard}
                  sub2={addDepositSEPA}
                  handleChange={sepaValueChange}
                  loading={paymentLoading}
                  loading2={loading}
                  card={savedCard}
                  checking={getSaved}
                  firstcall={selectSavedCard}
                  acall={getAllCards}
                />
              </div>
            </FFModal>
          </div>
          <div className="herd-number">
            <div className="form-ghead">Herd Number</div>
            <Link onClick={handleClickOpen}>
              <span className="material-icons">edit</span> Manage
            </Link>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                {add.a.map((key) => (
                  <InputField
                    label="Herd Number"
                    placeholder="Enter your herd number"
                    type="text"
                    con={
                      values.herd_number[key] != undefined &&
                      Object.keys(values.herd_number[key]).length == 10
                        ? "dallow"
                        : "hd"
                    }
                    id={key}
                    name="herd_number"
                    // InputProps={{
                    // 	onKeyPress:(HerdNum)
                    //   }}

                    value={values.herd_number[key]}
                    onchange={onHerdchange}
                  />
                ))}
              </DialogContent>
              <DialogActions>
                <CancelIcon
                  onClick={handleClose}
                  style={{ color: "#f44336" }}
                />
                <DoneIcon
                  onClick={handleClose1}
                  style={{ color: "var(--primColor)" }}
                />
                <AddIcon
                  onClick={newrow(add.a.length)}
                  style={{ color: "var(--primColor)" }}
                />
              </DialogActions>
            </Dialog>
          </div>
        </>
      ) : (
        ""
      )}
      {values.role !== "haulier" ? (
        //<Tooltip title="If you enable “one click bidding” you will not be ask if you wish to place this bid. All bids will be immediately binding on placing. Only enable this if you are sure you wish to bid quick fire." arrow placement="top">
        <div className="custom-control custom-switch mt-1">
          <input
            type="checkbox"
            className="custom-control-input"
            id="one-click"
            name="one-click"
            value={values.enablebid}
            checked={values.enablebid}
            onChange={toggleModal1}
          />

          <label className="custom-control-label" htmlFor="one-click">
            <h4 className="form-ghead option_Enable">
              Option to enable one-click bidding
            </h4>
          </label>
        </div>
      ) : (
        // </Tooltip>
        ""
      )}
      <div className="custom-control custom-switch mt-1">
        <input
          type="checkbox"
          className="custom-control-input"
          id="togglePassword"
          name="password_toggle"
          value={values.password_toggle}
          onChange={handleToggle}
          checked={checked}
        />
        <label className="custom-control-label" htmlFor="togglePassword">
          <h4 className="form-ghead">Change Password</h4>
        </label>
      </div>
      {checked && (
        <div className="row">
          <div className="col-sm-6 form-group">
            <CustomInput
              id="current-password"
              label="Current Password"
              error={state ? !values.current_password && true : false}
              placeholder="Enter your current password"
              tooltiptitle="Current Password"
              disableTooltip={false}
              helperText={state && errors.current_password}
              name="current_password"
              value={values.current_password}
              onchange={handleChange}
              type="password"
            />
          </div>
        </div>
      )}
      {checked && (
        <div className="row">
          <div className="col-sm-6 form-group">
            <CustomInput
              id="new-password"
              label="New password"
              error={state ? errors.new_pwd && true : false}
              helperText={state && errors.new_password}
              placeholder="Enter your new password"
              tooltiptitle="New Password"
              disableTooltip={false}
              type="password"
              name="new_password"
              value={values.new_password}
              onchange={handleChange}
            />
          </div>
          <div className="col-sm-6 form-group">
            <CustomInput
              id="confirm-password"
              label="Confirm Password"
              placeholder="Re-enter your password"
              tooltiptitle="Confirm Password"
              error={state ? errors.cfm_pwd && true : false}
              disableTooltip={false}
              name="confirm_password"
              helperText={state && errors.confirm_password}
              value={values.confirm_password}
              onchange={handleChange}
              type="password"
            />
          </div>
        </div>
      )}

      <div className="pl-btn pt-4">
        {loading ? (
          <CircularProgress />
        ) : (
          <PrimaryButton label="SAVE" onsubmit={handleSubmit} />
        )}
      </div>
      <FFModal
        open={modalopen1}
        handleClose={toggleModal2}
        modaltitle="Message"
      >
        <div style={{ fontWeight: "500" }}>
          <p className="card-text newCardText">
            <CheckBox
              name="terms"
              onchange={(e) => setTerms(e.target.checked)}
              value={terms}
              label={profileOne}
            />
          </p>
          <p style={{ alignContent: "center" }}>
            <FormHelperText error>{!terms ? values.ter : ""}</FormHelperText>
          </p>
          <div className="modalCTA d-flex justify-content-end align-items-center">
            <PrimaryButton label={"Cancel"} onsubmit ={toggleModal2} />
            <Button onClick={moveon}>Agree</Button>
          </div>
        </div>
      </FFModal>
    </div>
  );
};

export default ProfileForm;
