import moment from "moment"

export const DateFormat = (data) => {
    return `${moment(data).format('DD/MM/YYYY')}`;
  };
  
  export const DateTime = (data) => {
    return `${moment(data).format('DD/MM/YYYY  HH:MM:ss')}`;
  };
  export const SaleNumber = (loc,date1,date2) =>{
     let Number="LS";
     console.log("LS",loc);
     if(loc==="South East"){
          Number="LS"
     }
     if(loc==="Leinster North"){
      Number="LN"
     }
     if(loc=="Munster East"){
      Number="ME"
     }
     if(loc=="Munster West"){
      Number="MW"
      }
     if(loc=="Connacht East"){
        Number="CE"
     }
     if(loc=="Connacht West"){
      Number="CW"
     }
     if(loc=="Ulster East"){
      Number="UE"
     }
     if(loc=="Ulster West"){
      Number="UW"
     }
     
     var num=moment(date1).format("YYMM").toString()!=="Invalid date"?moment(date1).format("YYMM").toString():"0000-"
     
     Number=Number.toString()+num.toString();
     
     Number=Number.toString()+Math.floor(moment.duration(moment(new Date(date1)).diff(moment(new Date(date2)))).asWeeks())+"-";
     return Number;
  };

  export const ItemNum = (loc,date1) =>{
    let Number="01";
    if(loc=="South East"){
         Number="01"
    }
    if(loc=="Leinster North"){
     Number="02"
    }
    if(loc=="Munster East"){
     Number="03"
    }
    if(loc=="Munster West"){
     Number="04"
     }
    if(loc=="Connacht East"){
       Number="05"
    }
    if(loc=="Connacht West"){
     Number="06"
    }
    if(loc=="Ulster East"){
     Number="07"
    }
    if(loc=="Ulster West"){
     Number="08"
    }
    var num=moment(date1).format("YYMMDD").toString()=="Invalid date"?"00000":moment(date1).format("YYMMDD").toString()
    Number=Number+num.toString();
    
    
    return Number;
 };

 export const TimeLeft = (data)=>{
    const timeVariation = moment.duration(moment(data).diff(moment()))
    return( timeVariation._data.seconds <=0 ? "Sale Closed" : `${timeVariation._data.days} Days ${timeVariation._data.hours} Hours ${timeVariation._data.minutes} Minutes`)
  }
      
  export const TimetoDate = (data)=>{
    const timeVariation = moment.duration(moment().diff(moment(new Date(data)))).asMonths()
    return( Math.floor(timeVariation))
  }
  export const sDate = (data)=>{
   
    const add_date =moment(moment(data,"YYYY/MM/DD").add(3, "days"));
  
    return (moment(add_date._d).format("DD/MM/YY"));
  }
  export const eDate = (data)=>{
   
    // const day=moment(data).format("DD/MM/YYYY")
    // console.log("+++++",day)
    const add_date = moment(moment(data,"DD/MM/YYYY").add(7, "days"));
    console.log("+++++",add_date)
    return (moment(add_date._d).format("DD/MM/YY"));
  }

  export const TimetoDay = (data)=>{
    const timeVariation = moment.duration(moment().diff(moment(new Date(data)))).asDays()
    return( Math.floor(timeVariation))
  } 
 

  export const TimetoDays = (data)=>{
    const timeVariation = moment.duration(moment().diff(moment(new Date(data)))).asSeconds()
    return( Math.floor(timeVariation))
  } 
  export const TimeDifference=(data1,data2)=>{
    const timeVariation = moment.duration(moment(new Date(data1)).diff(moment(new Date(data2)))).asHours()
    return( Math.floor(timeVariation))
  }