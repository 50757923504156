import React, { useState, useEffect } from "react";
import { request } from "../../Utils/Request"
import Timer from "../../Utils/Timer"
import FFModal from "../../components/Modal"; 
import { isAuthenticated } from "../../Utils/Authenticated";
import Pagination from "../../components/Pagination/Pagination"
import { Link } from "react-router-dom"; 
import JwtDecode from "jwt-decode";
import { Box, Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { setWeek } from "date-fns";
import InputField from "../Inputs/InputField";
import { Toast } from "../../Utils/Notifications";
import { AvatarURL, callNumber, IMAGE_URL } from "../../Utils/constant";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {DateTime, TimeLeft,TimetoDays, TimetoDate} from "../../Utils/TimerProfile"
import moment from "moment";

const BuyNow = () => {
           /* useEffect(()=>{
	if(!isAuthenticated()){
		props.history.push("/")
	}	
},[]) */


const [modalopen, setModelopen] = React.useState(false);



const [purchaseData, setPurchasedata] = useState({
  productDetail:[],
  totallist:0,
  pageperList:10,
  page:1
})
const [val, setVal] = useState({
  review_1: 0,
  review_2: 0,
  review_3: 0,
  review_4: 0,
  review_5: 0
});
const [val1, setVal1] = useState("");
const [val2, setVal2] = useState({
  complain_message: "",
  complain_message_2: "",
  complain_phone: "",
  complain_email: ""
});
const [val3, setVal3] = useState({
  haulier_review_1: 0,
  haulier_review_2: 0
});
const [val4, setVal4] = useState("");
const [ke,setKeyva]=useState("")
const [modal, setModal] = useState(false);
const [state1, setState] = useState({
  details: [],
  pick: "",
});
const toggleComplaintModal = () => setModal(!modal);
//const toggleTrackingModal = () => setModal(!modal);
const handlePageChange = (evt, page)=>{
  setPurchasedata({
    ...purchaseData, page:page
  })

}
const sellerrating = async (event, newValue) => {
  var { name, value } = event.target;
  setVal({
    ...val,
    [name]: value
  })
}

const haulierrating = async (event, newValue) => {
  console.log('haulierrating',event.target)
  var { name, value } = event.target;
  setVal3({
    ...val3,
    [name]: value
  })
}
const ratings=async(e)=>{
  var data = {
    user_id: JwtDecode(isAuthenticated()).id,
    product_id: purchaseData.productDetail[ke].id,
    seller_id: purchaseData.productDetail[ke].user_id,
    ratings:val,
    subject:val1
  };
  const res = await request("post", "/setReviews", data, false, true);
  
  //Update state to reflect change
  getMypurchase();

  //setModelopen(!modalopen)
  Toast.fire({type:"success",title:"Review Updated successfully"})
}

const haulierRatings=async(e)=>{
  var data = {
    user_id: JwtDecode(isAuthenticated()).id,
    product_id: purchaseData.productDetail[ke].id,
    seller_id: purchaseData.productDetail[ke].haulier_user_id,
    ratings:val3,
    subject:val4
  };
  const res = await request("post", "/setReviews", data, false, true);
  
  //Update state to reflect change
  getMypurchase();

  //setModelopen(!modalopen)
  Toast.fire({type:"success",title:"Review Updated successfully"})
}

const asending=(e)=>{
  //  console.log("+++++++++",
  purchaseData.productDetail.sort((a,b)=>TimetoDays(a["buynowpaid_date"])-TimetoDays(b["buynowpaid_date"]))
   setPurchasedata({...purchaseData})
}
const desending=(e)=>{
  purchaseData.productDetail.sort((a,b)=>TimetoDays(a["buynowpaid_date"])-TimetoDays(b["buynowpaid_date"]))
  purchaseData.productDetail.reverse()
  setPurchasedata({...purchaseData})
}

const [reviewId,setRevieId]=useState("")
const [haulierReviewId,setHaulierReviewId]=useState("")
const [haulierReview,setHaulierReview]=useState(false)
const toggleModal = (v)=>(e)=> {
  setVal({...val,review_1: 0,review_2: 0,review_3: 0,review_4: 0,review_5: 0})
  setVal1('')
  setVal3({...val3,haulier_review_1: 0,haulier_review_2: 0})
  setVal4('')
  setRevieId('')
  setHaulierReviewId('')
  setHaulierReview('')
  setModelopen(!modalopen);
  setKeyva(v)
};
const toggleModalView = async(v)=> {

  //let review_message = purchaseData.productDetail[v].review_message
  //let reviewer_rating = purchaseData.productDetail[v].reviewer_rating
  let reviewid =purchaseData.productDetail[v].reviewer_id
  console.log('========toggleModalView',purchaseData.productDetail[v])

  const res = await request(
    "post",
    "/getReviewDetails",
    { pid: purchaseData.productDetail[v].id},
    false,
    true
  );
  if (res.data.success){
    var review_array = {}
    var haulier_review_array = {}
    res.data.data.map(function (review) {
      if(review.subject == "review_1" || 
          review.subject == "review_2" || 
          review.subject == "review_3" || 
          review.subject == "review_4" || 
          review.subject == "review_5"){
        review_array[review.subject] = review.rating;
      } else if (review.subject == "haulier_review_1" || 
                review.subject == "haulier_review_2"){
        haulier_review_array[review.subject] = review.rating;
      } else if (review.seller_id == purchaseData.productDetail[v].user_id){
        setVal1(review.subject)
        setRevieId(review.id)
      } else if (review.seller_id == purchaseData.productDetail[v].haulier_user_id){
        setVal4(review.subject)
        setHaulierReviewId(review.id)
      }
    });
    setVal(review_array)
    setVal3(haulier_review_array)
  } else {
    setRevieId(reviewid)
  }
  
  
  //setVal(reviewer_rating)
  //setVal1(review_message)
  setHaulierReview(purchaseData.productDetail[v].haulier_id)
  setModelopen(!modalopen);
  setKeyva(v)
};
const hanchange=(e)=>{
  setVal1(e.target.value)
}
const hanchange2=(e)=>{
  setVal4(e.target.value)
}
const complainchange=(e)=>{
  setVal2({...val2, [e.target.name]: e.target.value})
}

const getHaulierDetails=async(data)=>{
  const res = await request("post","/getHaulierDetails",data,false,true)
  if(res.data && res.data.success=="yes"){
    var arr=[],t=0;
    res.data.data.map((val,key)=>{
        arr=val.weight.split(",");
        arr.map((v)=>{
          t=t+parseInt(v)
        })        
        res.data.data[key].totalw=t;
        res.data.data[key].avw=parseFloat(t/arr.length).toFixed(2);
        t=0;
    
      
        var sex=[],m="";
        sex= res.data.data[key].sex.split(",");
         sex.map((key)=>{
              if(sex[0]!==sex[key] && sex[key]!==""){
                 m="Mixed"
              } 
        })
        if(m=="Mixed")
        {
            res.data.data[key].sex="Mixed"
        }
        else{
          res.data.data[key].sex=sex[0];
        }

        var breed=[],m="";
        breed=res.data.data[key].breed.split(",");
        breed.map((key)=>{
              if(breed[0]!==breed[key] && breed[key]!==""){
                 m="Mixed"
              } 
        })
        if(m=="Mixed")
        {
            res.data.data[key].breed="Mixed"
        }
        else{
          res.data.data[key].breed=breed[0];
        }

        var type=[],m="";
        type= res.data.data[key].type.split(",");
        type.map((key)=>{
              if(type[0]!==type[key] && type[key]!==""){
                 m="Mixed"
              } 
        })
        if(m=="Mixed")
        {
            res.data.data[key].type="Mixed"
        }
        else{
          res.data.data[key].type=type[0];
        }


        var age=[],age1=[];
        age1= res.data.data[key].dob.split(",");
        age1.map((key)=>{
              if(key!==""){
                 age.push(parseInt(TimetoDate(key)))
              } 
        })
        var min=age.reduce(function(a,b) {
          return Math.min(a,b)
        })
        var max=age.reduce(function(a,b) {
          return Math.max(a,b)
        })
        console.log("++++",max,min,key)
        res.data.data[key].dob=min+"-"+max;

    

      })
      setState({...state1, details:res.data.data, pick:res.data.data[0]})
      setVal2(res.data.data[0])
      setModal(!modal);

    }
}

const toggleComplaintView = (v, type)=>(e) =>{

  // if (type == 'submit'){
  //   window.open(`https://sso.trustap.com/auth/realms/trustap-stage/protocol/openid-connect/auth?response_type=code&client_id=ae44855d-6b59-499b-bd4e-d1e048be70f3&redirect_uri=${process.env.REACT_APP_FRONT_URL}&scope=openid%20p2p_ls:offline_create%20p2p_ls:offline_update%20p2p_tx:offline_accept_deposit%20p2p_tx:offline_set_price%20p2p_tx:offline_confirm_handover&state=close`, "_blank")
  // }

  var data = {
    delivered:"delivery",
    user_id: JwtDecode(isAuthenticated()).id,
    haulier_id: purchaseData.productDetail[v].haulier_id,
  };

  if (purchaseData.productDetail[v].haulier_status == "delivered"){
    data = {
      delivered:"delivered",
      user_id: JwtDecode(isAuthenticated()).id,
      haulier_id: purchaseData.productDetail[v].haulier_id,
    };
  }
  getHaulierDetails(data);

  
};

const submitcomplaint = async (id, lotid) => {
  var data = {
    use_id: JwtDecode(isAuthenticated()).id,
    haulier_id: id,
    user_role: 'buyer',
    data: val2,
    lotid: lotid
  };
  console.log(data)
  const res = await request("post", "/updatedelivery", data, false, true);

  if(res.data.success){
    //Update state to reflect change
    getMypurchase();
    setModal(!modal);
    Toast.fire({type:"success",title:"Complainit submitted successfully"})
  } else {
    Toast.fire({type:"error",title:res.data.message})
  }
};

const getMypurchase = async()=>{
  const res = await request(
    "post",
    "/buyerDashboard",
    { status: "buynow", page: purchaseData.page, sh_limit: purchaseData.pageperList }, false, true
  )
  console.log(res.data)

  if(res.data.success==="yes"){
    res.data.results.sort((a,b)=>TimetoDays(a["buynowpaid_date"])-TimetoDays(b["buynowpaid_date"]))
    setPurchasedata({
      ...purchaseData, productDetail :res.data.results, totallist:res.data.total_pagecnt
    })
  }
}
useEffect(()=>{
  getMypurchase()
},[purchaseData.page])
const desending1=(e)=>{
  
  purchaseData.productDetail.sort((a,b)=>a["buynowamount"]-b["buynowamount"])
  purchaseData.productDetail.reverse()
  setPurchasedata({...purchaseData})
  
}
const asending1=(e)=>{
  //  console.log("+++++++++",
  purchaseData.productDetail.sort((a,b)=>a["buynowamount"]-b["buynowamount"])
  setPurchasedata({...purchaseData})
}


console.log(purchaseData.productDetail)


  return (
    <div className="ff-resp-table table-responsive">
    <table className="table w-100">
      <thead className="mobile-hide">
        <tr>
          <th>#</th>
          <th>Lot name</th>
          <th>Bought on<ArrowDropUpIcon onClick={asending}/><ArrowDropDownIcon onClick={desending}/></th>
          <th>Amount<ArrowDropUpIcon onClick={asending1}/><ArrowDropDownIcon onClick={desending1}/></th>
          <th>Action</th>
          <th>Review</th>
        </tr>
      </thead>
      <tbody>
      {!purchaseData.productDetail.length ? 
      <tr>
        <td colspan="6" className="pd-zero text-center">
          <div className="card card-body">No items found</div>
        </td>
      </tr> 
      : 
      purchaseData.productDetail.map((item,key)=>
        <tr>
          <td className="resp-td" data-label="#">
            <div className="rtc-wrap">{key+1}</div>
          </td>
          <td className="resp-td" data-label="Lot name">
          <Link to={"/multi-items-view/"+item.id} style={{color:"var(--primColor)"}}> <div className="rtc-wrap">{item.title}</div></Link>
          </td>
          <td className="resp-td" data-label="Bought on">
            <div className="rtc-wrap">{DateTime(item.buynowpaid_date)}</div>
          </td>
          <td className="resp-td" data-label="Amount">
            <div className="rtc-wrap">€ {callNumber(item.bprice)}</div>
          </td>
          <td className="resp-td" data-label="Action">
            <div className="rtc-wrap rtc-buttons"><Link to={"/invoice/buynow/"+item.common_invoice}>{item.shipping_status == "completed"?"View Receipt":"Pre Settlement Figures"}</Link>
             {(item.haulier_status=="delivered")&&item.is_complain == "1"?
             <> <span className="line">|</span> <Link onClick={toggleComplaintView(key, 'view')}>VIEW COMPLAINT</Link></>:
             (item.haulier_status=="delivered")&&item.is_complain == "0"&&item.shipping_status != "completed" && parseInt(item.complaint_disable) == 1?
             <> <span className="line">|</span> <Link className="submitComplaint" style={{color:'red'}} onClick={toggleComplaintView(key, 'submit')}>MAKE A COMPLAINT</Link></>:''}</div>
          </td>
          {/* <td className="resp-td" data-label="Action">
            <div className="rtc-wrap"><Link onClick={toggleModal(key)}>ADD REVIEW</Link></div>
          </td> */}
          <td className="resp-td" data-label="Review">
            { item.shipping_status == "completed" ? 
              item.reviewer_id ?
                <div className="rtc-wrap"><Link onClick={e=>toggleModalView(key)}>VIEW REVIEW</Link></div>
                :
                <div className="rtc-wrap"><Link onClick={e=>toggleModalView(key)}>ADD REVIEW</Link></div> 
              : <div className="rtc-wrap"></div>
            }
          </td>
        </tr> 
        )}
      </tbody>
    </table>
    <FFModal
          open={modalopen}
          handleClose={toggleModal(0)}
          modaltitle="Reviews"
        >
          {/* <div className="sort-by mt-2 mb-2">
            <label>Sort By</label>
            <select>
              <option>Newest first</option>
            </select>
          </div> */}
        {/* <img src="/svg/logo.svg" alt="Farm Fayre" height="35" /> */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                    <h4 className="card-title">
                      <img src="/svg/logo.svg" alt="Farm Fayre" height="30" className="user_img"/>
                      <b>Members Reviews</b>
                    </h4>
                  </div>
              </div>
              <div className="row">
                {/* <div className="col-sm-4">
                  <h4 className="card-title">
                    <img
                      src={JwtDecode(isAuthenticated()).avatar?AvatarURL+JwtDecode(isAuthenticated()).avatar:"/images/default-user.svg"}
                      alt=""
                      className="user_img"
                    />
                    {JwtDecode(isAuthenticated()).first_name}
                  </h4>
                </div> */}
                <div className="col-sm-12 text-right">
                  <div className="light-red row customReview customReviewLabel">
                    <div className="col-sm-8 card-title text-left">Accuracy of Photos & Videos:</div>
                    <div className="col-sm-4 text-right">
                    <Box component="fieldset" mb={val} borderColor="transparent" className="customReviewBox">
                      <Rating
                        name="review_1"
                        value={val.review_1}
                        onChange={sellerrating}
                        readOnly = {reviewId ? true : false}
                      />
                    </Box> 
                    </div>
                  </div> 
                  <div className="light-red row customReview customReviewLabel">
                    <div className="col-sm-8 card-title text-left">Accuracy of description & Listing Details:</div>
                    <div className="col-sm-4 text-right">
                    <Box component="fieldset" mb={val} borderColor="transparent" className="customReviewBox">
                      <Rating
                        name="review_2"
                        value={val.review_2}
                        onChange={sellerrating}
                        readOnly = {reviewId ? true : false}
                      />
                    </Box>
                    </div>
                  </div> 
                  <div className="light-red row customReview">
                    <div className="col-sm-8 card-title text-left">Quality of Livestock received compared to those listed:</div>
                    <div className="col-sm-4 text-right customReviewLabel">
                    <Box component="fieldset" mb={val} borderColor="transparent" className="customReviewBox">
                      <Rating
                        name="review_3"
                        value={val.review_3}
                        onChange={sellerrating}
                        readOnly = {reviewId ? true : false}
                      />
                    </Box> 
                    </div>
                  </div> 
                  <div className="light-red row customReview customReviewLabel">
                    <div className="col-sm-8 card-title text-left">Overall Satisfaction:</div>
                    <div className="col-sm-4 text-right">
                    <Box component="fieldset" mb={val} borderColor="transparent" className="customReviewBox">
                      <Rating
                        name="review_4"
                        value={val.review_4}
                        onChange={sellerrating}
                        readOnly = {reviewId ? true : false}
                      />
                    </Box> 
                    </div>
                  </div> 
                  <div className="light-red row customReview customReviewLabel">
                    <div className="col-sm-8 card-title text-left">Would you deal with Member again:</div>
                    <div className="col-sm-4 text-right">
                    <Box component="fieldset" mb={val} borderColor="transparent" className="customReviewBox">
                      <Rating
                        name="review_5"
                        value={val.review_5}
                        onChange={sellerrating}
                        readOnly = {reviewId ? true : false}
                      />
                    </Box> 
                    </div> 
                  </div> 
                  <div className="query-input-wrap">
                    <InputField
                      className="form-control"
                      placeholder="Type your message here..."
                      value={val1}
                      onchange={hanchange}
                      disabled = {reviewId ? true : false}
                      // onKeyPress={keyp}
                    />
                  </div>
                  <Button className="blue-btn" onClick={ratings} disabled = {reviewId ? true : false}>
                    SEND
                  </Button> 
                </div>
              </div>

              {/* <p className="card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehender
              </p> */}
              
            </div>

          </div>
          
          <div className="card" hidden={!haulierReview}>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                    <h4 className="card-title">
                      <img src="/svg/logo.svg" alt="Farm Fayre" height="30" className="user_img"/>
                      <b>Haulier Reviews</b>
                    </h4>
                  </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-right">
                  <div className="light-red row customReview customReviewLabel">
                    <div className="col-sm-8 card-title text-left">Overall Quality of Haulier Service:</div>
                    <div className="col-sm-4 text-right">
                    <Box component="fieldset" mb={val} borderColor="transparent" className="customReviewBox">
                      <Rating
                        name="haulier_review_1"
                        value={val3.haulier_review_1}
                        onChange={haulierrating}
                        readOnly = {haulierReviewId ? true : false}
                      />
                    </Box> 
                    </div> 
                  </div> 
                  <div className="light-red row customReview customReviewLabel">
                    <div className="col-sm-8 card-title text-left">Value for money:</div>
                    <div className="col-sm-4 text-right">
                    <Box component="fieldset" mb={val} borderColor="transparent" className="customReviewBox">
                      <Rating
                        name="haulier_review_2"
                        value={val3.haulier_review_2}
                        onChange={haulierrating}
                        readOnly = {haulierReviewId ? true : false}
                      />
                    </Box> 
                    </div> 
                  </div> 
                  <div className="query-input-wrap">
                    <InputField
                      className="form-control"
                      placeholder="Type your message here..."
                      value={val4}
                      onchange={hanchange2}
                      disabled = {haulierReviewId ? true : false}
                      // onKeyPress={keyp}
                    />
                  </div>
                  <Button className="blue-btn" onClick={haulierRatings} disabled = {haulierReviewId ? true : false}>
                    SEND
                  </Button> 
                </div>
              </div>
            </div>

          </div>
        </FFModal>  


        <FFModal
          open={modal}
          handleClose={toggleComplaintModal}
          modaltitle= {state1.pick.is_complain == "0" ? "Submit a Complaint":"View Complaint"}
        >
        

          <div className="card">
            <div className="card-body">
              <div className="row">
                
                <div className="col-sm-12 text-right" style={{color:"black"}}>

                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Instructions for filling in complaint form:
                  Before filling in Complaint form please refer to <a
                    style={{ color: "green" }}
                    target={"_blank"}
                    href={"/sellerdeclaration?seller="+state1.pick.seller_first_name+" "+state1.pick.seller_last_name+"&lot="+state1.pick.title}
                  >
                    Seller's Declaration
                  </a>. If the Seller has breached this declaration you may be entitled to return relevant Lot. Please note that should the Seller have made a complete and honest declaration it may be unlikely that your complaint will be upheld.
                  We aim to respond within 1 working day, please bare with us in times of high demand.
                  </div>


                  {state1.pick.is_complain == "0" ?<div>
                  <div className="query-input-wrap">
                  <InputField
                    className="form-control"
                    placeholder="Please describe in detail the nature of your complaint"
                    value={val2.complain_message}
                    name="complain_message"
                    onchange={complainchange}
                    // onKeyPress={keyp}
                    disabled = {state1.pick.is_complain == "0" ? false:true}
                  />
                  </div>

                  <div className="query-input-wrap">
                  <InputField
                    className="form-control"
                    placeholder="Please describe in sufficient detail your proposed remedy"
                    value={val2.complain_message_2}
                    name="complain_message_2"
                    onchange={complainchange}
                    // onKeyPress={keyp}
                    disabled = {state1.pick.is_complain == "0" ? false:true}
                  />
                  </div>

                  <div className="query-input-wrap">
                  <InputField
                    className="form-control"
                    placeholder="Phone number for contact"
                    value={val2.complain_phone}
                    name="complain_phone"
                    onchange={complainchange}
                    // onKeyPress={keyp}
                    disabled = {state1.pick.is_complain == "0" ? false:true}
                  />
                  </div>

                  <div className="query-input-wrap">
                  <InputField
                    className="form-control"
                    placeholder="Email address for contact"
                    value={val2.complain_email}
                    name="complain_email"
                    onchange={complainchange}
                    // onKeyPress={keyp}
                    disabled = {state1.pick.is_complain == "0" ? false:true}
                  />
                  </div>
                  </div>:<div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  The nature of your complaint:<br></br> {state1.pick.complain_message}
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Proposed remedy:<br></br> {state1.pick.complain_message_2}
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Phone: {state1.pick.complain_phone}
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Email: {state1.pick.complain_email}
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Date & Time of complaint: {state1.pick.buyer_complain_date}
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Number of Livestock: {state1.pick.number_p}
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Average Weight Listed {state1.pick.weight_a_p} Kgs
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Average Weight Delivered {state1.pick.weight_b_d} Kgs
                  </div>
                  <div className="query-input-wrap" style={{height:"auto",textAlign:"left"}}>
                  Item ID: {state1.pick.lotid}
                  </div>
                  </div>}
                  {state1.pick.is_complain == "0"?<Button className="blue-btn" onClick={(e)=> submitcomplaint(state1.pick.id, state1.pick.lotid)} >
                    SUBMIT
                  </Button>:''}
     
                  
                </div>
              </div>
            </div>

          </div>
        </FFModal>  
        {/*
        <FFModal open={modal} handleClose={toggleTrackingModal} modaltitle="Delivered">
        <div className="hdb-media">
          <div className="hdb-media-left">
            <img
              src={IMAGE_URL + state1.pick.avatar}
              alt="Item title"
              className="dp-img"
            />
          </div>
          <div className="hdb-media-body">
            <div className="di-title-con">
              <div>{state1.pick.title}</div>
              <Link
                to={state1.pick.numberofitems==="1"?"/product-view/"+state1.pick.lotid:"/multi-items-view/"+state1.pick.lotid}
                className="dp-link"
              >
                View Item
              </Link>
            </div>
            <div className="di-details">
              <div>
                <div>Sex</div>
                <div>{state1.pick.sex}</div>
              </div>
              <div>
                <div>Age</div>
                <div>{state1.pick.dob} Months</div>
              </div>
              <div>
                <div>Section</div>
                <div>{state1.pick.category_id}</div>
              </div>
              <div>
                <div>Type</div>
                <div>{state1.pick.type}</div>
              </div>
              <div>
                <div>Breed</div>
                <div>{state1.pick.breed}</div>
              </div>
            </div>
            <div className="dp-con-head">Details before pickup</div>
            <div className="dp-con">
              <div>Number of livestocks</div>
              <div>{state1.pick.number_p}</div>
            </div>
            <div className="dp-con">
              <div>Number of livestocks collected</div>
              <div>{state1.pick.number_d}</div>
            </div>
            <div className="dp-con">
              <div>Tare weight before Loading</div>
              <div>{callNumber(Math.round(state1.pick.weight_b_p))} kg</div>
            </div>
            <div className="dp-con">
              <div>Tare weight after Loading</div>
              <div>{callNumber(Math.round(state1.pick.weight_a_p))} kg</div>
            </div>
            <div className="dp-con">
              <div>Expected weight</div>
              <div>
                {callNumber(Math.round(parseInt(state1.pick.weight_a_p) - parseInt(state1.pick.weight_b_p)))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Net weight</div>
              <div>
                {callNumber(Math.round(parseInt(state1.pick.weight_a_p) - parseInt(state1.pick.weight_b_p)))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Weight disparity</div>
              <div>
                {callNumber(Math.round(parseInt(state1.pick.totalw) - (parseInt(state1.pick.weight_b_p) - parseInt(state1.pick.weight_a_p))))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Average animal weight</div>
              <div>{callNumber(Math.round(state1.pick.average_weight))} kg</div>
            </div>
            <div className="dp-con">
              <div>Pickup time</div>
              <div>{moment(state1.pick.time_p).format("DD/MM/YYYY:hh:mm:ss")}</div>
            </div>
            <div className="dp-con">
              <div>
               Have you collected ID cards & Paperwork
              </div>
              <div>Yes</div>
            </div>
            <div className="dp-con">
              <div>
                Were any complaints raised by Seller at point of pickup ?
              </div>
              <div>No</div>
            </div>
            <div className="dp-con">
              <div>Seller signature</div>
              <div>
                <Button className="dp-link">Received</Button>
              </div>
            </div>
            <div className="dp-con">
              <div>Documents uploaded</div>
              <div>
              <a target={"_blank"} href={AvatarURL+state1.pick.document_p} className="dp-link">View</a>
              </div>
            </div>
            <div className="dp-con-head">Details after delivery</div>
            <div className="dp-con">
              <div>Number of livestocks</div>
              <div>{state1.pick.number_p}</div>
            </div>
            <div className="dp-con">
              <div>Number of livestocks collected</div>
              <div>{state1.pick.number_d}</div>
            </div>
            <div className="dp-con">
              <div>Tare weight before Loading</div>
              <div>{callNumber(Math.round(state1.pick.weight_b_d))} kg</div>
            </div>
            <div className="dp-con">
              <div>Tare weight after Loading</div>
              <div>{callNumber(Math.round(state1.pick.weight_a_d))} kg</div>
            </div>
            <div className="dp-con">
              <div>Expected weight</div>
              <div>
                {callNumber(Math.round(parseInt(state1.pick.weight_a_d) - parseInt(state1.pick.weight_b_d)))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Net weight</div>
              <div>
                {callNumber(Math.round(parseInt(state1.pick.weight_a_d) - parseInt(state1.pick.weight_b_d)))}{" "}
                kg
              </div>
            </div>
            <div className="dp-con">
              <div>Weight disparity</div>
              <div>
                {callNumber(Math.round(parseInt(state1.pick.totalw) - (parseInt(state1.pick.weight_b_d) - parseInt(state1.pick.weight_a_d))))}{" "}
                kg
              </div>
            </div>
            
            <div className="dp-con">
              <div>Average animal weight</div>
              <div>{callNumber(Math.round(state1.pick.average_weight))} kg</div>
            </div>

            <div className="dp-con">
              <div>Delivery time</div>
              <div>{moment(state1.pick.time_d).format("DD/MM/yyyy:hh:mm:ss")}</div>
            </div>
            <div className="dp-con">
              <div>
                Have you delivered ID cards & Paperwork
              </div>
              <div>Yes</div>
            </div>
            <div className="dp-con">
              <div>
                Were any complaints raised by Seller at point of pickup ?
              </div>
              <div>No</div>
            </div>
            <div className="dp-con">
              <div>Buyer signature</div>
              <div>
              <Button className="dp-link">Received</Button>
              </div>
            </div>

            <div className="dp-con">
              <div>Documents uploaded</div>
              <div>
                <a href={AvatarURL+state1.pick.document_d} className="dp-link">View</a>
              </div>
            </div>
            <br></br>
            
              {state1.pick.status != "delivered"?
              <div className="dp-con">
                <button className="gray-btn" onClick={(e)=> markasreceived(state1.pick.id)}>
                   Mark as Received
              </button></div>:''}
            

          </div>
        </div>
      </FFModal>  */}
    
    <Pagination 
    count = {Math.ceil(purchaseData.totallist / purchaseData.pageperList)}
    onchange = {handlePageChange}
    />
    </div>
  );
};

export default BuyNow;
