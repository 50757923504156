import React, { useState, useEffect } from "react";
import { request } from "../../Utils/Request"
import moment from "moment"
import {DateFormat, TimeLeft,TimetoDays} from "../../Utils/TimerProfile"
import { isAuthenticated } from "../../Utils/Authenticated";
import Pagination from "../../components/Pagination/Pagination"
import { Link } from "react-router-dom"; 
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { callNumber } from "../../Utils/constant";
var a=1;
const Sold =   () => { 
    /* useEffect(()=>{
	if(!isAuthenticated()){
		props.history.push("/")
	}	
},[]) */

const [productsData, setProductsdata] = useState({
  productDetail:[],
  totallist:0,
  pageperList:10,
  page:1
})
const handlePageChange = (evt, page)=>{
  setProductsdata({
    ...productsData, page:page
  })
  a=parseInt(a)+10;
}
const asending=(e)=>{
  //  console.log("+++++++++",
  productsData.productDetail.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
   setProductsdata({...productsData})
}
const desending=(e)=>{
  productsData.productDetail.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
  productsData.productDetail.reverse()
  setProductsdata({...productsData})
}
const asending1=(e)=>{
  //  console.log("+++++++++",
  productsData.productDetail.map((data)=>{
    data.m_price=data.win == 1?data.wprice:data.bprice;
  })
  productsData.productDetail.sort((a,b)=>a["m_price"]-b["m_price"])
   setProductsdata({...productsData})
}
const desending1=(e)=>{
  productsData.productDetail.map((data)=>{
    data.m_price=data.win == 1?data.wprice:data.bprice;
})
productsData.productDetail.sort((a,b)=>a["m_price"]-b["m_price"])
productsData.productDetail.reverse()
 setProductsdata({...productsData})
}

const getSellerProducts = async () =>{
  const res= await request(
    "post",
    "/getSellerProducts",
    { status: 'sold', limit: productsData.pageperList, page: productsData.page }, false, true
  )
//console.log(res.data)
if(res.data.status==="yes"){
  res.data.data.result.sort((a,b)=>TimetoDays(a["date_added"])-TimetoDays(b["date_added"]))
  setProductsdata({
    ...productsData, productDetail :res.data.data.result, totallist:res.data.data.length
  })
}
}

useEffect(()=>{
  getSellerProducts()
},[productsData.page])

//console.log(productsData)

  
  return (
    <div className="ff-resp-table table-responsive">
    <table className="table w-100">
      <thead className="mobile-hide">
        <tr>
          <th>#</th>
          <th>Lot name</th>
          <th>Sold on<ArrowDropUpIcon onClick={asending}/><ArrowDropDownIcon onClick={desending}/></th> 
          <th>Sold to</th>
          <th>Sold for<ArrowDropUpIcon onClick={asending1}/><ArrowDropDownIcon onClick={desending1}/></th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {productsData.productDetail &&
        productsData.productDetail.map((item,key)=>
        <tr>
          <td className="resp-td" data-label="#">
           <div className="rtc-wrap">{parseInt(a)+parseInt(key)}</div>
          </td>
          <td className="resp-td" data-label="Lot name"> <Link to={"/multi-items-view/"+item.id} style={{color:"var(--primColor)"}}><div className="rtc-wrap">{item.title}</div></Link></td>
          <td className="resp-td" data-label="Sold on"><div className="rtc-wrap">{DateFormat(item.buynowdate_added)}</div></td>
          <td className="resp-td" data-label="Sold to"><div className="rtc-wrap">{item.buynowuser_id}</div></td> {/* No User name yet */}
          <td className="resp-td" data-label="Sold for"><div className="rtc-wrap">€ {item.win != null ? (item.win == 1 ? callNumber(item.wprice) : callNumber(item.bprice)) : callNumber(item.wprice)}</div></td>
          <td className="resp-td" data-label="Sold for"><div className="rtc-wrap">Sold</div></td>
          <td className="resp-td" data-label="Status">
            {item.buynowpaid==="1"?
            <div className="rtc-wrap"><Link to={{pathname:"/invoice/buynow/"+item.common_invoice+"/"+item.buynowuser_id,state:{val:"sold"}}} className="gray-btn">{item.shipping_status == "completed"?"View Receipt":"Pre Settlement Figures"}</Link></div>
             :<Link to={{pathname:"/invoice/auction/"+item.common_invoice+"/"+item.buynowuser_id,state:{val:"sold"}}} className="gray-btn">{item.shipping_status == "completed"?"View Receipt":"Pre Settlement Figures"}</Link>}
            </td>
        </tr> 
      )}
      </tbody>
    </table>
        <Pagination 
        count = {Math.ceil(productsData.totallist / productsData.pageperList)}
        onchange = {handlePageChange}
        />
    </div>
  );
};

export default Sold;
